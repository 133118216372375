const player_index_id = 0
const player_index_last_name = 1
const player_index_first_name = 2
const player_index_full_name = 3
const player_index_is_active = 4

export const players = [
    [76001, "Abdelnaby", "Alaa", "Alaa Abdelnaby", false],
    [76002, "Abdul-Aziz", "Zaid", "Zaid Abdul-Aziz", false],
    [76003, "Abdul-Jabbar", "Kareem", "Kareem Abdul-Jabbar", false],
    [51, "Abdul-Rauf", "Mahmoud", "Mahmoud Abdul-Rauf", false],
    [1505, "Abdul-Wahad", "Tariq", "Tariq Abdul-Wahad", false],
    [949, "Abdur-Rahim", "Shareef", "Shareef Abdur-Rahim", false],
    [76005, "Abernethy", "Tom", "Tom Abernethy", false],
    [76006, "Able", "Forest", "Forest Able", false],
    [76007, "Abramovic", "John", "John Abramovic", false],
    [203518, "Abrines", "Alex", "Alex Abrines", false],
    [1630173, "Achiuwa", "Precious", "Precious Achiuwa", true],
    [101165, "Acker", "Alex", "Alex Acker", false],
    [76008, "Ackerman", "Donald", "Donald Ackerman", false],
    [76009, "Acres", "Mark", "Mark Acres", false],
    [76010, "Acton", "Charles", "Charles Acton", false],
    [203112, "Acy", "Quincy", "Quincy Acy", false],
    [76011, "Adams", "Alvan", "Alvan Adams", false],
    [76012, "Adams", "Don", "Don Adams", false],
    [200801, "Adams", "Hassan", "Hassan Adams", false],
    [1629121, "Adams", "Jaylen", "Jaylen Adams", false],
    [203919, "Adams", "Jordan", "Jordan Adams", false],
    [149, "Adams", "Michael", "Michael Adams", false],
    [203500, "Adams", "Steven", "Steven Adams", true],
    [912, "Addison", "Rafael", "Rafael Addison", false],
    [1628389, "Adebayo", "Bam", "Bam Adebayo", true],
    [1629061, "Adel", "Deng", "Deng Adel", false],
    [76015, "Adelman", "Rick", "Rick Adelman", false],
    [202399, "Adrien", "Jeff", "Jeff Adrien", false],
    [201167, "Afflalo", "Arron", "Arron Afflalo", false],
    [1630534, "Agbaji", "Ochai", "Ochai Agbaji", true],
    [200772, "Ager", "Maurice", "Maurice Ager", false],
    [76016, "Aguirre", "Mark", "Mark Aguirre", false],
    [201336, "Ahearn", "Blake", "Blake Ahearn", false],
    [76017, "Ainge", "Danny", "Danny Ainge", false],
    [201582, "Ajinca", "Alexis", "Alexis Ajinca", false],
    [76018, "Akin", "Henry", "Henry Akin", false],
    [203006, "Akognon", "Josh", "Josh Akognon", false],
    [1629152, "Akoon-Purcell", "DeVaughn", "DeVaughn Akoon-Purcell", false],
    [202374, "Alabi", "Solomon", "Solomon Alabi", false],
    [76019, "Alarie", "Mark", "Mark Alarie", false],
    [76020, "Alcorn", "Gary", "Gary Alcorn", false],
    [1630583, "Aldama", "Santi", "Santi Aldama", true],
    [203128, "Aldemir", "Furkan", "Furkan Aldemir", false],
    [202332, "Aldrich", "Cole", "Cole Aldrich", false],
    [200746, "Aldridge", "LaMarcus", "LaMarcus Aldridge", false],
    [76021, "Aleksinas", "Chuck", "Chuck Aleksinas", false],
    [1626146, "Alexander", "Cliff", "Cliff Alexander", false],
    [724, "Alexander", "Cory", "Cory Alexander", false],
    [2042, "Alexander", "Courtney", "Courtney Alexander", false],
    [76022, "Alexander", "Gary", "Gary Alexander", false],
    [201570, "Alexander", "Joe", "Joe Alexander", false],
    [1629734, "Alexander", "Kyle", "Kyle Alexander", false],
    [1630234, "Alexander", "Ty-Shon", "Ty-Shon Alexander", false],
    [2349, "Alexander", "Victor", "Victor Alexander", false],
    [1629638, "Alexander-Walker", "Nickeil", "Nickeil Alexander-Walker", true],
    [76024, "Alford", "Steve", "Steve Alford", false],
    [1628959, "Alkins", "Rawle", "Rawle Alkins", false],
    [76028, "Allen", "Bob", "Bob Allen", false],
    [1628960, "Allen", "Grayson", "Grayson Allen", true],
    [1628386, "Allen", "Jarrett", "Jarrett Allen", true],
    [706, "Allen", "Jerome", "Jerome Allen", false],
    [1628443, "Allen", "Kadeem", "Kadeem Allen", false],
    [202730, "Allen", "Lavoy", "Lavoy Allen", false],
    [76027, "Allen", "Lucius", "Lucius Allen", false],
    [2124, "Allen", "Malik", "Malik Allen", false],
    [76025, "Allen", "Randy", "Randy Allen", false],
    [951, "Allen", "Ray", "Ray Allen", false],
    [2754, "Allen", "Tony", "Tony Allen", false],
    [76029, "Allison", "Odis", "Odis Allison", false],
    [200984, "Allred", "Lance", "Lance Allred", false],
    [76030, "Allums", "Darrell", "Darrell Allums", false],
    [201165, "Almond", "Morris", "Morris Almond", false],
    [308, "Alston", "Derrick", "Derrick Alston", false],
    [1747, "Alston", "Rafer", "Rafer Alston", false],
    [1824, "Aluma", "Peter", "Peter Aluma", false],
    [1630631, "Alvarado", "Jose", "Jose Alvarado", true],
    [680, "Amaechi", "John", "John Amaechi", false],
    [732, "Amaya", "Ashraf", "Ashraf Amaya", false],
    [202329, "Aminu", "Al-Farouq", "Al-Farouq Aminu", false],
    [200811, "Amundson", "Lou", "Lou Amundson", false],
    [76034, "Anderegg", "Bob", "Bob Anderegg", false],
    [2365, "Andersen", "Chris", "Chris Andersen", false],
    [2431, "Andersen", "David", "David Andersen", false],
    [101187, "Anderson", "Alan", "Alan Anderson", false],
    [202079, "Anderson", "Antonio", "Antonio Anderson", false],
    [76035, "Anderson", "Cliff", "Cliff Anderson", false],
    [76036, "Anderson", "Daniel", "Daniel Anderson", false],
    [1507, "Anderson", "Derek", "Derek Anderson", false],
    [76037, "Anderson", "Dwight", "Dwight Anderson", false],
    [944, "Anderson", "Eric", "Eric Anderson", false],
    [246, "Anderson", "Greg", "Greg Anderson", false],
    [202341, "Anderson", "James", "James Anderson", false],
    [76040, "Anderson", "Jerome", "Jerome Anderson", false],
    [1626147, "Anderson", "Justin", "Justin Anderson", false],
    [72, "Anderson", "Kenny", "Kenny Anderson", false],
    [76041, "Anderson", "Kim", "Kim Anderson", false],
    [203937, "Anderson", "Kyle", "Kyle Anderson", true],
    [76042, "Anderson", "Michael", "Michael Anderson", false],
    [76043, "Anderson", "Mitchell", "Mitchell Anderson", false],
    [98, "Anderson", "Nick", "Nick Anderson", false],
    [76045, "Anderson", "Richard", "Richard Anderson", false],
    [76046, "Anderson", "Ron", "Ron Anderson", false],
    [201583, "Anderson", "Ryan", "Ryan Anderson", false],
    [1000, "Anderson", "Shandon", "Shandon Anderson", false],
    [335, "Anderson", "Willie", "Willie Anderson", false],
    [76048, "Anderzunas", "Wally", "Wally Anderzunas", false],
    [101149, "Andriuskevicius", "Martynas", "Martynas Andriuskevicius", false],
    [76049, "Anielak", "Don", "Don Anielak", false],
    [1628387, "Anigbogu", "Ike", "Ike Anigbogu", false],
    [76050, "Ansley", "Michael", "Michael Ansley", false],
    [1512, "Anstey", "Chris", "Chris Anstey", false],
    [203507, "Antetokounmpo", "Giannis", "Giannis Antetokounmpo", true],
    [1628961, "Antetokounmpo", "Kostas", "Kostas Antetokounmpo", false],
    [203648, "Antetokounmpo", "Thanasis", "Thanasis Antetokounmpo", true],
    [2546, "Anthony", "Carmelo", "Carmelo Anthony", false],
    [1630175, "Anthony", "Cole", "Cole Anthony", true],
    [21, "Anthony", "Greg", "Greg Anthony", false],
    [201202, "Anthony", "Joel", "Joel Anthony", false],
    [203544, "Antic", "Pero", "Pero Antic", false],
    [1628384, "Anunoby", "O.G.", "O.G. Anunoby", true],
    [203951, "Appling", "Keith", "Keith Appling", false],
    [2737, "Araujo", "Rafael", "Rafael Araujo", false],
    [76053, "Arceneaux", "Stacey", "Stacey Arceneaux", false],
    [76054, "Archibald", "Nate", "Nate Archibald", false],
    [2425, "Archibald", "Robert", "Robert Archibald", false],
    [1627853, "Arcidiacono", "Ryan", "Ryan Arcidiacono", true],
    [76055, "Ard", "Jim", "Jim Ard", false],
    [2240, "Arenas", "Gilbert", "Gilbert Arenas", false],
    [2772, "Ariza", "Trevor", "Trevor Ariza", false],
    [76056, "Arizin", "Paul", "Paul Arizin", false],
    [76057, "Arlauckas", "Joe", "Joe Arlauckas", false],
    [769, "Armstrong", "B.J.", "B.J. Armstrong", false],
    [76061, "Armstrong", "Bob", "Bob Armstrong", false],
    [2220, "Armstrong", "Brandon", "Brandon Armstrong", false],
    [353, "Armstrong", "Darrell", "Darrell Armstrong", false],
    [200756, "Armstrong", "Hilton", "Hilton Armstrong", false],
    [76060, "Armstrong", "Paul", "Paul Armstrong", false],
    [76059, "Armstrong", "Tate", "Tate Armstrong", false],
    [76062, "Arnelle", "Jesse", "Jesse Arnelle", false],
    [76063, "Arnette", "Jay", "Jay Arnette", false],
    [76064, "Arnzen", "Bob", "Bob Arnzen", false],
    [2306, "Arroyo", "Carlos", "Carlos Arroyo", false],
    [201589, "Arthur", "Darrell", "Darrell Arthur", false],
    [76065, "Arthurs", "John", "John Arthurs", false],
    [1628503, "Artis", "Jamel", "Jamel Artis", false],
    [201600, "Asik", "Omer", "Omer Asik", false],
    [355, "Askew", "Vincent", "Vincent Askew", false],
    [173, "Askins", "Keith", "Keith Askins", false],
    [76068, "Asmonga", "Don", "Don Asmonga", false],
    [76069, "Atha", "Richard", "Richard Atha", false],
    [1088, "Atkins", "Chucky", "Chucky Atkins", false],
    [76070, "Attles", "Alvin", "Alvin Attles", false],
    [76071, "Aubuchon", "Chet", "Chet Aubuchon", false],
    [278, "Augmon", "Stacey", "Stacey Augmon", false],
    [201571, "Augustin", "D.J.", "D.J. Augustin", false],
    [200788, "Augustine", "James", "James Augustine", false],
    [1134, "Austin", "Ike", "Ike Austin", false],
    [76073, "Austin", "John", "John Austin", false],
    [76074, "Austin", "Ken", "Ken Austin", false],
    [1630166, "Avdija", "Deni", "Deni Avdija", true],
    [138, "Avent", "Anthony", "Anthony Avent", false],
    [76076, "Averitt", "William", "William Averitt", false],
    [1895, "Avery", "William", "William Avery", false],
    [76078, "Awtrey", "Dennis", "Dennis Awtrey", false],
    [1630555, "Ayayi", "Joel", "Joel Ayayi", false],
    [202970, "Ayon", "Gustavo", "Gustavo Ayon", false],
    [201965, "Ayres", "Jeff", "Jeff Ayres", false],
    [1629028, "Ayton", "Deandre", "Deandre Ayton", true],
    [101235, "Azubuike", "Kelenna", "Kelenna Azubuike", false],
    [1628962, "Azubuike", "Udoka", "Udoka Azubuike", true],
    [203569, "Babb", "Chris", "Chris Babb", false],
    [202337, "Babbitt", "Luke", "Luke Babbitt", false],
    [76079, "Babic", "Milos", "Milos Babic", false],
    [76080, "Bach", "Johnny", "Johnny Bach", false],
    [1628407, "Bacon", "Dwayne", "Dwayne Bacon", false],
    [1630641, "Badji", "Ibou", "Ibou Badji", true],
    [76081, "Baechtold", "Jim", "Jim Baechtold", false],
    [2053, "Bagaric", "Dalibor", "Dalibor Bagaric", false],
    [76082, "Bagley", "John", "John Bagley", false],
    [1628963, "Bagley III", "Marvin", "Marvin Bagley III", true],
    [76084, "Bailey", "Carl", "Carl Bailey", false],
    [76083, "Bailey", "Gus", "Gus Bailey", false],
    [76085, "Bailey", "James", "James Bailey", false],
    [1847, "Bailey", "Thurl", "Thurl Bailey", false],
    [1753, "Bailey", "Toby", "Toby Bailey", false],
    [203946, "Bairstow", "Cameron", "Cameron Bairstow", false],
    [1858, "Baker", "Mark", "Mark Baker", false],
    [2486, "Baker", "Maurice", "Maurice Baker", false],
    [76089, "Baker", "Norm", "Norm Baker", false],
    [1627758, "Baker", "Ron", "Ron Baker", false],
    [452, "Baker", "Vin", "Vin Baker", false],
    [1627735, "Baldwin IV", "Wade", "Wade Baldwin IV", false],
    [1631116, "Baldwin Jr.", "Patrick", "Patrick Baldwin Jr.", true],
    [200764, "Balkman", "Renaldo", "Renaldo Balkman", false],
    [76090, "Ball", "Cedric", "Cedric Ball", false],
    [1630163, "Ball", "LaMelo", "LaMelo Ball", true],
    [1628366, "Ball", "Lonzo", "Lonzo Ball", true],
    [76091, "Ballard", "Greg", "Greg Ballard", false],
    [76092, "Baltimore", "Herschel", "Herschel Baltimore", false],
    [1628964, "Bamba", "Mo", "Mo Bamba", true],
    [1631094, "Banchero", "Paolo", "Paolo Banchero", true],
    [1630217, "Bane", "Desmond", "Desmond Bane", true],
    [76093, "Banks", "Gene", "Gene Banks", false],
    [2556, "Banks", "Marcus", "Marcus Banks", false],
    [76094, "Bannister", "Ken", "Ken Bannister", false],
    [76095, "Bantom", "Mike", "Mike Bantom", false],
    [1630625, "Banton", "Dalano", "Dalano Banton", true],
    [1627760, "Barber", "Cat", "Cat Barber", false],
    [76096, "Barber", "John", "John Barber", false],
    [2571, "Barbosa", "Leandro", "Leandro Barbosa", false],
    [76097, "Bardo", "Stephen", "Stephen Bardo", false],
    [200826, "Barea", "J.J.", "J.J. Barea", false],
    [200745, "Bargnani", "Andrea", "Andrea Bargnani", false],
    [76098, "Barker", "Cliff", "Cliff Barker", false],
    [76099, "Barker", "Tom", "Tom Barker", false],
    [787, "Barkley", "Charles", "Charles Barkley", false],
    [2057, "Barkley", "Erick", "Erick Barkley", false],
    [76102, "Barksdale", "Don", "Don Barksdale", false],
    [1631230, "Barlow", "Dominick", "Dominick Barlow", true],
    [203084, "Barnes", "Harrison", "Harrison Barnes", true],
    [76103, "Barnes", "Harry", "Harry Barnes", false],
    [76105, "Barnes", "Jim", "Jim Barnes", false],
    [76104, "Barnes", "Marvin", "Marvin Barnes", false],
    [2440, "Barnes", "Matt", "Matt Barnes", false],
    [1630567, "Barnes", "Scottie", "Scottie Barnes", true],
    [76107, "Barnett", "Dick", "Dick Barnett", false],
    [76106, "Barnett", "Jim", "Jim Barnett", false],
    [76108, "Barnhill", "John", "John Barnhill", false],
    [76109, "Barnhill", "Norton", "Norton Barnhill", false],
    [76110, "Barnhorst", "Leo", "Leo Barnhorst", false],
    [76111, "Barr", "John", "John Barr", false],
    [76112, "Barr", "Mike", "Mike Barr", false],
    [76113, "Barr", "Thomas", "Thomas Barr", false],
    [2857, "Barrett", "Andre", "Andre Barrett", false],
    [76114, "Barrett", "Ernie", "Ernie Barrett", false],
    [1629628, "Barrett", "RJ", "RJ Barrett", true],
    [2853, "Barron", "Earl", "Earl Barron", false],
    [344, "Barros", "Dana", "Dana Barros", false],
    [699, "Barry", "Brent", "Brent Barry", false],
    [1003, "Barry", "Drew", "Drew Barry", false],
    [468, "Barry", "Jon", "Jon Barry", false],
    [600013, "Barry", "Rick", "Rick Barry", false],
    [76117, "Bartels", "Ed", "Ed Bartels", false],
    [76118, "Bartolome", "Vic", "Vic Bartolome", false],
    [203115, "Barton", "Will", "Will Barton", true],
    [101188, "Basden", "Eddie", "Eddie Basden", false],
    [76119, "Baskerville", "Jerry", "Jerry Baskerville", false],
    [101138, "Bass", "Brandon", "Brandon Bass", false],
    [1628238, "Bass", "Paris", "Paris Bass", false],
    [76120, "Bassett", "Tim", "Tim Bassett", false],
    [1629646, "Bassey", "Charles", "Charles Bassey", true],
    [1766, "Baston", "Maceo", "Maceo Baston", false],
    [2370, "Bateer", "Mengke", "Mengke Bateer", false],
    [76121, "Bates", "Billyray", "Billyray Bates", false],
    [1628966, "Bates-Diop", "Keita", "Keita Bates-Diop", true],
    [101212, "Batista", "Esteban", "Esteban Batista", false],
    [2471, "Batiste", "Mike", "Mike Batiste", false],
    [1499, "Battie", "Tony", "Tony Battie", false],
    [2203, "Battier", "Shane", "Shane Battier", false],
    [174, "Battle", "John", "John Battle", false],
    [76123, "Battle", "Kenny", "Kenny Battle", false],
    [76124, "Batton", "Dave", "Dave Batton", false],
    [201587, "Batum", "Nicolas", "Nicolas Batum", true],
    [76125, "Baum", "Johnny", "Johnny Baum", false],
    [76126, "Baumholtz", "Frankie", "Frankie Baumholtz", false],
    [2437, "Baxter", "Lonny", "Lonny Baxter", false],
    [201573, "Bayless", "Jerryd", "Jerryd Bayless", false],
    [76127, "Baylor", "Elgin", "Elgin Baylor", false],
    [203382, "Baynes", "Aron", "Aron Baynes", false],
    [76128, "Bazarevich", "Sergei", "Sergei Bazarevich", false],
    [203145, "Bazemore", "Kent", "Kent Bazemore", false],
    [1629647, "Bazley", "Darius", "Darius Bazley", true],
    [76129, "Beach", "Ed", "Ed Beach", false],
    [203078, "Beal", "Bradley", "Bradley Beal", true],
    [76130, "Beard", "Butch", "Butch Beard", false],
    [76131, "Beard", "Ralph", "Ralph Beard", false],
    [2602, "Beasley", "Jerome", "Jerome Beasley", false],
    [1627736, "Beasley", "Malik", "Malik Beasley", true],
    [201563, "Beasley", "Michael", "Michael Beasley", false],
    [76133, "Beaty", "Zelmo", "Zelmo Beaty", false],
    [201958, "Beaubois", "Rodrigue", "Rodrigue Beaubois", false],
    [1630699, "Beauchamp", "MarJon", "MarJon Beauchamp", true],
    [76134, "Beck", "Byron", "Byron Beck", false],
    [1133, "Beck", "Corey", "Corey Beck", false],
    [76136, "Beck", "Ernie", "Ernie Beck", false],
    [76137, "Becker", "Moe", "Moe Becker", false],
    [76138, "Bedford", "William", "William Bedford", false],
    [76139, "Beenders", "Hank", "Hank Beenders", false],
    [76140, "Behagen", "Ron", "Ron Behagen", false],
    [76141, "Behnke", "Elmer", "Elmer Behnke", false],
    [201158, "Belinelli", "Marco", "Marco Belinelli", false],
    [2294, "Bell", "Charlie", "Charlie Bell", false],
    [76142, "Bell", "Dennis", "Dennis Bell", false],
    [1628395, "Bell", "Jordan", "Jordan Bell", false],
    [1952, "Bell", "Raja", "Raja Bell", false],
    [2559, "Bell", "Troy", "Troy Bell", false],
    [76143, "Bell", "William", "William Bell", false],
    [76144, "Bellamy", "Walt", "Walt Bellamy", false],
    [1627761, "Bembry", "DeAndre'", "DeAndre' Bembry", false],
    [76145, "Bemoras", "Irv", "Irv Bemoras", false],
    [76146, "Benbow", "Leon", "Leon Benbow", false],
    [1627733, "Bender", "Dragan", "Dragan Bender", false],
    [1886, "Bender", "Jonathan", "Jonathan Bender", false],
    [203968, "Benimon", "Jerrelle", "Jerrelle Benimon", false],
    [104, "Benjamin", "Benoit", "Benoit Benjamin", false],
    [1736, "Benjamin", "Corey", "Corey Benjamin", false],
    [203461, "Bennett", "Anthony", "Anthony Bennett", false],
    [1120, "Bennett", "Elmer", "Elmer Bennett", false],
    [712, "Bennett", "Mario", "Mario Bennett", false],
    [76151, "Bennett", "Mel", "Mel Bennett", false],
    [76148, "Bennett", "Tony", "Tony Bennett", false],
    [76152, "Bennett", "Winston", "Winston Bennett", false],
    [128, "Benoit", "David", "David Benoit", false],
    [202728, "Benson", "Keith", "Keith Benson", false],
    [76154, "Benson", "Kent", "Kent Benson", false],
    [1627791, "Bentil", "Ben", "Ben Bentil", false],
    [76155, "Berce", "Gene", "Gene Berce", false],
    [76156, "Bergen", "Gary", "Gary Bergen", false],
    [76157, "Berry", "Ricky", "Ricky Berry", false],
    [76158, "Berry", "Walter", "Walter Berry", false],
    [1629541, "Bertans", "Dairis", "Dairis Bertans", false],
    [202722, "Bertans", "Davis", "Davis Bertans", true],
    [76159, "Beshore", "Del", "Del Beshore", false],
    [696, "Best", "Travis", "Travis Best", false],
    [201976, "Beverley", "Patrick", "Patrick Beverley", true],
    [1630180, "Bey", "Saddiq", "Saddiq Bey", true],
    [1630189, "Bey", "Tyler", "Tyler Bey", false],
    [204021, "Bhullar", "Sim", "Sim Bhullar", false],
    [76160, "Bianchi", "Al", "Al Bianchi", false],
    [76161, "Biasatti", "Hank", "Hank Biasatti", false],
    [76162, "Bibby", "Henry", "Henry Bibby", false],
    [1710, "Bibby", "Mike", "Mike Bibby", false],
    [76163, "Biedenbach", "Ed", "Ed Biedenbach", false],
    [2740, "Biedrins", "Andris", "Andris Biedrins", false],
    [76164, "Bielke", "Don", "Don Bielke", false],
    [76165, "Bigelow", "Bob", "Bob Bigelow", false],
    [1497, "Billups", "Chauncey", "Chauncey Billups", false],
    [76166, "Bing", "Dave", "Dave Bing", false],
    [76167, "Binion", "Joe", "Joe Binion", false],
    [203920, "Birch", "Khem", "Khem Birch", true],
    [1628444, "Bird", "Jabari", "Jabari Bird", false],
    [76168, "Bird", "Jerry", "Jerry Bird", false],
    [1449, "Bird", "Larry", "Larry Bird", false],
    [76170, "Birdsong", "Otis", "Otis Birdsong", false],
    [76171, "Bishop", "Gale", "Gale Bishop", false],
    [1629048, "Bitadze", "Goga", "Goga Bitadze", true],
    [202687, "Biyombo", "Bismack", "Bismack Biyombo", true],
    [202357, "Bjelica", "Nemanja", "Nemanja Bjelica", false],
    [76172, "Blab", "Uwe", "Uwe Blab", false],
    [76173, "Black", "Charlie", "Charlie Black", false],
    [76174, "Black", "Norman", "Norman Black", false],
    [204028, "Black", "Tarik", "Tarik Black", false],
    [76175, "Black", "Tom", "Tom Black", false],
    [76176, "Blackman", "Rolando", "Rolando Blackman", false],
    [76180, "Blackwell", "Alex", "Alex Blackwell", false],
    [76177, "Blackwell", "Cory", "Cory Blackwell", false],
    [1806, "Blackwell", "James", "James Blackwell", false],
    [76179, "Blackwell", "Nate", "Nate Blackwell", false],
    [201971, "Blair", "DeJuan", "DeJuan Blair", false],
    [2581, "Blake", "Steve", "Steve Blake", false],
    [202392, "Blakely", "Marqus", "Marqus Blakely", false],
    [1628469, "Blakeney", "Antonio", "Antonio Blakeney", false],
    [200807, "Blalock", "Will", "Will Blalock", false],
    [76182, "Blaney", "George", "George Blaney", false],
    [76183, "Blanks", "Lance", "Lance Blanks", false],
    [76184, "Blanton", "Ricky", "Ricky Blanton", false],
    [101154, "Blatche", "Andray", "Andray Blatche", false],
    [302, "Blaylock", "Mookie", "Mookie Blaylock", false],
    [202339, "Bledsoe", "Eric", "Eric Bledsoe", false],
    [1629833, "Blevins", "Keljin", "Keljin Blevins", false],
    [76186, "Blevins", "Leon", "Leon Blevins", false],
    [76187, "Block", "John", "John Block", false],
    [76188, "Bloom", "Mike", "Mike Bloom", false],
    [1628417, "Blossomgame", "Jaron", "Jaron Blossomgame", false],
    [916, "Blount", "Corie", "Corie Blount", false],
    [1548, "Blount", "Mark", "Mark Blount", false],
    [203505, "Blue", "Vander", "Vander Blue", false],
    [1629129, "Bluiett", "Trevon", "Trevon Bluiett", false],
    [76189, "Blume", "Ray", "Ray Blume", false],
    [76190, "Bobb", "Nelson", "Nelson Bobb", false],
    [2800, "Bobbitt", "Tony", "Tony Bobbitt", false],
    [76191, "Bockhorn", "Arlen", "Arlen Bockhorn", false],
    [1631205, "Boeheim", "Buddy", "Buddy Boeheim", true],
    [76192, "Boerwinkle", "Tom", "Tom Boerwinkle", false],
    [2586, "Bogans", "Keith", "Keith Bogans", false],
    [203992, "Bogdanovic", "Bogdan", "Bogdan Bogdanovic", true],
    [202711, "Bogdanovic", "Bojan", "Bojan Bogdanovic", true],
    [177, "Bogues", "Muggsy", "Muggsy Bogues", false],
    [101106, "Bogut", "Andrew", "Andrew Bogut", false],
    [1564, "Bohannon", "Etdrick", "Etdrick Bohannon", false],
    [1629626, "Bol", "Bol", "Bol Bol", true],
    [76195, "Bol", "Manute", "Manute Bol", false],
    [1628413, "Bolden", "Jonah", "Jonah Bolden", false],
    [1629716, "Bolden", "Marques", "Marques Bolden", false],
    [76196, "Bolger", "Bill", "Bill Bolger", false],
    [1630195, "Bolmaro", "Leandro", "Leandro Bolmaro", true],
    [1627762, "Bolomboy", "Joel", "Joel Bolomboy", false],
    [76197, "Bolstorff", "Doug", "Doug Bolstorff", false],
    [76203, "Bon Salle", "George H.", "George H. Bon Salle", false],
    [76198, "Bond", "Phil", "Phil Bond", false],
    [1603, "Bond", "Walter", "Walter Bond", false],
    [1629648, "Bone", "Jordan", "Jordan Bone", false],
    [1121, "Boney", "Dexter", "Dexter Boney", false],
    [1629067, "Bonga", "Isaac", "Isaac Bonga", false],
    [76201, "Bonham", "Ron", "Ron Bonham", false],
    [41, "Bonner", "Anthony", "Anthony Bonner", false],
    [2588, "Bonner", "Matt", "Matt Bonner", false],
    [1626164, "Booker", "Devin", "Devin Booker", true],
    [511, "Booker", "Melvin", "Melvin Booker", false],
    [202344, "Booker", "Trevor", "Trevor Booker", false],
    [200767, "Boone", "Josh", "Josh Boone", false],
    [76204, "Boone", "Ron", "Ron Boone", false],
    [1916, "Booth", "Calvin", "Calvin Booth", false],
    [1522, "Booth", "Keith", "Keith Booth", false],
    [76206, "Boozer", "Bob", "Bob Boozer", false],
    [2430, "Boozer", "Carlos", "Carlos Boozer", false],
    [2414, "Borchardt", "Curtis", "Curtis Borchardt", false],
    [76207, "Bornheimer", "Jake", "Jake Bornheimer", false],
    [1953, "Borrell", "Lazaro", "Lazaro Borrell", false],
    [76209, "Borsavage", "Costic", "Costic Borsavage", false],
    [76210, "Boryla", "Vince", "Vince Boryla", false],
    [2547, "Bosh", "Chris", "Chris Bosh", false],
    [76211, "Bostic", "Jim", "Jim Bostic", false],
    [76212, "Boston", "Lawrence", "Lawrence Boston", false],
    [1630527, "Boston Jr.", "Brandon", "Brandon Boston Jr.", true],
    [76213, "Boswell", "Tom", "Tom Boswell", false],
    [1628449, "Boucher", "Chris", "Chris Boucher", true],
    [1630547, "Bouknight", "James", "James Bouknight", true],
    [2257, "Boumtje-Boumtje", "Ruben", "Ruben Boumtje-Boumtje", false],
    [1631123, "Bouyea", "Jamaree", "Jamaree Bouyea", true],
    [76214, "Boven", "Don", "Don Boven", false],
    [1898, "Bowdler", "Cal", "Cal Bowdler", false],
    [1477, "Bowen", "Bruce", "Bruce Bowen", false],
    [1763, "Bowen", "Ryan", "Ryan Bowen", false],
    [1628968, "Bowen II", "Brian", "Brian Bowen II", false],
    [194, "Bowie", "Anthony", "Anthony Bowie", false],
    [76217, "Bowie", "Sam", "Sam Bowie", false],
    [1085, "Bowman", "Ira", "Ira Bowman", false],
    [1629065, "Bowman", "Ky", "Ky Bowman", false],
    [76219, "Bowman", "Nate", "Nate Bowman", false],
    [671, "Boyce", "Donnie", "Donnie Boyce", false],
    [76221, "Boyd", "Dennis", "Dennis Boyd", false],
    [76222, "Boyd", "Fred", "Fred Boyd", false],
    [76223, "Boyd", "Ken", "Ken Boyd", false],
    [1863, "Boykins", "Earl", "Earl Boykins", false],
    [76224, "Boykoff", "Harry", "Harry Boykoff", false],
    [76225, "Boynes", "Winford", "Winford Boynes", false],
    [200841, "Bozeman", "Cedric", "Cedric Bozeman", false],
    [76226, "Bracey", "Steve", "Steve Bracey", false],
    [202342, "Brackins", "Craig", "Craig Brackins", false],
    [76227, "Bradds", "Gary", "Gary Bradds", false],
    [76228, "Bradley", "Alex", "Alex Bradley", false],
    [76229, "Bradley", "Alonzo", "Alonzo Bradley", false],
    [202340, "Bradley", "Avery", "Avery Bradley", false],
    [76233, "Bradley", "Bill", "Bill Bradley", false],
    [76230, "Bradley", "Charles", "Charles Bradley", false],
    [76231, "Bradley", "Dudley", "Dudley Bradley", false],
    [76232, "Bradley", "Joe", "Joe Bradley", false],
    [2214, "Bradley", "Michael", "Michael Bradley", false],
    [762, "Bradley", "Shawn", "Shawn Bradley", false],
    [1628396, "Bradley", "Tony", "Tony Bradley", true],
    [1361, "Bradtke", "Mark", "Mark Bradtke", false],
    [66, "Bragg", "Marques", "Marques Bragg", false],
    [1765, "Braggs", "Torraye", "Torraye Braggs", false],
    [1920, "Bramlett", "A.J.", "A.J. Bramlett", false],
    [76237, "Branch", "Adrian", "Adrian Branch", false],
    [1882, "Brand", "Elton", "Elton Brand", false],
    [210, "Brandon", "Terrell", "Terrell Brandon", false],
    [1631103, "Branham", "Malaki", "Malaki Branham", true],
    [76238, "Brannum", "Bob", "Bob Brannum", false],
    [76239, "Branson", "Brad", "Brad Branson", false],
    [76240, "Branson", "Jesse", "Jesse Branson", false],
    [1629714, "Brantley", "Jarrell", "Jarrell Brantley", true],
    [76241, "Brasco", "Jim", "Jim Brasco", false],
    [76242, "Bratz", "Mike", "Mike Bratz", false],
    [76243, "Braun", "Carl", "Carl Braun", false],
    [1631128, "Braun", "Christian", "Christian Braun", true],
    [1629649, "Brazdeikis", "Ignas", "Ignas Brazdeikis", false],
    [160, "Breaux", "Tim", "Tim Breaux", false],
    [2452, "Bremer", "J.R.", "J.R. Bremer", false],
    [76245, "Brennan", "Pete", "Pete Brennan", false],
    [76246, "Brennan", "Tom", "Tom Brennan", false],
    [76247, "Breuer", "Randy", "Randy Breuer", false],
    [201147, "Brewer", "Corey", "Corey Brewer", false],
    [2249, "Brewer", "Jamison", "Jamison Brewer", false],
    [76248, "Brewer", "Jim", "Jim Brewer", false],
    [76249, "Brewer", "Ron", "Ron Brewer", false],
    [200758, "Brewer", "Ronnie", "Ronnie Brewer", false],
    [2056, "Brezec", "Primoz", "Primoz Brezec", false],
    [76250, "Brian", "Frank", "Frank Brian", false],
    [783, "Brickowski", "Frank", "Frank Brickowski", false],
    [76252, "Bridgeman", "Junior", "Junior Bridgeman", false],
    [76253, "Bridges", "Bill", "Bill Bridges", false],
    [1628969, "Bridges", "Mikal", "Mikal Bridges", true],
    [1628970, "Bridges", "Miles", "Miles Bridges", false],
    [76254, "Brightman", "Al", "Al Brightman", false],
    [1628578, "Brimah", "Amida", "Amida Brimah", false],
    [76255, "Brindley", "Audley", "Audley Brindley", false],
    [1628515, "Briscoe", "Isaiah", "Isaiah Briscoe", false],
    [76256, "Brisker", "John", "John Brisker", false],
    [1629052, "Brissett", "Oshae", "Oshae Brissett", true],
    [76257, "Bristow", "Allan", "Allan Bristow", false],
    [76258, "Britt", "Tyrone", "Tyrone Britt", false],
    [76259, "Britt", "Wayman", "Wayman Britt", false],
    [76260, "Brittain", "Mike", "Mike Brittain", false],
    [76261, "Britton", "David", "David Britton", false],
    [201972, "Brockman", "Jon", "Jon Brockman", false],
    [1629151, "Broekhoff", "Ryan", "Ryan Broekhoff", false],
    [76262, "Brogan", "Jim", "Jim Brogan", false],
    [1627763, "Brogdon", "Malcolm", "Malcolm Brogdon", true],
    [76263, "Brokaw", "Gary", "Gary Brokaw", false],
    [76264, "Brookfield", "Price", "Price Brookfield", false],
    [201166, "Brooks", "Aaron", "Aaron Brooks", false],
    [1629717, "Brooks", "Armoni", "Armoni Brooks", false],
    [1628415, "Brooks", "Dillon", "Dillon Brooks", true],
    [76265, "Brooks", "Kevin", "Kevin Brooks", false],
    [202705, "Brooks", "MarShon", "MarShon Brooks", false],
    [76266, "Brooks", "Michael", "Michael Brooks", false],
    [418, "Brooks", "Scott", "Scott Brooks", false],
    [2810, "Brown", "Andre", "Andre Brown", false],
    [1626148, "Brown", "Anthony", "Anthony Brown", false],
    [76284, "Brown", "Bob", "Bob Brown", false],
    [201628, "Brown", "Bobby", "Bobby Brown", false],
    [1628971, "Brown", "Bruce", "Bruce Brown", true],
    [359, "Brown", "Chucky", "Chucky Brown", false],
    [2245, "Brown", "Damone", "Damone Brown", false],
    [76270, "Brown", "Darrell", "Darrell Brown", false],
    [244, "Brown", "Dee", "Dee Brown", false],
    [200793, "Brown", "Dee", "Dee Brown", false],
    [201974, "Brown", "Derrick", "Derrick Brown", false],
    [2484, "Brown", "Devin", "Devin Brown", false],
    [2081, "Brown", "Ernest", "Ernest Brown", false],
    [76272, "Brown", "Fred", "Fred Brown", false],
    [76273, "Brown", "George", "George Brown", false],
    [1871, "Brown", "Gerald", "Gerald Brown", false],
    [76275, "Brown", "Harold", "Harold Brown", false],
    [203913, "Brown", "Jabari", "Jabari Brown", false],
    [1627759, "Brown", "Jaylen", "Jaylen Brown", true],
    [76276, "Brown", "John", "John Brown", false],
    [2208, "Brown", "Kedrick", "Kedrick Brown", false],
    [1631112, "Brown", "Kendall", "Kendall Brown", true],
    [2198, "Brown", "Kwame", "Kwame Brown", false],
    [76278, "Brown", "Leon", "Leon Brown", false],
    [76279, "Brown", "Lewis", "Lewis Brown", false],
    [203485, "Brown", "Lorenzo", "Lorenzo Brown", false],
    [992, "Brown", "Marcus", "Marcus Brown", false],
    [203900, "Brown", "Markel", "Markel Brown", false],
    [1479, "Brown", "Mike", "Mike Brown", false],
    [1629650, "Brown", "Moses", "Moses Brown", true],
    [76277, "Brown", "Myron", "Myron Brown", false],
    [136, "Brown", "P.J.", "P.J. Brown", false],
    [753, "Brown", "Randy", "Randy Brown", false],
    [76282, "Brown", "Raymond", "Raymond Brown", false],
    [76283, "Brown", "Rickey", "Rickey Brown", false],
    [76286, "Brown", "Roger", "Roger Brown", false],
    [200769, "Brown", "Shannon", "Shannon Brown", false],
    [76285, "Brown", "Stan", "Stan Brown", false],
    [1628425, "Brown", "Sterling", "Sterling Brown", true],
    [2357, "Brown", "Tierre", "Tierre Brown", false],
    [76268, "Brown", "Tony", "Tony Brown", false],
    [1630535, "Brown III", "Greg", "Greg Brown III", true],
    [1629718, "Brown Jr.", "Charlie", "Charlie Brown Jr.", false],
    [1630602, "Brown Jr.", "Chaundee", "Chaundee Brown Jr.", false],
    [1628972, "Brown Jr.", "Troy", "Troy Brown Jr.", true],
    [76287, "Browne", "Jim", "Jim Browne", false],
    [76288, "Brundy", "Stanley", "Stanley Brundy", false],
    [1628973, "Brunson", "Jalen", "Jalen Brunson", true],
    [1594, "Brunson", "Rick", "Rick Brunson", false],
    [1627852, "Brussino", "Nicolas", "Nicolas Brussino", false],
    [1629091, "Bryant", "Elijah", "Elijah Bryant", false],
    [76289, "Bryant", "Emmette", "Emmette Bryant", false],
    [76290, "Bryant", "Joe", "Joe Bryant", false],
    [977, "Bryant", "Kobe", "Kobe Bryant", false],
    [95, "Bryant", "Mark", "Mark Bryant", false],
    [1628418, "Bryant", "Thomas", "Thomas Bryant", true],
    [76291, "Bryant", "Wallace", "Wallace Bryant", false],
    [76292, "Bryn", "Torgeir", "Torgeir Bryn", false],
    [1629783, "Buchanan", "Shaq", "Shaq Buchanan", false],
    [76293, "Buckhalter", "Joe", "Joe Buckhalter", false],
    [76294, "Bucknall", "Steve", "Steve Bucknall", false],
    [76295, "Buckner", "Cleveland", "Cleveland Buckner", false],
    [1761, "Buckner", "Greg", "Greg Buckner", false],
    [76296, "Buckner", "Quinn", "Quinn Buckner", false],
    [76297, "Budd", "Dave", "Dave Budd", false],
    [201978, "Budinger", "Chase", "Chase Budinger", false],
    [76298, "Budko", "Walter", "Walter Budko", false],
    [146, "Buechler", "Jud", "Jud Buechler", false],
    [1934, "Buford", "Rodney", "Rodney Buford", false],
    [672, "Bullard", "Matt", "Matt Bullard", false],
    [203493, "Bullock", "Reggie", "Reggie Bullock", true],
    [76300, "Bunch", "Greg", "Greg Bunch", false],
    [76301, "Bunt", "Dick", "Dick Bunt", false],
    [76302, "Buntin", "Bill", "Bill Buntin", false],
    [76303, "Burden", "Luther", "Luther Burden", false],
    [2469, "Burke", "Pat", "Pat Burke", false],
    [203504, "Burke", "Trey", "Trey Burke", false],
    [76304, "Burkman", "Roger", "Roger Burkman", false],
    [202692, "Burks", "Alec", "Alec Burks", true],
    [2766, "Burks", "Antonio", "Antonio Burks", false],
    [101207, "Burleson", "Kevin", "Kevin Burleson", false],
    [76305, "Burleson", "Tom", "Tom Burleson", false],
    [76306, "Burmaster", "Jack", "Jack Burmaster", false],
    [76307, "Burns", "David", "David Burns", false],
    [76308, "Burns", "Evers", "Evers Burns", false],
    [76309, "Burns", "Jim", "Jim Burns", false],
    [197, "Burrell", "Scott", "Scott Burrell", false],
    [76311, "Burris", "Art", "Art Burris", false],
    [675, "Burrough", "Junior", "Junior Burrough", false],
    [76313, "Burrow", "Bob", "Bob Burrow", false],
    [1629126, "Burton", "Deonte", "Deonte Burton", true],
    [76314, "Burton", "Ed", "Ed Burton", false],
    [416, "Burton", "Willie", "Willie Burton", false],
    [76316, "Burtt", "Steve", "Steve Burtt", false],
    [76317, "Buse", "Don", "Don Buse", false],
    [202221, "Butch", "Brian", "Brian Butch", false],
    [76318, "Butcher", "Donnie", "Donnie Butcher", false],
    [76319, "Butler", "Al", "Al Butler", false],
    [2406, "Butler", "Caron", "Caron Butler", false],
    [202364, "Butler", "Da'Sean", "Da'Sean Butler", false],
    [76320, "Butler", "Greg", "Greg Butler", false],
    [2866, "Butler", "Jackie", "Jackie Butler", false],
    [1630215, "Butler", "Jared", "Jared Butler", true],
    [202710, "Butler", "Jimmy", "Jimmy Butler", true],
    [348, "Butler", "Mitchell", "Mitchell Butler", false],
    [2446, "Butler", "Rasual", "Rasual Butler", false],
    [1631219, "Butler Jr.", "John", "John Butler Jr.", true],
    [202779, "Buycks", "Dwight", "Dwight Buycks", false],
    [201182, "Byars", "Derrick", "Derrick Byars", false],
    [101115, "Bynum", "Andrew", "Andrew Bynum", false],
    [101198, "Bynum", "Will", "Will Bynum", false],
    [76321, "Byrnes", "Marty", "Marty Byrnes", false],
    [76322, "Byrnes", "Tommy", "Tommy Byrnes", false],
    [76323, "Bytzura", "Mike", "Mike Bytzura", false],
    [2560, "Cabarkapa", "Zarko", "Zarko Cabarkapa", false],
    [76324, "Cable", "Barney", "Barney Cable", false],
    [203998, "Caboclo", "Bruno", "Bruno Caboclo", false],
    [1629719, "Cacok", "Devontae", "Devontae Cacok", false],
    [679, "Caffey", "Jason", "Jason Caffey", false],
    [262, "Cage", "Michael", "Michael Cage", false],
    [1631288, "Cain", "Jamal", "Jamal Cain", true],
    [76326, "Calabrese", "Gerry", "Gerry Calabrese", false],
    [201979, "Calathes", "Nick", "Nick Calathes", false],
    [101181, "Calderon", "Jose", "Jose Calderon", false],
    [154, "Caldwell", "Adrian", "Adrian Caldwell", false],
    [76328, "Caldwell", "Jim", "Jim Caldwell", false],
    [76329, "Caldwell", "Joe", "Joe Caldwell", false],
    [203484, "Caldwell-Pope", "Kentavious", "Kentavious Caldwell-Pope", true],
    [76331, "Calhoun", "Bill", "Bill Calhoun", false],
    [76330, "Calhoun", "Corky", "Corky Calhoun", false],
    [76332, "Calip", "Demetrius", "Demetrius Calip", false],
    [76333, "Callahan", "Tom", "Tom Callahan", false],
    [76334, "Calloway", "Rick", "Rick Calloway", false],
    [76335, "Calverley", "Ernie", "Ernie Calverley", false],
    [76336, "Calvin", "Mack", "Mack Calvin", false],
    [76337, "Cambridge", "Dexter", "Dexter Cambridge", false],
    [948, "Camby", "Marcus", "Marcus Camby", false],
    [1630267, "Campazzo", "Facundo", "Facundo Campazzo", true],
    [922, "Campbell", "Elden", "Elden Campbell", false],
    [76338, "Campbell", "Tony", "Tony Campbell", false],
    [203477, "Canaan", "Isaiah", "Isaiah Canaan", false],
    [1628427, "Cancar", "Vlatko", "Vlatko Cancar", true],
    [1629962, "Cannady", "Devin", "Devin Cannady", false],
    [76339, "Cannon", "Larry", "Larry Cannon", false],
    [203991, "Capela", "Clint", "Clint Capela", true],
    [202382, "Caracter", "Derrick", "Derrick Caracter", false],
    [2073, "Cardinal", "Brian", "Brian Cardinal", false],
    [1630176, "Carey Jr.", "Vernon", "Vernon Carey Jr.", true],
    [76340, "Carl", "Howie", "Howie Carl", false],
    [76341, "Carlisle", "Chet", "Chet Carlisle", false],
    [2367, "Carlisle", "Geno", "Geno Carlisle", false],
    [76342, "Carlisle", "Rick", "Rick Carlisle", false],
    [76343, "Carlson", "Al", "Al Carlson", false],
    [76344, "Carlson", "Don", "Don Carlson", false],
    [76345, "Carney", "Bob", "Bob Carney", false],
    [200760, "Carney", "Rodney", "Rodney Carney", false],
    [76346, "Carpenter", "Bob", "Bob Carpenter", false],
    [156, "Carr", "Antoine", "Antoine Carr", false],
    [76348, "Carr", "Austin", "Austin Carr", false],
    [713, "Carr", "Chris", "Chris Carr", false],
    [1757, "Carr", "Cory", "Cory Carr", false],
    [76350, "Carr", "Kenny", "Kenny Carr", false],
    [76351, "Carr", "M.L.", "M.L. Carr", false],
    [76352, "Carrington", "Bob", "Bob Carrington", false],
    [201960, "Carroll", "DeMarre", "DeMarre Carroll", false],
    [76353, "Carroll", "Joe Barry", "Joe Barry Carroll", false],
    [2679, "Carroll", "Matt", "Matt Carroll", false],
    [1131, "Carruth", "Jimmy", "Jimmy Carruth", false],
    [1853, "Carter", "Anthony", "Anthony Carter", false],
    [76355, "Carter", "Butch", "Butch Carter", false],
    [76356, "Carter", "Fred", "Fred Carter", false],
    [76357, "Carter", "George", "George Carter", false],
    [76358, "Carter", "Howard", "Howard Carter", false],
    [76359, "Carter", "Jake", "Jake Carter", false],
    [1628975, "Carter", "Jevon", "Jevon Carter", true],
    [2466, "Carter", "Maurice", "Maurice Carter", false],
    [76360, "Carter", "Reggie", "Reggie Carter", false],
    [76361, "Carter", "Ron", "Ron Carter", false],
    [1713, "Carter", "Vince", "Vince Carter", false],
    [1628976, "Carter Jr.", "Wendell", "Wendell Carter Jr.", true],
    [203487, "Carter-Williams", "Michael", "Michael Carter-Williams", true],
    [76362, "Cartwright", "Bill", "Bill Cartwright", false],
    [76363, "Carty", "Jay", "Jay Carty", false],
    [1627936, "Caruso", "Alex", "Alex Caruso", true],
    [76364, "Cash", "Cornelius", "Cornelius Cash", false],
    [208, "Cassell", "Sam", "Sam Cassell", false],
    [201956, "Casspi", "Omri", "Omri Casspi", false],
    [76365, "Catchings", "Harvey", "Harvey Catchings", false],
    [767, "Catledge", "Terry", "Terry Catledge", false],
    [76367, "Catlett", "Sid", "Sid Catlett", false],
    [1509, "Cato", "Kelvin", "Kelvin Cato", false],
    [76368, "Cattage", "Bobby", "Bobby Cattage", false],
    [1626161, "Cauley-Stein", "Willie", "Willie Cauley-Stein", false],
    [1628505, "Caupain", "Troy", "Troy Caupain", false],
    [190, "Causwell", "Duane", "Duane Causwell", false],
    [1628463, "Cavanaugh", "Tyler", "Tyler Cavanaugh", false],
    [76370, "Cavenall", "Ron", "Ron Cavenall", false],
    [1629958, "Caver", "Ahmad", "Ahmad Caver", false],
    [76, "Ceballos", "Cedric", "Cedric Ceballos", false],
    [1911, "Celestand", "John", "John Celestand", false],
    [76373, "Cervi", "Al", "Al Cervi", false],
    [2763, "Chalmers", "Lionel", "Lionel Chalmers", false],
    [201596, "Chalmers", "Mario", "Mario Chalmers", false],
    [76374, "Chamberlain", "Bill", "Bill Chamberlain", false],
    [76375, "Chamberlain", "Wilt", "Wilt Chamberlain", false],
    [76376, "Chambers", "Jerry", "Jerry Chambers", false],
    [1472, "Chambers", "Tom", "Tom Chambers", false],
    [1630577, "Champagnie", "Julian", "Julian Champagnie", true],
    [1630551, "Champagnie", "Justin", "Justin Champagnie", true],
    [76378, "Champion", "Mike", "Mike Champion", false],
    [1631113, "Chandler", "Kennedy", "Kennedy Chandler", true],
    [2199, "Chandler", "Tyson", "Tyson Chandler", false],
    [201163, "Chandler", "Wilson", "Wilson Chandler", false],
    [76379, "Chaney", "Don", "Don Chaney", false],
    [76380, "Chaney", "John", "John Chaney", false],
    [364, "Chapman", "Rex", "Rex Chapman", false],
    [76382, "Chappell", "Len", "Len Chappell", false],
    [76383, "Charles", "Ken", "Ken Charles", false],
    [76384, "Charles", "Lorenzo", "Lorenzo Charles", false],
    [1629147, "Chealey", "Joe", "Joe Chealey", false],
    [384, "Cheaney", "Calbert", "Calbert Cheaney", false],
    [1629597, "Cheatham", "Zylan", "Zylan Cheatham", false],
    [76385, "Cheeks", "Maurice", "Maurice Cheeks", false],
    [76386, "Chenier", "Phil", "Phil Chenier", false],
    [203805, "Cherry", "Will", "Will Cherry", false],
    [76387, "Chievous", "Derrick", "Derrick Chievous", false],
    [432, "Chilcutt", "Pete", "Pete Chilcutt", false],
    [2735, "Childress", "Josh", "Josh Childress", false],
    [719, "Childress", "Randolph", "Randolph Childress", false],
    [164, "Childs", "Chris", "Chris Childs", false],
    [1629185, "Chiozza", "Chris", "Chris Chiozza", false],
    [76391, "Chollet", "Leroy", "Leroy Chollet", false],
    [76392, "Chones", "Jim", "Jim Chones", false],
    [1627737, "Chriss", "Marquese", "Marquese Chriss", false],
    [76393, "Christ", "Fred", "Fred Christ", false],
    [76394, "Christensen", "Cal", "Cal Christensen", false],
    [76395, "Christian", "Bob", "Bob Christian", false],
    [57, "Christie", "Doug", "Doug Christie", false],
    [1631108, "Christie", "Max", "Max Christie", true],
    [202091, "Christmas", "Dionte", "Dionte Christmas", false],
    [1626176, "Christmas", "Rakeem", "Rakeem Christmas", false],
    [203902, "Christon", "Semaj", "Semaj Christon", false],
    [1630528, "Christopher", "Josh", "Josh Christopher", true],
    [203565, "Christopher", "Patrick", "Patrick Christopher", false],
    [928, "Churchwell", "Robert", "Robert Churchwell", false],
    [76397, "Clark", "Archie", "Archie Clark", false],
    [76398, "Clark", "Carlos", "Carlos Clark", false],
    [201947, "Clark", "Earl", "Earl Clark", false],
    [1629109, "Clark", "Gary", "Gary Clark", false],
    [203546, "Clark", "Ian", "Ian Clark", false],
    [1721, "Clark", "Keon", "Keon Clark", false],
    [1629634, "Clarke", "Brandon", "Brandon Clarke", true],
    [1626262, "Clarke", "Coty", "Coty Clarke", false],
    [203903, "Clarkson", "Jordan", "Jordan Clarkson", true],
    [1628492, "Clavell", "Gian", "Gian Clavell", false],
    [201964, "Claver", "Victor", "Victor Claver", false],
    [1127, "Claxton", "Charles", "Charles Claxton", false],
    [1629651, "Claxton", "Nic", "Nic Claxton", true],
    [2049, "Claxton", "Speedy", "Speedy Claxton", false],
    [76402, "Cleamons", "Jim", "Jim Cleamons", false],
    [2043, "Cleaves", "Mateen", "Mateen Cleaves", false],
    [76403, "Clemens", "John", "John Clemens", false],
    [1629598, "Clemons", "Chris", "Chris Clemons", false],
    [1628499, "Cleveland", "Antonius", "Antonius Cleveland", false],
    [76404, "Clifton", "Nat", "Nat Clifton", false],
    [76406, "Closs", "Bill", "Bill Closs", false],
    [1569, "Closs", "Keith", "Keith Closs", false],
    [76407, "Cloyd", "Paul", "Paul Cloyd", false],
    [76408, "Cluggish", "Marion", "Marion Cluggish", false],
    [76409, "Clyde", "Ben", "Ben Clyde", false],
    [1629599, "Coffey", "Amir", "Amir Coffey", true],
    [76410, "Coffey", "Richard", "Richard Coffey", false],
    [76411, "Cofield", "Fred", "Fred Cofield", false],
    [715, "Coker", "John", "John Coker", false],
    [202708, "Cole", "Norris", "Norris Cole", false],
    [76412, "Coleman", "Ben", "Ben Coleman", false],
    [934, "Coleman", "Derrick", "Derrick Coleman", false],
    [76413, "Coleman", "E.C.", "E.C. Coleman", false],
    [76414, "Coleman", "Jack", "Jack Coleman", false],
    [76415, "Coleman", "Norris", "Norris Coleman", false],
    [902, "Coles", "Bimbo", "Bimbo Coles", false],
    [2044, "Collier", "Jason", "Jason Collier", false],
    [76416, "Collins", "Art", "Art Collins", false],
    [76418, "Collins", "Don", "Don Collins", false],
    [76421, "Collins", "Doug", "Doug Collins", false],
    [1530, "Collins", "James", "James Collins", false],
    [2260, "Collins", "Jarron", "Jarron Collins", false],
    [2215, "Collins", "Jason", "Jason Collins", false],
    [76419, "Collins", "Jimmy", "Jimmy Collins", false],
    [1628381, "Collins", "John", "John Collins", true],
    [200776, "Collins", "Mardy", "Mardy Collins", false],
    [202395, "Collins", "Sherron", "Sherron Collins", false],
    [1628380, "Collins", "Zach", "Zach Collins", true],
    [1627858, "Collinsworth", "Kyle", "Kyle Collinsworth", false],
    [201954, "Collison", "Darren", "Darren Collison", false],
    [2555, "Collison", "Nick", "Nick Collison", false],
    [76422, "Colone", "Joe", "Joe Colone", false],
    [1629045, "Colson", "Bonzie", "Bonzie Colson", false],
    [2173, "Colson", "Sean", "Sean Colson", false],
    [306, "Colter", "Steve", "Steve Colter", false],
    [76425, "Combs", "Leroy", "Leroy Combs", false],
    [76426, "Comegys", "Dallas", "Dallas Comegys", false],
    [76427, "Comley", "Larry", "Larry Comley", false],
    [76428, "Conley", "Gene", "Gene Conley", false],
    [201144, "Conley", "Mike", "Mike Conley", true],
    [76429, "Conlin", "Ed", "Ed Conlin", false],
    [55, "Conlon", "Marty", "Marty Conlon", false],
    [1626192, "Connaughton", "Pat", "Pat Connaughton", true],
    [76431, "Conner", "Lester", "Lester Conner", false],
    [76432, "Connors", "Chuck", "Chuck Connors", false],
    [101215, "Conroy", "Will", "Will Conroy", false],
    [76433, "Cook", "Anthony", "Anthony Cook", false],
    [76434, "Cook", "Bert", "Bert Cook", false],
    [76438, "Cook", "Bobby", "Bobby Cook", false],
    [2567, "Cook", "Brian", "Brian Cook", false],
    [201161, "Cook", "Daequan", "Daequan Cook", false],
    [76435, "Cook", "Darwin", "Darwin Cook", false],
    [76436, "Cook", "Jeff", "Jeff Cook", false],
    [76437, "Cook", "Norm", "Norm Cook", false],
    [2241, "Cook", "Omar", "Omar Cook", false],
    [1626188, "Cook", "Quinn", "Quinn Cook", false],
    [1629076, "Cook", "Tyler", "Tyler Cook", false],
    [1628429, "Cooke", "Charles", "Charles Cooke", false],
    [76439, "Cooke", "David", "David Cooke", false],
    [76440, "Cooke", "Joe", "Joe Cooke", false],
    [1641645, "Cooks", "Xavier", "Xavier Cooks", true],
    [204022, "Cooley", "Jack", "Jack Cooley", false],
    [76442, "Cooper", "Chuck", "Chuck Cooper", false],
    [1030, "Cooper", "Duane", "Duane Cooper", false],
    [76443, "Cooper", "Joe", "Joe Cooper", false],
    [76444, "Cooper", "Michael", "Michael Cooper", false],
    [1630536, "Cooper", "Sharife", "Sharife Cooper", false],
    [76441, "Cooper", "Wayne", "Wayne Cooper", false],
    [76446, "Copa", "Tom", "Tom Copa", false],
    [203142, "Copeland", "Chris", "Chris Copeland", false],
    [76447, "Copeland", "Hollis", "Hollis Copeland", false],
    [76448, "Copeland", "Lanard", "Lanard Copeland", false],
    [895, "Corbin", "Tyrone", "Tyrone Corbin", false],
    [76450, "Corchiani", "Chris", "Chris Corchiani", false],
    [76451, "Corley", "Ken", "Ken Corley", false],
    [76452, "Corley", "Ray", "Ray Corley", false],
    [1627822, "Cornelie", "Petr", "Petr Cornelie", false],
    [76453, "Corzine", "Dave", "Dave Corzine", false],
    [76454, "Costello", "Larry", "Larry Costello", false],
    [1627856, "Costello", "Matt", "Matt Costello", false],
    [203955, "Cotton", "Bryce", "Bryce Cotton", false],
    [76456, "Cotton", "Jack", "Jack Cotton", false],
    [1526, "Cotton", "James", "James Cotton", false],
    [76457, "Coughran", "John", "John Coughran", false],
    [76458, "Counts", "Mel", "Mel Counts", false],
    [76459, "Courtin", "Steve", "Steve Courtin", false],
    [1432, "Courtney", "Joe", "Joe Courtney", false],
    [202408, "Cousin", "Marcus", "Marcus Cousin", false],
    [202326, "Cousins", "DeMarcus", "DeMarcus Cousins", false],
    [600003, "Cousy", "Bob", "Bob Cousy", false],
    [203496, "Covington", "Robert", "Robert Covington", true],
    [76462, "Cowens", "Dave", "Dave Cowens", false],
    [76463, "Cox", "John", "John Cox", false],
    [76464, "Cox", "Johnny", "Johnny Cox", false],
    [76465, "Cox", "Wesley", "Wesley Cox", false],
    [203459, "Crabbe", "Allen", "Allen Crabbe", false],
    [1628470, "Craig", "Torrey", "Torrey Craig", true],
    [1544, "Crawford", "Chris", "Chris Crawford", false],
    [76466, "Crawford", "Freddie", "Freddie Crawford", false],
    [2037, "Crawford", "Jamal", "Jamal Crawford", false],
    [201621, "Crawford", "Joe", "Joe Crawford", false],
    [202348, "Crawford", "Jordan", "Jordan Crawford", false],
    [1628249, "Creek", "Mitchell", "Mitchell Creek", false],
    [76468, "Creighton", "Jim", "Jim Creighton", false],
    [76469, "Crevier", "Ron", "Ron Crevier", false],
    [76470, "Crisler", "Hal", "Hal Crisler", false],
    [2343, "Crispin", "Joe", "Joe Crispin", false],
    [76471, "Criss", "Charlie", "Charlie Criss", false],
    [76472, "Crite", "Winston", "Winston Crite", false],
    [201159, "Crittenton", "Javaris", "Javaris Crittenton", false],
    [76473, "Crocker", "Dillard", "Dillard Crocker", false],
    [76474, "Crompton", "Jeffrey", "Jeffrey Crompton", false],
    [76475, "Crosby", "Terry", "Terry Crosby", false],
    [1506, "Croshere", "Austin", "Austin Croshere", false],
    [76476, "Cross", "Jeff", "Jeff Cross", false],
    [76477, "Cross", "Pete", "Pete Cross", false],
    [76478, "Cross", "Russell", "Russell Cross", false],
    [76479, "Crossin", "Francis", "Francis Crossin", false],
    [180, "Crotty", "John", "John Crotty", false],
    [76481, "Crow", "Mark", "Mark Crow", false],
    [76482, "Crowder", "Corey", "Corey Crowder", false],
    [203109, "Crowder", "Jae", "Jae Crowder", true],
    [1629633, "Culver", "Jarrett", "Jarrett Culver", true],
    [1629600, "Cumberland", "Jarron", "Jarron Cumberland", false],
    [76483, "Cummings", "Pat", "Pat Cummings", false],
    [187, "Cummings", "Terry", "Terry Cummings", false],
    [1907, "Cummings", "Vonteego", "Vonteego Cummings", false],
    [76487, "Cunningham", "Billy", "Billy Cunningham", false],
    [1630595, "Cunningham", "Cade", "Cade Cunningham", true],
    [201967, "Cunningham", "Dante", "Dante Cunningham", false],
    [76485, "Cunningham", "Dick", "Dick Cunningham", false],
    [203099, "Cunningham", "Jared", "Jared Cunningham", false],
    [1061, "Cunningham", "William", "William Cunningham", false],
    [76488, "Curcic", "Radisav", "Radisav Curcic", false],
    [76489, "Cure", "Armand", "Armand Cure", false],
    [940, "Cureton", "Earl", "Earl Cureton", false],
    [223, "Curley", "Bill", "Bill Curley", false],
    [76492, "Curran", "Fran", "Fran Curran", false],
    [209, "Curry", "Dell", "Dell Curry", false],
    [2201, "Curry", "Eddy", "Eddy Curry", false],
    [201191, "Curry", "JamesOn", "JamesOn Curry", false],
    [688, "Curry", "Michael", "Michael Curry", false],
    [203552, "Curry", "Seth", "Seth Curry", true],
    [201939, "Curry", "Stephen", "Stephen Curry", true],
    [687, "Cvetkovic", "Rastko", "Rastko Cvetkovic", false],
    [76505, "D'Antoni", "Mike", "Mike D'Antoni", false],
    [76496, "Dahler", "Ed", "Ed Dahler", false],
    [76497, "Dailey", "Quintin", "Quintin Dailey", false],
    [2223, "Dalembert", "Samuel", "Samuel Dalembert", false],
    [76498, "Dallmar", "Howie", "Howie Dallmar", false],
    [956, "Dampier", "Erick", "Erick Dampier", false],
    [76499, "Dampier", "Lou", "Lou Dampier", false],
    [76500, "Dandridge", "Bob", "Bob Dandridge", false],
    [1498, "Daniels", "Antonio", "Antonio Daniels", false],
    [1630700, "Daniels", "Dyson", "Dyson Daniels", true],
    [2845, "Daniels", "Erik", "Erik Daniels", false],
    [1380, "Daniels", "Lloyd", "Lloyd Daniels", false],
    [2605, "Daniels", "Marquis", "Marquis Daniels", false],
    [76502, "Daniels", "Mel", "Mel Daniels", false],
    [203584, "Daniels", "Troy", "Troy Daniels", false],
    [390, "Danilovic", "Sasha", "Sasha Danilovic", false],
    [76504, "Dantley", "Adrian", "Adrian Dantley", false],
    [76506, "Darcey", "Henry", "Henry Darcey", false],
    [76507, "Darden", "Jimmy", "Jimmy Darden", false],
    [212, "Dare", "Yinka", "Yinka Dare", false],
    [76509, "Dark", "Jesse", "Jesse Dark", false],
    [1630268, "Darling", "Nate", "Nate Darling", false],
    [76511, "Darrow", "Jimmy", "Jimmy Darrow", false],
    [203540, "Datome", "Gigi", "Gigi Datome", false],
    [921, "Daugherty", "Brad", "Brad Daugherty", false],
    [1600, "David", "Kornel", "Kornel David", false],
    [201176, "Davidson", "Jermareo", "Jermareo Davidson", false],
    [76514, "Davies", "Bob", "Bob Davies", false],
    [203561, "Davies", "Brandon", "Brandon Davies", false],
    [203076, "Davis", "Anthony", "Anthony Davis", true],
    [213, "Davis", "Antonio", "Antonio Davis", false],
    [76515, "Davis", "Aubrey", "Aubrey Davis", false],
    [1884, "Davis", "Baron", "Baron Davis", false],
    [989, "Davis", "Ben", "Ben Davis", false],
    [76538, "Davis", "Bill", "Bill Davis", false],
    [76533, "Davis", "Bob", "Bob Davis", false],
    [76516, "Davis", "Brad", "Brad Davis", false],
    [76517, "Davis", "Brian", "Brian Davis", false],
    [76518, "Davis", "Charlie", "Charlie Davis", false],
    [76519, "Davis", "Charlie", "Charlie Davis", false],
    [905, "Davis", "Dale", "Dale Davis", false],
    [1627738, "Davis", "Deyonta", "Deyonta Davis", false],
    [76521, "Davis", "Double D", "Double D Davis", false],
    [202334, "Davis", "Ed", "Ed Davis", false],
    [76522, "Davis", "Edward", "Edward Davis", false],
    [1023, "Davis", "Emanual", "Emanual Davis", false],
    [201175, "Davis", "Glen", "Glen Davis", false],
    [76523, "Davis", "Harry", "Harry Davis", false],
    [93, "Davis", "Hubert", "Hubert Davis", false],
    [76524, "Davis", "James", "James Davis", false],
    [76525, "Davis", "Jim", "Jim Davis", false],
    [76526, "Davis", "Johnny", "Johnny Davis", false],
    [1631098, "Davis", "Johnny", "Johnny Davis", true],
    [2668, "Davis", "Josh", "Josh Davis", false],
    [707, "Davis", "Mark", "Mark Davis", false],
    [76528, "Davis", "Mark", "Mark Davis", false],
    [76529, "Davis", "Mel", "Mel Davis", false],
    [76530, "Davis", "Michael", "Michael Davis", false],
    [76531, "Davis", "Mike", "Mike Davis", false],
    [76520, "Davis", "Monti", "Monti Davis", false],
    [200781, "Davis", "Paul", "Paul Davis", false],
    [76532, "Davis", "Ralph", "Ralph Davis", false],
    [1729, "Davis", "Ricky", "Ricky Davis", false],
    [76534, "Davis", "Ron", "Ron Davis", false],
    [1629056, "Davis", "Terence", "Terence Davis", true],
    [426, "Davis", "Terry", "Terry Davis", false],
    [1629093, "Davis", "Tyler", "Tyler Davis", false],
    [76536, "Davis", "Walt", "Walt Davis", false],
    [1453, "Davis", "Walter", "Walter Davis", false],
    [1631120, "Davison", "JD", "JD Davison", true],
    [203958, "Dawkins", "Andre", "Andre Dawkins", false],
    [76539, "Dawkins", "Darryl", "Darryl Dawkins", false],
    [1008, "Dawkins", "Johnny", "Johnny Dawkins", false],
    [76541, "Dawkins", "Paul", "Paul Dawkins", false],
    [1626183, "Dawson", "Branden", "Branden Dawson", false],
    [201286, "Dawson", "Eric", "Eric Dawson", false],
    [76542, "Dawson", "Tony", "Tony Dawson", false],
    [103, "Day", "Todd", "Todd Day", false],
    [201948, "Daye", "Austin", "Austin Daye", false],
    [76543, "Daye", "Darren", "Darren Daye", false],
    [1630620, "Days", "Darius", "Darius Days", true],
    [201986, "De Colo", "Nando", "Nando De Colo", false],
    [76545, "DeBusschere", "Dave", "Dave DeBusschere", false],
    [692, "DeClercq", "Andrew", "Andrew DeClercq", false],
    [1629602, "DeLaurier", "Javin", "Javin DeLaurier", false],
    [76551, "DeLong", "Nate", "Nate DeLong", false],
    [201942, "DeRozan", "DeMar", "DeMar DeRozan", true],
    [76562, "DeZonie", "Hank", "Hank DeZonie", false],
    [76544, "Deane", "Greg", "Greg Deane", false],
    [1630466, "Deck", "Gabriel", "Gabriel Deck", false],
    [203473, "Dedmon", "Dewayne", "Dewayne Dedmon", true],
    [76546, "Dees", "Archie", "Archie Dees", false],
    [143, "Dehere", "Terry", "Terry Dehere", false],
    [76548, "Dehnert", "Red", "Red Dehnert", false],
    [1626214, "Dejean-Jones", "Bryce", "Bryce Dejean-Jones", false],
    [1626155, "Dekker", "Sam", "Sam Dekker", false],
    [219, "Del Negro", "Vinny", "Vinny Del Negro", false],
    [1627098, "Delaney", "Malcolm", "Malcolm Delaney", false],
    [183, "Dele", "Bison", "Bison Dele", false],
    [2568, "Delfino", "Carlos", "Carlos Delfino", false],
    [1629116, "Delgado", "Angel", "Angel Delgado", false],
    [960, "Delk", "Tony", "Tony Delk", false],
    [203521, "Dellavedova", "Matthew", "Matthew Dellavedova", true],
    [76552, "Dembo", "Fennis", "Fennis Dembo", false],
    [76553, "Demic", "Larry", "Larry Demic", false],
    [725, "Demps", "Dell", "Dell Demps", false],
    [76555, "Dempsey", "George", "George Dempsey", false],
    [2736, "Deng", "Luol", "Luol Deng", false],
    [76556, "Dennard", "Kenny", "Kenny Dennard", false],
    [76557, "Denning", "Blaine", "Blaine Denning", false],
    [202458, "Dentmon", "Justin", "Justin Dentmon", false],
    [76558, "Denton", "Randy", "Randy Denton", false],
    [76559, "Derline", "Rod", "Rod Derline", false],
    [1629094, "Derrickson", "Marcus", "Marcus Derrickson", false],
    [76560, "Deutsch", "Dave", "Dave Deutsch", false],
    [76561, "Devlin", "Walter", "Walter Devlin", false],
    [76568, "DiGregorio", "Ernie", "Ernie DiGregorio", false],
    [1628978, "DiVincenzo", "Donte", "Donte DiVincenzo", true],
    [1631217, "Diabate", "Moussa", "Moussa Diabate", true],
    [1629603, "Diakite", "Mamadi", "Mamadi Diakite", true],
    [1760, "Dial", "Derrick", "Derrick Dial", false],
    [1627767, "Diallo", "Cheick", "Cheick Diallo", false],
    [1628977, "Diallo", "Hamidou", "Hamidou Diallo", true],
    [2564, "Diaw", "Boris", "Boris Diaw", false],
    [200821, "Diawara", "Yakhouba", "Yakhouba Diawara", false],
    [200799, "Diaz", "Guillermo", "Guillermo Diaz", false],
    [2424, "Dickau", "Dan", "Dan Dickau", false],
    [2079, "Dickens", "Kaniel", "Kaniel Dickens", false],
    [76563, "Dickerson", "Henry", "Henry Dickerson", false],
    [1722, "Dickerson", "Michael", "Michael Dickerson", false],
    [76564, "Dickey", "Derrek", "Derrek Dickey", false],
    [76565, "Dickey", "Dick", "Dick Dickey", false],
    [101143, "Diener", "Travis", "Travis Diener", false],
    [203476, "Dieng", "Gorgui", "Gorgui Dieng", true],
    [1631172, "Dieng", "Ousmane", "Ousmane Dieng", true],
    [76566, "Dierking", "Connie", "Connie Dierking", false],
    [76567, "Dietrick", "Coby", "Coby Dietrick", false],
    [76569, "Dillard", "Mickey", "Mickey Dillard", false],
    [76570, "Dille", "Bob", "Bob Dille", false],
    [76571, "Dillon", "John", "John Dillon", false],
    [76574, "Dinkins", "Byron", "Byron Dinkins", false],
    [76575, "Dinkins", "Jackie", "Jackie Dinkins", false],
    [76576, "Dinwiddie", "Bill", "Bill Dinwiddie", false],
    [203915, "Dinwiddie", "Spencer", "Spencer Dinwiddie", true],
    [101113, "Diogu", "Ike", "Ike Diogu", false],
    [2205, "Diop", "DeSagana", "DeSagana Diop", false],
    [76577, "Dischinger", "Terry", "Terry Dischinger", false],
    [76578, "Diute", "Fred", "Fred Diute", false],
    [124, "Divac", "Vlade", "Vlade Divac", false],
    [2413, "Dixon", "Juan", "Juan Dixon", false],
    [1059, "Djordjevic", "Aleksandar", "Aleksandar Djordjevic", false],
    [76580, "Dodd", "Earl", "Earl Dodd", false],
    [1720, "Doleac", "Michael", "Michael Doleac", false],
    [76581, "Dolhon", "Joe", "Joe Dolhon", false],
    [76582, "Doll", "Bob", "Bob Doll", false],
    [76583, "Donaldson", "James", "James Donaldson", false],
    [1629029, "Doncic", "Luka", "Luka Doncic", true],
    [76584, "Donham", "Bob", "Bob Donham", false],
    [76586, "Donovan", "Billy", "Billy Donovan", false],
    [76585, "Donovan", "Harry", "Harry Donovan", false],
    [2039, "Dooling", "Keyon", "Keyon Dooling", false],
    [76587, "Dorsey", "Jacky", "Jacky Dorsey", false],
    [201595, "Dorsey", "Joey", "Joey Dorsey", false],
    [1628416, "Dorsey", "Tyler", "Tyler Dorsey", true],
    [1629652, "Dort", "Luguentz", "Luguentz Dort", true],
    [1630245, "Dosunmu", "Ayo", "Ayo Dosunmu", true],
    [1628422, "Dotson", "Damyean", "Damyean Dotson", false],
    [1629653, "Dotson", "Devon", "Devon Dotson", true],
    [200763, "Douby", "Quincy", "Quincy Douby", false],
    [76588, "Douglas", "Bruce", "Bruce Douglas", false],
    [76589, "Douglas", "John", "John Douglas", false],
    [76590, "Douglas", "Leon", "Leon Douglas", false],
    [428, "Douglas", "Sherman", "Sherman Douglas", false],
    [201962, "Douglas", "Toney", "Toney Douglas", false],
    [201604, "Douglas-Roberts", "Chris", "Chris Douglas-Roberts", false],
    [1629635, "Doumbouya", "Sekou", "Sekou Doumbouya", false],
    [76592, "Dove", "Lloyd", "Lloyd Dove", false],
    [202220, "Dowdell", "Zabian", "Zabian Dowdell", false],
    [76593, "Downey", "Bill", "Bill Downey", false],
    [76594, "Downing", "Steve", "Steve Downing", false],
    [1630288, "Dowtin Jr.", "Jeff", "Jeff Dowtin Jr.", true],
    [76595, "Doyle", "Danny", "Danny Doyle", false],
    [1628495, "Doyle", "Milton", "Milton Doyle", false],
    [1628408, "Dozier", "PJ", "PJ Dozier", true],
    [76596, "Dozier", "Terry", "Terry Dozier", false],
    [201609, "Dragic", "Goran", "Goran Dragic", true],
    [204054, "Dragic", "Zoran", "Zoran Dragic", false],
    [1068, "Dreiling", "Greg", "Greg Dreiling", false],
    [1724, "Drew", "Bryce", "Bryce Drew", false],
    [76598, "Drew", "John", "John Drew", false],
    [76599, "Drew", "Larry", "Larry Drew", false],
    [203580, "Drew II", "Larry", "Larry Drew II", false],
    [17, "Drexler", "Clyde", "Clyde Drexler", false],
    [1041, "Driggers", "Nate", "Nate Driggers", false],
    [76603, "Driscoll", "Terry", "Terry Driscoll", false],
    [1542, "Drobnjak", "Predrag", "Predrag Drobnjak", false],
    [76604, "Drollinger", "Ralph", "Ralph Drollinger", false],
    [203083, "Drummond", "Andre", "Andre Drummond", true],
    [76623, "DuVal", "Dennis", "Dennis DuVal", false],
    [1630537, "Duarte", "Chris", "Chris Duarte", true],
    [76605, "Duckett", "Dick", "Dick Duckett", false],
    [404, "Duckworth", "Kevin", "Kevin Duckworth", false],
    [76607, "Dudley", "Charles", "Charles Dudley", false],
    [201, "Dudley", "Chris", "Chris Dudley", false],
    [201162, "Dudley", "Jared", "Jared Dudley", false],
    [76608, "Duerod", "Terry", "Terry Duerod", false],
    [76609, "Duffy", "Bob", "Bob Duffy", false],
    [76610, "Duffy", "Bob", "Bob Duffy", false],
    [2768, "Duhon", "Chris", "Chris Duhon", false],
    [1626251, "Dukan", "Duje", "Duje Dukan", false],
    [1630561, "Duke Jr.", "David", "David Duke Jr.", true],
    [76611, "Dukes", "Walter", "Walter Dukes", false],
    [247, "Dumars", "Joe", "Joe Dumars", false],
    [30, "Dumas", "Richard", "Richard Dumas", false],
    [434, "Dumas", "Tony", "Tony Dumas", false],
    [76615, "Duncan", "Andy", "Andy Duncan", false],
    [1495, "Duncan", "Tim", "Tim Duncan", false],
    [2399, "Dunleavy", "Mike", "Mike Dunleavy", false],
    [76616, "Dunleavy", "Mike", "Mike Dunleavy", false],
    [1627739, "Dunn", "Kris", "Kris Dunn", true],
    [76617, "Dunn", "Pat", "Pat Dunn", false],
    [76618, "Dunn", "T.R.", "T.R. Dunn", false],
    [2648, "Dupree", "Ronald", "Ronald Dupree", false],
    [201142, "Durant", "Kevin", "Kevin Durant", true],
    [1631105, "Duren", "Jalen", "Jalen Duren", true],
    [76619, "Duren", "John", "John Duren", false],
    [1090, "Durham", "Pat", "Pat Durham", false],
    [76621, "Durrant", "Devin", "Devin Durrant", false],
    [76622, "Durrett", "Ken", "Ken Durrett", false],
    [1628979, "Duval", "Trevon", "Trevon Duval", false],
    [76624, "Dwan", "Jack", "Jack Dwan", false],
    [76625, "Dykema", "Craig", "Craig Dykema", false],
    [76626, "Dyker", "Gene", "Gene Dyker", false],
    [202406, "Dyson", "Jerome", "Jerome Dyson", false],
    [289, "Eackles", "Ledell", "Ledell Eackles", false],
    [76628, "Eakins", "Jim", "Jim Eakins", false],
    [768, "Earl", "Acie", "Acie Earl", false],
    [76630, "Earle", "Ed", "Ed Earle", false],
    [203921, "Early", "Cleanthony", "Cleanthony Early", false],
    [1631106, "Eason", "Tari", "Tari Eason", true],
    [76631, "Eaton", "Mark", "Mark Eaton", false],
    [76632, "Eaves", "Jerry", "Jerry Eaves", false],
    [202365, "Ebanks", "Devin", "Devin Ebanks", false],
    [76633, "Ebben", "Bill", "Bill Ebben", false],
    [76634, "Eberhard", "Al", "Al Eberhard", false],
    [2569, "Ebi", "Ndudi", "Ndudi Ebi", false],
    [1630693, "Echenique", "Jaime", "Jaime Echenique", false],
    [204067, "Eddie", "Jarell", "Jarell Eddie", false],
    [76635, "Eddie", "Patrick", "Patrick Eddie", false],
    [76636, "Eddleman", "Thomas", "Thomas Eddleman", false],
    [76637, "Edelin", "Kent", "Kent Edelin", false],
    [76638, "Edmonson", "Keith", "Keith Edmonson", false],
    [721, "Edney", "Tyus", "Tyus Edney", false],
    [1630162, "Edwards", "Anthony", "Anthony Edwards", true],
    [76646, "Edwards", "Bill", "Bill Edwards", false],
    [898, "Edwards", "Blue", "Blue Edwards", false],
    [1629035, "Edwards", "Carsen", "Carsen Edwards", false],
    [2451, "Edwards", "Corsley", "Corsley Edwards", false],
    [346, "Edwards", "Doug", "Doug Edwards", false],
    [76641, "Edwards", "Franklin", "Franklin Edwards", false],
    [229, "Edwards", "James", "James Edwards", false],
    [76643, "Edwards", "Jay", "Jay Edwards", false],
    [2823, "Edwards", "John", "John Edwards", false],
    [1630556, "Edwards", "Kessler", "Kessler Edwards", true],
    [236, "Edwards", "Kevin", "Kevin Edwards", false],
    [1630306, "Edwards", "Rob", "Rob Edwards", false],
    [202197, "Edwards", "Shane", "Shane Edwards", false],
    [1629053, "Edwards", "Vincent", "Vincent Edwards", false],
    [76647, "Egan", "Johnny", "Johnny Egan", false],
    [76648, "Eggleston", "Lonnie", "Lonnie Eggleston", false],
    [76649, "Ehlers", "Eddie", "Eddie Ehlers", false],
    [378, "Ehlo", "Craig", "Craig Ehlo", false],
    [76651, "Eichhorst", "Dick", "Dick Eichhorst", false],
    [458, "Eisley", "Howard", "Howard Eisley", false],
    [1918, "Ekezie", "Obinna", "Obinna Ekezie", false],
    [2064, "El-Amin", "Khalid", "Khalid El-Amin", false],
    [76653, "Eliason", "Don", "Don Eliason", false],
    [53, "Elie", "Mario", "Mario Elie", false],
    [1629604, "Elleby", "CJ", "CJ Elleby", false],
    [76655, "Ellefson", "Ray", "Ray Ellefson", false],
    [1627740, "Ellenson", "Henry", "Henry Ellenson", false],
    [201961, "Ellington", "Wayne", "Wayne Ellington", false],
    [76656, "Elliott", "Bob", "Bob Elliott", false],
    [251, "Elliott", "Sean", "Sean Elliott", false],
    [76658, "Ellis", "Alexander", "Alexander Ellis", false],
    [76664, "Ellis", "Bo", "Bo Ellis", false],
    [107, "Ellis", "Dale", "Dale Ellis", false],
    [1677, "Ellis", "Harold", "Harold Ellis", false],
    [76661, "Ellis", "Joe", "Joe Ellis", false],
    [1631165, "Ellis", "Keon", "Keon Ellis", true],
    [111, "Ellis", "LaPhonso", "LaPhonso Ellis", false],
    [1034, "Ellis", "LeRon", "LeRon Ellis", false],
    [76663, "Ellis", "Leroy", "Leroy Ellis", false],
    [101145, "Ellis", "Monta", "Monta Ellis", false],
    [442, "Ellison", "Pervis", "Pervis Ellison", false],
    [600010, "Elmore", "Len", "Len Elmore", false],
    [1922, "Elson", "Francisco", "Francisco Elson", false],
    [76667, "Elston", "Darrell", "Darrell Elston", false],
    [2408, "Ely", "Melvin", "Melvin Ely", false],
    [203954, "Embiid", "Joel", "Joel Embiid", true],
    [76668, "Embry", "Wayne", "Wayne Embry", false],
    [2765, "Emmett", "Andre", "Andre Emmett", false],
    [76669, "Endress", "Ned", "Ned Endress", false],
    [76670, "Engler", "Chris", "Chris Engler", false],
    [76671, "Englestad", "Wayne", "Wayne Englestad", false],
    [76672, "English", "A.J.", "A.J. English", false],
    [76673, "English", "Alex", "Alex English", false],
    [76674, "English", "Claude", "Claude English", false],
    [76676, "English", "Jojo", "Jojo English", false],
    [203119, "English", "Kim", "Kim English", false],
    [76675, "English", "Scott", "Scott English", false],
    [76677, "Englund", "Gene", "Gene Englund", false],
    [203898, "Ennis", "Tyler", "Tyler Ennis", false],
    [203516, "Ennis III", "James", "James Ennis III", false],
    [76678, "Epps", "Ray", "Ray Epps", false],
    [201623, "Erden", "Semih", "Semih Erden", false],
    [76679, "Erias", "Bo", "Bo Erias", false],
    [76680, "Erickson", "Keith", "Keith Erickson", false],
    [76681, "Erving", "Julius", "Julius Erving", false],
    [1915, "Eschmeyer", "Evan", "Evan Eschmeyer", false],
    [76682, "Eskridge", "Jack", "Jack Eskridge", false],
    [76683, "Esposito", "Vincenzo", "Vincenzo Esposito", false],
    [1629234, "Eubanks", "Drew", "Drew Eubanks", true],
    [76687, "Evans", "Bob", "Bob Evans", false],
    [967, "Evans", "Brian", "Brian Evans", false],
    [76685, "Evans", "Earl", "Earl Evans", false],
    [1628980, "Evans", "Jacob", "Jacob Evans", false],
    [1628393, "Evans", "Jawun", "Jawun Evans", false],
    [202379, "Evans", "Jeremy", "Jeremy Evans", false],
    [2230, "Evans", "Maurice", "Maurice Evans", false],
    [76686, "Evans", "Mike", "Mike Evans", false],
    [2501, "Evans", "Reggie", "Reggie Evans", false],
    [201936, "Evans", "Tyreke", "Tyreke Evans", false],
    [101137, "Ewing", "Daniel", "Daniel Ewing", false],
    [121, "Ewing", "Patrick", "Patrick Ewing", false],
    [201607, "Ewing", "Patrick", "Patrick Ewing", false],
    [203957, "Exum", "Dante", "Dante Exum", false],
    [201963, "Eyenga", "Christian", "Christian Eyenga", false],
    [203105, "Ezeli", "Festus", "Festus Ezeli", false],
    [76689, "Ezersky", "Johnny", "Johnny Ezersky", false],
    [76690, "Fabel", "Joe", "Joe Fabel", false],
    [76691, "Fairchild", "John", "John Fairchild", false],
    [1629605, "Fall", "Tacko", "Tacko Fall", false],
    [76692, "Farbman", "Phil", "Phil Farbman", false],
    [202702, "Faried", "Kenneth", "Kenneth Faried", false],
    [76693, "Farley", "Dick", "Dick Farley", false],
    [200770, "Farmar", "Jordan", "Jordan Farmar", false],
    [2824, "Farmer", "Desmon", "Desmon Farmer", false],
    [76695, "Farmer", "Jim", "Jim Farmer", false],
    [76694, "Farmer", "Mike", "Mike Farmer", false],
    [1108, "Farmer", "Tony", "Tony Farmer", false],
    [76696, "Faught", "Bob", "Bob Faught", false],
    [203543, "Faverani", "Vitor", "Vitor Faverani", false],
    [202324, "Favors", "Derrick", "Derrick Favors", false],
    [201174, "Fazekas", "Nick", "Nick Fazekas", false],
    [76697, "Fedor", "Dave", "Dave Fedor", false],
    [76698, "Feerick", "Bob", "Bob Feerick", false],
    [76699, "Feher", "Butch", "Butch Feher", false],
    [994, "Feick", "Jamie", "Jamie Feick", false],
    [76701, "Feiereisel", "Ron", "Ron Feiereisel", false],
    [76702, "Feigenbaum", "George", "George Feigenbaum", false],
    [76703, "Feitl", "Dave", "Dave Feitl", false],
    [1627770, "Felder", "Kay", "Kay Felder", false],
    [1626245, "Felicio", "Cristiano", "Cristiano Felicio", false],
    [203467, "Felix", "Carrick", "Carrick Felix", false],
    [101230, "Felix", "Noel", "Noel Felix", false],
    [76704, "Felix", "Ray", "Ray Felix", false],
    [101109, "Felton", "Raymond", "Raymond Felton", false],
    [76705, "Fendley", "Jake", "Jake Fendley", false],
    [76706, "Fenley", "Bill", "Bill Fenley", false],
    [2724, "Ferguson", "Desmond", "Desmond Ferguson", false],
    [1628390, "Ferguson", "Terrance", "Terrance Ferguson", false],
    [201164, "Fernandez", "Rudy", "Rudy Fernandez", false],
    [1628981, "Fernando", "Bruno", "Bruno Fernando", true],
    [76707, "Fernsten", "Eric", "Eric Fernsten", false],
    [76708, "Ferrari", "Al", "Al Ferrari", false],
    [76709, "Ferreira", "Rolando", "Rolando Ferreira", false],
    [273, "Ferrell", "Duane", "Duane Ferrell", false],
    [1627812, "Ferrell", "Yogi", "Yogi Ferrell", false],
    [76711, "Ferrin", "Arnie", "Arnie Ferrin", false],
    [76712, "Ferry", "Bob", "Bob Ferry", false],
    [198, "Ferry", "Danny", "Danny Ferry", false],
    [201178, "Fesenko", "Kyrylo", "Kyrylo Fesenko", false],
    [76713, "Fields", "Kenny", "Kenny Fields", false],
    [202361, "Fields", "Landry", "Landry Fields", false],
    [76714, "Filipek", "Ron", "Ron Filipek", false],
    [76715, "Fillmore", "Greg", "Greg Fillmore", false],
    [76716, "Finkel", "Hank", "Hank Finkel", false],
    [714, "Finley", "Michael", "Michael Finley", false],
    [76717, "Finn", "Danny", "Danny Finn", false],
    [1627827, "Finney-Smith", "Dorian", "Dorian Finney-Smith", true],
    [900, "Fish", "Matt", "Matt Fish", false],
    [965, "Fisher", "Derek", "Derek Fisher", false],
    [2809, "Fitch", "Gerald", "Gerald Fitch", false],
    [1630238, "Fitts", "Malik", "Malik Fitts", false],
    [76720, "Fitzgerald", "Bob", "Bob Fitzgerald", false],
    [76719, "Fitzgerald", "Dick", "Dick Fitzgerald", false],
    [2033, "Fizer", "Marcus", "Marcus Fizer", false],
    [76721, "Fleishman", "Jerry", "Jerry Fleishman", false],
    [76722, "Fleming", "Al", "Al Fleming", false],
    [76723, "Fleming", "Ed", "Ed Fleming", false],
    [313, "Fleming", "Vern", "Vern Fleming", false],
    [2784, "Flores", "Luis", "Luis Flores", false],
    [76725, "Flowers", "Bruce", "Bruce Flowers", false],
    [76726, "Floyd", "Sleepy", "Sleepy Floyd", false],
    [201938, "Flynn", "Jonny", "Jonny Flynn", false],
    [1630201, "Flynn", "Malachi", "Malachi Flynn", true],
    [76727, "Flynn", "Mike", "Mike Flynn", false],
    [76728, "Fogle", "Larry", "Larry Fogle", false],
    [76729, "Foley", "Jack", "Jack Foley", false],
    [1829, "Fontaine", "Ike", "Ike Fontaine", false],
    [76731, "Fontaine", "Levi", "Levi Fontaine", false],
    [1631323, "Fontecchio", "Simone", "Simone Fontecchio", true],
    [202880, "Foote", "Jeff", "Jeff Foote", false],
    [1627854, "Forbes", "Bryn", "Bryn Forbes", true],
    [201814, "Forbes", "Gary", "Gary Forbes", false],
    [1630758, "Ford", "Aleem", "Aleem Ford", false],
    [76732, "Ford", "Alphonso", "Alphonso Ford", false],
    [2228, "Ford", "Alton", "Alton Ford", false],
    [76733, "Ford", "Chris", "Chris Ford", false],
    [76734, "Ford", "Don", "Don Ford", false],
    [76735, "Ford", "Jake", "Jake Ford", false],
    [76736, "Ford", "Phil", "Phil Ford", false],
    [101213, "Ford", "Sharrod", "Sharrod Ford", false],
    [726, "Ford", "Sherell", "Sherell Ford", false],
    [2551, "Ford", "T.J.", "T.J. Ford", false],
    [76738, "Forman", "Donnie", "Donnie Forman", false],
    [76739, "Forrest", "Bayard", "Bayard Forrest", false],
    [1630235, "Forrest", "Trent", "Trent Forrest", true],
    [2218, "Forte", "Joseph", "Joseph Forte", false],
    [202622, "Fortson", "Courtney", "Courtney Fortson", false],
    [1504, "Fortson", "Danny", "Danny Fortson", false],
    [76740, "Foster", "Fred", "Fred Foster", false],
    [323, "Foster", "Greg", "Greg Foster", false],
    [1902, "Foster", "Jeff", "Jeff Foster", false],
    [76742, "Foster", "Rod", "Rod Foster", false],
    [1630701, "Foster Jr.", "Michael", "Michael Foster Jr.", true],
    [2238, "Fotsis", "Antonis", "Antonis Fotsis", false],
    [203095, "Fournier", "Evan", "Evan Fournier", true],
    [76744, "Foust", "Larry", "Larry Foust", false],
    [76745, "Fowler", "Jerry", "Jerry Fowler", false],
    [1762, "Fowlkes", "Tremaine", "Tremaine Fowlkes", false],
    [1628368, "Fox", "De'Aaron", "De'Aaron Fox", true],
    [76746, "Fox", "Harold", "Harold Fox", false],
    [76747, "Fox", "Jim", "Jim Fox", false],
    [296, "Fox", "Rick", "Rick Fox", false],
    [200751, "Foye", "Randy", "Randy Foye", false],
    [1502, "Foyle", "Adonal", "Adonal Foyle", false],
    [2499, "Frahm", "Richie", "Richie Frahm", false],
    [1883, "Francis", "Steve", "Steve Francis", false],
    [76748, "Frank", "Tellis", "Tellis Frank", false],
    [76749, "Frankel", "Nat", "Nat Frankel", false],
    [203479, "Franklin", "Jamaal", "Jamaal Franklin", false],
    [1629606, "Franks", "Robert", "Robert Franks", false],
    [204025, "Frazier", "Tim", "Tim Frazier", false],
    [76750, "Frazier", "Walt", "Walt Frazier", false],
    [76751, "Frazier", "Will", "Will Frazier", false],
    [1626187, "Frazier II", "Michael", "Michael Frazier II", false],
    [1628982, "Frazier Jr.", "Melvin", "Melvin Frazier Jr.", false],
    [76752, "Frederick", "Anthony", "Anthony Frederick", false],
    [202690, "Fredette", "Jimmer", "Jimmer Fredette", false],
    [76753, "Free", "World", "World Free", false],
    [202683, "Freedom", "Enes", "Enes Freedom", false],
    [200777, "Freeland", "Joel", "Joel Freeland", false],
    [76754, "Freeman", "Donnie", "Donnie Freeman", false],
    [76755, "Freeman", "Gary", "Gary Freeman", false],
    [76756, "Freeman", "Rod", "Rod Freeman", false],
    [2782, "Freije", "Matt", "Matt Freije", false],
    [76757, "Frey", "Frido", "Frido Frey", false],
    [76758, "Friend", "Larry", "Larry Friend", false],
    [76759, "Frink", "Pat", "Pat Frink", false],
    [76760, "Fritsche", "Jim", "Jim Fritsche", false],
    [101112, "Frye", "Channing", "Channing Frye", false],
    [76761, "Fryer", "Bernie", "Bernie Fryer", false],
    [76762, "Fucarino", "Frank", "Frank Fucarino", false],
    [76763, "Fuetsch", "Herm", "Herm Fuetsch", false],
    [76764, "Fulks", "Joe", "Joe Fulks", false],
    [2673, "Fuller", "Hiram", "Hiram Fuller", false],
    [957, "Fuller", "Todd", "Todd Fuller", false],
    [76765, "Fuller", "Tony", "Tony Fuller", false],
    [1628365, "Fultz", "Markelle", "Markelle Fultz", true],
    [1444, "Funderburke", "Lawrence", "Lawrence Funderburke", false],
    [76767, "Furlow", "Terry", "Terry Furlow", false],
    [76768, "Gabor", "Billy", "Billy Gabor", false],
    [1629117, "Gabriel", "Wenyen", "Wenyen Gabriel", true],
    [2429, "Gadzuric", "Dan", "Dan Gadzuric", false],
    [202070, "Gaffney", "Tony", "Tony Gaffney", false],
    [1629655, "Gafford", "Daniel", "Daniel Gafford", true],
    [101184, "Gai", "Deng", "Deng Gai", false],
    [76769, "Gainer", "Elmer R.", "Elmer R. Gainer", false],
    [76770, "Gaines", "Corey", "Corey Gaines", false],
    [2558, "Gaines", "Reece", "Reece Gaines", false],
    [202178, "Gaines", "Sundiata", "Sundiata Gaines", false],
    [76771, "Gale", "Mike", "Mike Gale", false],
    [76772, "Gallagher", "Chad", "Chad Gallagher", false],
    [76773, "Gallatin", "Harry", "Harry Gallatin", false],
    [201568, "Gallinari", "Danilo", "Danilo Gallinari", true],
    [204038, "Galloway", "Langston", "Langston Galloway", false],
    [76774, "Gambee", "Dave", "Dave Gambee", false],
    [333, "Gamble", "Kevin", "Kevin Gamble", false],
    [76776, "Gantt", "Bob", "Bob Gantt", false],
    [200816, "Garbajosa", "Jorge", "Jorge Garbajosa", false],
    [2092, "Garces", "Ruben", "Ruben Garces", false],
    [2682, "Garcia", "Alex", "Alex Garcia", false],
    [101128, "Garcia", "Francisco", "Francisco Garcia", false],
    [76778, "Gardner", "Earl", "Earl Gardner", false],
    [201242, "Gardner", "Thomas", "Thomas Gardner", false],
    [76779, "Gardner", "Vern", "Vern Gardner", false],
    [76780, "Garfinkel", "Jack", "Jack Garfinkel", false],
    [1627868, "Garino", "Patricio", "Patricio Garino", false],
    [1629636, "Garland", "Darius", "Darius Garland", true],
    [76781, "Garland", "Gary", "Gary Garland", false],
    [76782, "Garland", "Winston", "Winston Garland", false],
    [76783, "Garmaker", "Dick", "Dick Garmaker", false],
    [1612, "Garner", "Chris", "Chris Garner", false],
    [76785, "Garnett", "Bill", "Bill Garnett", false],
    [708, "Garnett", "Kevin", "Kevin Garnett", false],
    [1831, "Garnett", "Marlon", "Marlon Garnett", false],
    [1628656, "Garrett", "Billy", "Billy Garrett", false],
    [76786, "Garrett", "Calvin", "Calvin Garrett", false],
    [1051, "Garrett", "Dean", "Dean Garrett", false],
    [203197, "Garrett", "Diante", "Diante Garrett", false],
    [76788, "Garrett", "Eldo", "Eldo Garrett", false],
    [1630585, "Garrett", "Marcus", "Marcus Garrett", false],
    [76789, "Garrett", "Rowland", "Rowland Garrett", false],
    [76790, "Garrick", "Tom", "Tom Garrick", false],
    [76791, "Garris", "John", "John Garris", false],
    [1619, "Garris", "Kiwane", "Kiwane Garris", false],
    [1727, "Garrity", "Pat", "Pat Garrity", false],
    [1630586, "Garuba", "Usman", "Usman Garuba", true],
    [76793, "Garvin", "Jim", "Jim Garvin", false],
    [1630568, "Garza", "Luka", "Luka Garza", true],
    [201188, "Gasol", "Marc", "Marc Gasol", false],
    [2200, "Gasol", "Pau", "Pau Gasol", false],
    [76794, "Gates", "Frank", "Frank Gates", false],
    [423, "Gatling", "Chris", "Chris Gatling", false],
    [930, "Gattison", "Kenny", "Kenny Gattison", false],
    [200752, "Gay", "Rudy", "Rudy Gay", true],
    [76797, "Gayda", "Ed", "Ed Gayda", false],
    [1845, "Gaze", "Andrew", "Andrew Gaze", false],
    [1627771, "Gbinije", "Michael", "Michael Gbinije", false],
    [1002, "Geary", "Reggie", "Reggie Geary", false],
    [202087, "Gee", "Alonzo", "Alonzo Gee", false],
    [761, "Geiger", "Matt", "Matt Geiger", false],
    [101153, "Gelabale", "Mickael", "Mickael Gelabale", false],
    [1904, "George", "Devean", "Devean George", false],
    [76800, "George", "Jack", "Jack George", false],
    [202331, "George", "Paul", "Paul George", true],
    [76801, "George", "Tate", "Tate George", false],
    [1627875, "Georges-Hunt", "Marcus", "Marcus Georges-Hunt", false],
    [76802, "Gerard", "Gus", "Gus Gerard", false],
    [76803, "Gervin", "Derrick", "Derrick Gervin", false],
    [76804, "Gervin", "George", "George Gervin", false],
    [76805, "Getchell", "Gorham", "Gorham Getchell", false],
    [76806, "Gianelli", "John", "John Gianelli", false],
    [76807, "Gibbs", "Dick", "Dick Gibbs", false],
    [200789, "Gibson", "Daniel", "Daniel Gibson", false],
    [76808, "Gibson", "Dee", "Dee Gibson", false],
    [1626780, "Gibson", "Jonathan", "Jonathan Gibson", false],
    [76809, "Gibson", "Mel", "Mel Gibson", false],
    [76810, "Gibson", "Mike", "Mike Gibson", false],
    [201959, "Gibson", "Taj", "Taj Gibson", true],
    [76811, "Gibson", "Ward", "Ward Gibson", false],
    [201592, "Giddens", "J.R.", "J.R. Giddens", false],
    [1630581, "Giddey", "Josh", "Josh Giddey", true],
    [201821, "Gilder", "Trey", "Trey Gilder", false],
    [1628385, "Giles III", "Harry", "Harry Giles III", false],
    [1628983, "Gilgeous-Alexander", "Shai", "Shai Gilgeous-Alexander", true],
    [1630264, "Gill", "Anthony", "Anthony Gill", true],
    [2109, "Gill", "Eddie", "Eddie Gill", false],
    [383, "Gill", "Kendall", "Kendall Gill", false],
    [76812, "Gillery", "Ben", "Ben Gillery", false],
    [1631221, "Gillespie", "Collin", "Collin Gillespie", true],
    [1630273, "Gillespie", "Freddie", "Freddie Gillespie", false],
    [76813, "Gillette", "Gene", "Gene Gillette", false],
    [288, "Gilliam", "Armen", "Armen Gilliam", false],
    [76815, "Gilliam", "Herm", "Herm Gilliam", false],
    [600014, "Gilmore", "Artis", "Artis Gilmore", false],
    [76817, "Gilmore", "Walt", "Walt Gilmore", false],
    [76818, "Gilmur", "Chuck", "Chuck Gilmur", false],
    [1938, "Ginobili", "Manu", "Manu Ginobili", false],
    [1921, "Giricek", "Gordan", "Gordan Giricek", false],
    [76819, "Givens", "Jack", "Jack Givens", false],
    [202148, "Gladness", "Mickell", "Mickell Gladness", false],
    [76820, "Glamack", "George", "George Glamack", false],
    [76821, "Glass", "Gerald", "Gerald Glass", false],
    [76822, "Glenn", "Mike", "Mike Glenn", false],
    [76823, "Glick", "Norman", "Norman Glick", false],
    [76824, "Glouchkov", "Georgi", "Georgi Glouchkov", false],
    [76825, "Glover", "Clarence", "Clarence Glover", false],
    [1901, "Glover", "Dion", "Dion Glover", false],
    [2601, "Glyniadakis", "Andreas", "Andreas Glyniadakis", false],
    [76826, "Gminski", "Mike", "Mike Gminski", false],
    [203497, "Gobert", "Rudy", "Rudy Gobert", true],
    [76827, "Godfread", "Dan", "Dan Godfread", false],
    [76828, "Gola", "Tom", "Tom Gola", false],
    [76829, "Goldfaden", "Ben", "Ben Goldfaden", false],
    [924, "Goldwire", "Anthony", "Anthony Goldwire", false],
    [101155, "Gomes", "Ryan", "Ryan Gomes", false],
    [76830, "Gondrezick", "Glen", "Glen Gondrezick", false],
    [76831, "Gondrezick", "Grant", "Grant Gondrezick", false],
    [2400, "Gooden", "Drew", "Drew Gooden", false],
    [76832, "Goodrich", "Gail", "Gail Goodrich", false],
    [1864, "Goodrich", "Steve", "Steve Goodrich", false],
    [203462, "Goodwin", "Archie", "Archie Goodwin", false],
    [1629164, "Goodwin", "Brandon", "Brandon Goodwin", false],
    [1630692, "Goodwin", "Jordan", "Jordan Goodwin", true],
    [76833, "Goodwin", "Wilfred", "Wilfred Goodwin", false],
    [203932, "Gordon", "Aaron", "Aaron Gordon", true],
    [2732, "Gordon", "Ben", "Ben Gordon", false],
    [204079, "Gordon", "Drew", "Drew Gordon", false],
    [201569, "Gordon", "Eric", "Eric Gordon", true],
    [76834, "Gordon", "Lancaster", "Lancaster Gordon", false],
    [76835, "Gordon", "Paul", "Paul Gordon", false],
    [101162, "Gortat", "Marcin", "Marcin Gortat", false],
    [76836, "Gottlieb", "Leo", "Leo Gottlieb", false],
    [202726, "Goudelock", "Andrew", "Andrew Goudelock", false],
    [76837, "Govedarica", "Bato", "Bato Govedarica", false],
    [76838, "Graboski", "Joe", "Joe Graboski", false],
    [76839, "Grace", "Ricky", "Ricky Grace", false],
    [1628984, "Graham", "Devonte'", "Devonte' Graham", true],
    [37, "Graham", "Greg", "Greg Graham", false],
    [101121, "Graham", "Joey", "Joey Graham", false],
    [76843, "Graham", "Mal", "Mal Graham", false],
    [76841, "Graham", "Orlando", "Orlando Graham", false],
    [76842, "Graham", "Paul", "Paul Graham", false],
    [101211, "Graham", "Stephen", "Stephen Graham", false],
    [1626203, "Graham", "Treveon", "Treveon Graham", false],
    [76844, "Grandholm", "Jim", "Jim Grandholm", false],
    [97, "Grandison", "Ronnie", "Ronnie Grandison", false],
    [101122, "Granger", "Danny", "Danny Granger", false],
    [76847, "Granger", "Stewart", "Stewart Granger", false],
    [258, "Grant", "Brian", "Brian Grant", false],
    [76850, "Grant", "Bud", "Bud Grant", false],
    [202, "Grant", "Gary", "Gary Grant", false],
    [285, "Grant", "Greg", "Greg Grant", false],
    [265, "Grant", "Harvey", "Harvey Grant", false],
    [270, "Grant", "Horace", "Horace Grant", false],
    [203924, "Grant", "Jerami", "Jerami Grant", true],
    [1626170, "Grant", "Jerian", "Jerian Grant", false],
    [76852, "Grant", "Josh", "Josh Grant", false],
    [1514, "Grant", "Paul", "Paul Grant", false],
    [76853, "Grant", "Travis", "Travis Grant", false],
    [1629055, "Grantham", "Donte", "Donte Grantham", false],
    [76854, "Grate", "Don", "Don Grate", false],
    [76855, "Graves", "Butch", "Butch Graves", false],
    [1629755, "Gravett", "Hassani", "Hassani Gravett", false],
    [201189, "Gray", "Aaron", "Aaron Gray", false],
    [1070, "Gray", "Devin", "Devin Gray", false],
    [1516, "Gray", "Ed", "Ed Gray", false],
    [1026, "Gray", "Evric", "Evric Gray", false],
    [76859, "Gray", "Gary", "Gary Gray", false],
    [1627982, "Gray", "Josh", "Josh Gray", false],
    [76860, "Gray", "Leonard", "Leonard Gray", false],
    [76861, "Gray", "Stuart", "Stuart Gray", false],
    [76862, "Gray", "Sylvester", "Sylvester Gray", false],
    [76863, "Gray", "Wyndol", "Wyndol Gray", false],
    [81, "Grayer", "Jeff", "Jeff Grayer", false],
    [76865, "Greacen", "Bob", "Bob Greacen", false],
    [920, "Green", "A.C.", "A.C. Green", false],
    [1631260, "Green", "AJ", "AJ Green", true],
    [201980, "Green", "Danny", "Danny Green", true],
    [101209, "Green", "Devin", "Devin Green", false],
    [203110, "Green", "Draymond", "Draymond Green", true],
    [203475, "Green", "Erick", "Erick Green", false],
    [101123, "Green", "Gerald", "Gerald Green", false],
    [203210, "Green", "JaMychal", "JaMychal Green", true],
    [1630224, "Green", "Jalen", "Jalen Green", true],
    [1629750, "Green", "Javonte", "Javonte Green", true],
    [201145, "Green", "Jeff", "Jeff Green", true],
    [76867, "Green", "Johnny", "Johnny Green", false],
    [1630182, "Green", "Josh", "Josh Green", true],
    [76868, "Green", "Ken", "Ken Green", false],
    [76869, "Green", "Kenny", "Kenny Green", false],
    [600011, "Green", "Lamar", "Lamar Green", false],
    [1036, "Green", "Litterial", "Litterial Green", false],
    [76872, "Green", "Luther", "Luther Green", false],
    [76873, "Green", "Mike", "Mike Green", false],
    [76874, "Green", "Rickey", "Rickey Green", false],
    [76875, "Green", "Sean", "Sean Green", false],
    [76877, "Green", "Si", "Si Green", false],
    [76876, "Green", "Sidney", "Sidney Green", false],
    [76878, "Green", "Steve", "Steve Green", false],
    [201192, "Green", "Taurean", "Taurean Green", false],
    [76879, "Green", "Tommy", "Tommy Green", false],
    [2584, "Green", "Willie", "Willie Green", false],
    [201590, "Greene", "Donte", "Donte Greene", false],
    [101158, "Greene", "Orien", "Orien Greene", false],
    [76880, "Greenspan", "Jerry", "Jerry Greenspan", false],
    [76881, "Greenwood", "David", "David Greenwood", false],
    [76882, "Greer", "Hal", "Hal Greer", false],
    [2696, "Greer", "Lynn", "Lynn Greer", false],
    [76883, "Gregor", "Gary", "Gary Gregor", false],
    [76884, "Gregory", "Claude", "Claude Gregory", false],
    [76885, "Greig", "John", "John Greig", false],
    [76886, "Grekin", "Norm", "Norm Grekin", false],
    [76887, "Grevey", "Kevin", "Kevin Grevey", false],
    [1631100, "Griffin", "AJ", "AJ Griffin", true],
    [1559, "Griffin", "Adrian", "Adrian Griffin", false],
    [201933, "Griffin", "Blake", "Blake Griffin", true],
    [2204, "Griffin", "Eddie", "Eddie Griffin", false],
    [76888, "Griffin", "Greg", "Greg Griffin", false],
    [76889, "Griffin", "Paul", "Paul Griffin", false],
    [201981, "Griffin", "Taylor", "Taylor Griffin", false],
    [76890, "Griffith", "Darrell", "Darrell Griffith", false],
    [76891, "Grigsby", "Chuck", "Chuck Grigsby", false],
    [1629656, "Grimes", "Quentin", "Quentin Grimes", true],
    [1575, "Grimm", "Derek", "Derek Grimm", false],
    [76893, "Grimshaw", "George", "George Grimshaw", false],
    [76894, "Groat", "Dick", "Dick Groat", false],
    [76895, "Gross", "Bob", "Bob Gross", false],
    [76896, "Grote", "Jerry", "Jerry Grote", false],
    [76897, "Groza", "Alex", "Alex Groza", false],
    [101219, "Grundy", "Anthony", "Anthony Grundy", false],
    [76899, "Grunfeld", "Ernie", "Ernie Grunfeld", false],
    [76900, "Guarilia", "Gene", "Gene Guarilia", false],
    [76901, "Gudmundsson", "Petur", "Petur Gudmundsson", false],
    [1629741, "Guduric", "Marko", "Marko Guduric", false],
    [76902, "Guerin", "Richie", "Richie Guerin", false],
    [339, "Gugliotta", "Tom", "Tom Gugliotta", false],
    [76903, "Guibert", "Andres", "Andres Guibert", false],
    [76904, "Guidinger", "Jay", "Jay Guidinger", false],
    [76905, "Gunther", "Coulby", "Coulby Gunther", false],
    [76906, "Gunther", "Dave", "Dave Gunther", false],
    [76907, "Guokas", "Al", "Al Guokas", false],
    [76908, "Guokas", "Matt", "Matt Guokas", false],
    [76909, "Guokas", "Matt", "Matt Guokas", false],
    [203268, "Gutierrez", "Jorge", "Jorge Gutierrez", false],
    [1629657, "Guy", "Kyle", "Kyle Guy", false],
    [2062, "Guyton", "A.J.", "A.J. Guyton", false],
    [2775, "Ha Seung-jin", "", "Ha Seung-jin", false],
    [1629060, "Hachimura", "Rui", "Rui Hachimura", true],
    [76910, "Hackett", "Rudy", "Rudy Hackett", false],
    [201632, "Haddadi", "Hamed", "Hamed Haddadi", false],
    [76911, "Haffner", "Scott", "Scott Haffner", false],
    [76912, "Hagan", "Cliff", "Cliff Hagan", false],
    [76913, "Hagan", "Glenn", "Glenn Hagan", false],
    [1630204, "Hagans", "Ashton", "Ashton Hagans", false],
    [76914, "Hahn", "Bob", "Bob Hahn", false],
    [76915, "Hairston", "Al", "Al Hairston", false],
    [76916, "Hairston", "Happy", "Happy Hairston", false],
    [76917, "Hairston", "Lindsay", "Lindsay Hairston", false],
    [201612, "Hairston", "Malik", "Malik Hairston", false],
    [203798, "Hairston", "PJ", "PJ Hairston", false],
    [2409, "Haislip", "Marcus", "Marcus Haislip", false],
    [76918, "Halbert", "Chuck", "Chuck Halbert", false],
    [76919, "Halbrook", "Harvey", "Harvey Halbrook", false],
    [76920, "Hale", "Bruce", "Bruce Hale", false],
    [917, "Haley", "Jack", "Jack Haley", false],
    [1630169, "Haliburton", "Tyrese", "Tyrese Haliburton", true],
    [76922, "Halimon", "Shaler", "Shaler Halimon", false],
    [1628985, "Hall", "Devon", "Devon Hall", false],
    [1629743, "Hall", "Donta", "Donta Hall", false],
    [1631160, "Hall", "Jordan", "Jordan Hall", true],
    [1630221, "Hall", "Josh", "Josh Hall", false],
    [200837, "Hall", "Mike", "Mike Hall", false],
    [1629788, "Hall", "Tyler", "Tyler Hall", false],
    [76923, "Halliburton", "Jeff", "Jeff Halliburton", false],
    [1032, "Ham", "Darvin", "Darvin Ham", false],
    [984, "Hamer", "Steve", "Steve Hamer", false],
    [76925, "Hamilton", "Dale", "Dale Hamilton", false],
    [1627772, "Hamilton", "Daniel", "Daniel Hamilton", false],
    [76926, "Hamilton", "Dennis", "Dennis Hamilton", false],
    [202706, "Hamilton", "Jordan", "Jordan Hamilton", false],
    [203120, "Hamilton", "Justin", "Justin Hamilton", false],
    [76927, "Hamilton", "Ralph", "Ralph Hamilton", false],
    [1888, "Hamilton", "Richard", "Richard Hamilton", false],
    [76928, "Hamilton", "Roylee", "Roylee Hamilton", false],
    [76929, "Hamilton", "Steve", "Steve Hamilton", false],
    [2347, "Hamilton", "Tang", "Tang Hamilton", false],
    [676, "Hamilton", "Thomas", "Thomas Hamilton", false],
    [1985, "Hamilton", "Zendon", "Zendon Hamilton", false],
    [903, "Hammink", "Geert", "Geert Hammink", false],
    [67, "Hammonds", "Tom", "Tom Hammonds", false],
    [1627773, "Hammons", "AJ", "AJ Hammons", false],
    [1630181, "Hampton", "R.J.", "R.J. Hampton", true],
    [241, "Hancock", "Darrin", "Darrin Hancock", false],
    [1052, "Handlogten", "Ben", "Ben Handlogten", false],
    [76935, "Hankins", "Cecil", "Cecil Hankins", false],
    [76936, "Hankinson", "Phil", "Phil Hankinson", false],
    [1628605, "Hannahs", "Dusty", "Dusty Hannahs", false],
    [76937, "Hannum", "Alex", "Alex Hannum", false],
    [76938, "Hanrahan", "Don", "Don Hanrahan", false],
    [76939, "Hans", "Rollen", "Rollen Hans", false],
    [203162, "Hansbrough", "Ben", "Ben Hansbrough", false],
    [201946, "Hansbrough", "Tyler", "Tyler Hansbrough", false],
    [76942, "Hansen", "Bob", "Bob Hansen", false],
    [76940, "Hansen", "Glenn", "Glenn Hansen", false],
    [76941, "Hansen", "Lars", "Lars Hansen", false],
    [2580, "Hansen", "Travis", "Travis Hansen", false],
    [1682, "Hanson", "Reggie", "Reggie Hanson", false],
    [76944, "Hanzlik", "Bill", "Bill Hanzlik", false],
    [202376, "Harangody", "Luke", "Luke Harangody", false],
    [358, "Hardaway", "Anfernee", "Anfernee Hardaway", false],
    [896, "Hardaway", "Tim", "Tim Hardaway", false],
    [203501, "Hardaway Jr.", "Tim", "Tim Hardaway Jr.", true],
    [201935, "Harden", "James", "James Harden", true],
    [76945, "Harding", "Reggie", "Reggie Harding", false],
    [76946, "Hardnett", "Charlie", "Charlie Hardnett", false],
    [76947, "Hardy", "Alan", "Alan Hardy", false],
    [1630702, "Hardy", "Jaden", "Jaden Hardy", true],
    [76948, "Hardy", "James", "James Hardy", false],
    [76949, "Hargis", "John", "John Hargis", false],
    [203090, "Harkless", "Maurice", "Maurice Harkless", false],
    [76950, "Harkness", "Jerry", "Jerry Harkness", false],
    [76951, "Harlicka", "Skip", "Skip Harlicka", false],
    [76952, "Harmon", "Jerome", "Jerome Harmon", false],
    [157, "Harper", "Derek", "Derek Harper", false],
    [1629607, "Harper", "Jared", "Jared Harper", false],
    [202712, "Harper", "Justin", "Justin Harper", false],
    [76954, "Harper", "Mike", "Mike Harper", false],
    [166, "Harper", "Ron", "Ron Harper", false],
    [1631199, "Harper Jr.", "Ron", "Ron Harper Jr.", true],
    [1723, "Harpring", "Matt", "Matt Harpring", false],
    [1626149, "Harrell", "Montrezl", "Montrezl Harrell", true],
    [202725, "Harrellson", "Josh", "Josh Harrellson", false],
    [2492, "Harrington", "Adam", "Adam Harrington", false],
    [1733, "Harrington", "Al", "Al Harrington", false],
    [2454, "Harrington", "Junior", "Junior Harrington", false],
    [970, "Harrington", "Othella", "Othella Harrington", false],
    [76956, "Harris", "Art", "Art Harris", false],
    [76957, "Harris", "Bernie", "Bernie Harris", false],
    [76959, "Harris", "Bob", "Bob Harris", false],
    [76958, "Harris", "Chris", "Chris Harris", false],
    [2734, "Harris", "Devin", "Devin Harris", false],
    [203548, "Harris", "Elias", "Elias Harris", false],
    [203914, "Harris", "Gary", "Gary Harris", true],
    [1630223, "Harris", "Jalen", "Jalen Harris", false],
    [203925, "Harris", "Joe", "Joe Harris", true],
    [1630284, "Harris", "Kevon", "Kevon Harris", true],
    [446, "Harris", "Lucious", "Lucious Harris", false],
    [202412, "Harris", "Manny", "Manny Harris", false],
    [200839, "Harris", "Mike", "Mike Harris", false],
    [76960, "Harris", "Steve", "Steve Harris", false],
    [202227, "Harris", "Terrel", "Terrel Harris", false],
    [202699, "Harris", "Tobias", "Tobias Harris", true],
    [76961, "Harris", "Tony", "Tony Harris", false],
    [1626151, "Harrison", "Aaron", "Aaron Harrison", false],
    [1626150, "Harrison", "Andrew", "Andrew Harrison", false],
    [76962, "Harrison", "Bob", "Bob Harrison", false],
    [2758, "Harrison", "David", "David Harrison", false],
    [1627885, "Harrison", "Shaquille", "Shaquille Harrison", false],
    [2078, "Hart", "Jason", "Jason Hart", false],
    [1628404, "Hart", "Josh", "Josh Hart", true],
    [1628392, "Hartenstein", "Isaiah", "Isaiah Hartenstein", true],
    [176, "Harvey", "Antonio", "Antonio Harvey", false],
    [2051, "Harvey", "Donnell", "Donnell Harvey", false],
    [202238, "Hasbrouck", "Kenny", "Kenny Hasbrouck", false],
    [321, "Haskin", "Scott", "Scott Haskin", false],
    [76965, "Haskins", "Clem", "Clem Haskins", false],
    [2617, "Haslem", "Udonis", "Udonis Haslem", true],
    [2239, "Hassell", "Trenton", "Trenton Hassell", false],
    [76967, "Hassett", "Billy", "Billy Hassett", false],
    [76966, "Hassett", "Joey", "Joey Hassett", false],
    [76968, "Hastings", "Scott", "Scott Hastings", false],
    [2213, "Haston", "Kirk", "Kirk Haston", false],
    [76969, "Hatton", "Vern", "Vern Hatton", false],
    [1630573, "Hauser", "Sam", "Sam Hauser", true],
    [76970, "Havlicek", "John", "John Havlicek", false],
    [201150, "Hawes", "Spencer", "Spencer Hawes", false],
    [76971, "Hawes", "Steve", "Steve Hawes", false],
    [76972, "Hawkins", "Connie", "Connie Hawkins", false],
    [765, "Hawkins", "Hersey", "Hersey Hawkins", false],
    [1038, "Hawkins", "Juaquin", "Juaquin Hawkins", false],
    [76974, "Hawkins", "Marshall", "Marshall Hawkins", false],
    [1138, "Hawkins", "Michael", "Michael Hawkins", false],
    [76975, "Hawkins", "Robert", "Robert Hawkins", false],
    [76977, "Hawkins", "Tom", "Tom Hawkins", false],
    [76978, "Hawthorne", "Nate", "Nate Hawthorne", false],
    [101236, "Hayes", "Chuck", "Chuck Hayes", false],
    [76979, "Hayes", "Elvin", "Elvin Hayes", false],
    [2553, "Hayes", "Jarvis", "Jarvis Hayes", false],
    [1629637, "Hayes", "Jaxson", "Jaxson Hayes", true],
    [1630165, "Hayes", "Killian", "Killian Hayes", true],
    [1628502, "Hayes", "Nigel", "Nigel Hayes", false],
    [76980, "Hayes", "Steve", "Steve Hayes", false],
    [202330, "Hayward", "Gordon", "Gordon Hayward", true],
    [202351, "Hayward", "Lazar", "Lazar Hayward", false],
    [2217, "Haywood", "Brendan", "Brendan Haywood", false],
    [76981, "Haywood", "Spencer", "Spencer Haywood", false],
    [76982, "Hazen", "John W.", "John W. Hazen", false],
    [76983, "Hazzard", "Walt", "Walt Hazzard", false],
    [101129, "Head", "Luther", "Luther Head", false],
    [1049, "Heal", "Shane", "Shane Heal", false],
    [76984, "Heaney", "Brian", "Brian Heaney", false],
    [76985, "Heard", "Garfield", "Garfield Heard", false],
    [203687, "Hearn", "Reggie", "Reggie Hearn", false],
    [76986, "Hedderick", "Herm", "Herm Hedderick", false],
    [76987, "Heggs", "Alvin", "Alvin Heggs", false],
    [76988, "Heinsohn", "Tom", "Tom Heinsohn", false],
    [76989, "Hemric", "Dick", "Dick Hemric", false],
    [673, "Henderson", "Alan", "Alan Henderson", false],
    [1538, "Henderson", "Cedric", "Cedric Henderson", false],
    [76990, "Henderson", "Cedric", "Cedric Henderson", false],
    [76991, "Henderson", "Dave", "Dave Henderson", false],
    [76993, "Henderson", "Gerald", "Gerald Henderson", false],
    [201945, "Henderson", "Gerald", "Gerald Henderson", false],
    [1764, "Henderson", "J.R.", "J.R. Henderson", false],
    [76992, "Henderson", "Jerome", "Jerome Henderson", false],
    [76994, "Henderson", "Kevin", "Kevin Henderson", false],
    [76996, "Henderson", "Tom", "Tom Henderson", false],
    [971, "Hendrickson", "Mark", "Mark Hendrickson", false],
    [76998, "Hennessy", "Larry", "Larry Hennessy", false],
    [76999, "Henriksen", "Don", "Don Henriksen", false],
    [1630565, "Henry", "Aaron", "Aaron Henry", false],
    [77000, "Henry", "Al", "Al Henry", false],
    [77004, "Henry", "Bill", "Bill Henry", false],
    [77001, "Henry", "Carl", "Carl Henry", false],
    [77002, "Henry", "Conner", "Conner Henry", false],
    [1627988, "Henry", "Myke", "Myke Henry", false],
    [77003, "Henry", "Skeeter", "Skeeter Henry", false],
    [202333, "Henry", "Xavier", "Xavier Henry", false],
    [203089, "Henson", "John", "John Henson", false],
    [1667, "Henson", "Steve", "Steve Henson", false],
    [77006, "Herman", "Bill", "Bill Herman", false],
    [77007, "Hermsen", "Clarence", "Clarence Hermsen", false],
    [1629608, "Hernandez", "Dewan", "Dewan Hernandez", false],
    [1627823, "Hernangomez", "Juancho", "Juancho Hernangomez", true],
    [1626195, "Hernangomez", "Willy", "Willy Hernangomez", true],
    [1914, "Herren", "Chris", "Chris Herren", false],
    [61, "Herrera", "Carl", "Carl Herrera", false],
    [200835, "Herrmann", "Walter", "Walter Herrmann", false],
    [1629639, "Herro", "Tyler", "Tyler Herro", true],
    [77010, "Herron", "Keith", "Keith Herron", false],
    [77011, "Hertzberg", "Sidney", "Sidney Hertzberg", false],
    [1628987, "Hervey", "Kevin", "Kevin Hervey", false],
    [77012, "Hetzel", "Fred", "Fred Hetzel", false],
    [77013, "Hewitt", "Bill", "Bill Hewitt", false],
    [77014, "Hewson", "Jack", "Jack Hewson", false],
    [77015, "Heyman", "Art", "Art Heyman", false],
    [1626209, "Hezonja", "Mario", "Mario Hezonja", false],
    [201579, "Hibbert", "Roy", "Roy Hibbert", false],
    [77016, "Hickey", "Matthew", "Matthew Hickey", false],
    [1628439, "Hicks", "Isaiah", "Isaiah Hicks", false],
    [77017, "Hicks", "Phil", "Phil Hicks", false],
    [201581, "Hickson", "JJ", "JJ Hickson", false],
    [1627741, "Hield", "Buddy", "Buddy Hield", true],
    [202809, "Higgins", "Cory", "Cory Higgins", false],
    [1848, "Higgins", "Mike", "Mike Higgins", false],
    [77019, "Higgins", "Rod", "Rod Higgins", false],
    [925, "Higgins", "Sean", "Sean Higgins", false],
    [77021, "Higgs", "Kenny", "Kenny Higgs", false],
    [77022, "High", "Johnny", "Johnny High", false],
    [1629312, "Highsmith", "Haywood", "Haywood Highsmith", true],
    [77023, "Hightower", "Wayne", "Wayne Hightower", false],
    [77024, "Hill", "Armond", "Armond Hill", false],
    [77025, "Hill", "Cleo", "Cleo Hill", false],
    [77026, "Hill", "Gary", "Gary Hill", false],
    [201588, "Hill", "George", "George Hill", true],
    [255, "Hill", "Grant", "Grant Hill", false],
    [201195, "Hill", "Herbert", "Herbert Hill", false],
    [201941, "Hill", "Jordan", "Jordan Hill", false],
    [1630792, "Hill", "Malcolm", "Malcolm Hill", true],
    [203524, "Hill", "Solomon", "Solomon Hill", false],
    [201631, "Hill", "Steven", "Steven Hill", false],
    [238, "Hill", "Tyrone", "Tyrone Hill", false],
    [77027, "Hillhouse", "Art", "Art Hillhouse", false],
    [1626199, "Hilliard", "Darrun", "Darrun Hilliard", false],
    [77028, "Hillman", "Darnell", "Darnell Hillman", false],
    [77029, "Hilton", "Fred", "Fred Hilton", false],
    [2550, "Hinrich", "Kirk", "Kirk Hinrich", false],
    [77030, "Hinson", "Roy", "Roy Hinson", false],
    [1630207, "Hinton", "Nate", "Nate Hinton", false],
    [77031, "Hirsch", "Mel", "Mel Hirsch", false],
    [77032, "Hitch", "Lew", "Lew Hitch", false],
    [200823, "Hite", "Robert", "Robert Hite", false],
    [1629658, "Hoard", "Jaylen", "Jaylen Hoard", false],
    [202359, "Hobson", "Darington", "Darington Hobson", false],
    [1106, "Hodge", "Donald", "Donald Hodge", false],
    [101125, "Hodge", "Julius", "Julius Hodge", false],
    [77034, "Hodges", "Craig", "Craig Hodges", false],
    [77035, "Hoefer", "Adolph", "Adolph Hoefer", false],
    [77036, "Hoffman", "Paul 'The Bear'", "Paul 'The Bear' Hoffman", false],
    [77037, "Hogsett", "Bob", "Bob Hogsett", false],
    [77038, "Hogue", "Paul", "Paul Hogue", false],
    [697, "Hoiberg", "Fred", "Fred Hoiberg", false],
    [77039, "Holcomb", "Doug", "Doug Holcomb", false],
    [2450, "Holcomb", "Randy", "Randy Holcomb", false],
    [1628988, "Holiday", "Aaron", "Aaron Holiday", true],
    [201950, "Holiday", "Jrue", "Jrue Holiday", true],
    [203200, "Holiday", "Justin", "Justin Holiday", true],
    [77040, "Holland", "Brad", "Brad Holland", false],
    [77041, "Holland", "Joe", "Joe Holland", false],
    [204066, "Holland", "John", "John Holland", false],
    [77042, "Holland", "Wilbur", "Wilbur Holland", false],
    [77043, "Hollins", "Lionel", "Lionel Hollins", false],
    [200797, "Hollins", "Ryan", "Ryan Hollins", false],
    [77044, "Hollis", "Essie", "Essie Hollis", false],
    [1626178, "Hollis-Jefferson", "Rondae", "Rondae Hollis-Jefferson", false],
    [1626158, "Holmes", "Richaun", "Richaun Holmes", true],
    [1631096, "Holmgren", "Chet", "Chet Holmgren", true],
    [77045, "Holstein", "Jim", "Jim Holstein", false],
    [77046, "Holt", "Alvin", "Alvin Holt", false],
    [77047, "Holton", "Michael", "Michael Holton", false],
    [77048, "Holub", "Dick", "Dick Holub", false],
    [77049, "Holup", "Joe", "Joe Holup", false],
    [77050, "Holzman", "Red", "Red Holzman", false],
    [1630258, "Homesley", "Caleb", "Caleb Homesley", false],
    [1532, "Honeycutt", "Jerald", "Jerald Honeycutt", false],
    [202715, "Honeycutt", "Tyler", "Tyler Honeycutt", false],
    [1967, "Hood", "Derek", "Derek Hood", false],
    [203918, "Hood", "Rodney", "Rodney Hood", false],
    [77051, "Hoover", "Tom", "Tom Hoover", false],
    [77052, "Hopkins", "Bob", "Bob Hopkins", false],
    [77053, "Hoppen", "Dave", "Dave Hoppen", false],
    [77054, "Hopson", "Dennis", "Dennis Hopson", false],
    [203816, "Hopson", "Scotty", "Scotty Hopson", false],
    [77055, "Horan", "Johnny", "Johnny Horan", false],
    [77056, "Hordges", "Cedrick", "Cedrick Hordges", false],
    [201143, "Horford", "Al", "Al Horford", true],
    [77057, "Horford", "Tito", "Tito Horford", false],
    [77058, "Horn", "Ron", "Ron Horn", false],
    [204, "Hornacek", "Jeff", "Jeff Hornacek", false],
    [202862, "Horner", "Dennis", "Dennis Horner", false],
    [109, "Horry", "Robert", "Robert Horry", false],
    [77060, "Horton", "Ed", "Ed Horton", false],
    [1629659, "Horton-Tucker", "Talen", "Talen Horton-Tucker", true],
    [77061, "Hosket", "Wilmer", "Wilmer Hosket", false],
    [77062, "Houbregs", "Bob", "Bob Houbregs", false],
    [2067, "House", "Eddie", "Eddie House", false],
    [1627863, "House Jr.", "Danuel", "Danuel House Jr.", true],
    [1631216, "Houstan", "Caleb", "Caleb Houstan", true],
    [275, "Houston", "Allan", "Allan Houston", false],
    [780, "Houston", "Byron", "Byron Houston", false],
    [77064, "Hovasse", "Tom", "Tom Hovasse", false],
    [77065, "Howard", "Brian", "Brian Howard", false],
    [2730, "Howard", "Dwight", "Dwight Howard", false],
    [77066, "Howard", "Greg", "Greg Howard", false],
    [2572, "Howard", "Josh", "Josh Howard", false],
    [436, "Howard", "Juwan", "Juwan Howard", false],
    [1630210, "Howard", "Markus", "Markus Howard", false],
    [77067, "Howard", "Mo", "Mo Howard", false],
    [77069, "Howard", "Otis", "Otis Howard", false],
    [1128, "Howard", "Stephen", "Stephen Howard", false],
    [1629739, "Howard", "William", "William Howard", false],
    [77070, "Howell", "Bailey", "Bailey Howell", false],
    [77072, "Hubbard", "Bob", "Bob Hubbard", false],
    [77071, "Hubbard", "Phil", "Phil Hubbard", false],
    [1631309, "Hudgins", "Trevor", "Trevor Hudgins", true],
    [201991, "Hudson", "Lester", "Lester Hudson", false],
    [77074, "Hudson", "Lou", "Lou Hudson", false],
    [1607, "Hudson", "Troy", "Troy Hudson", false],
    [1626273, "Huertas", "Marcelo", "Marcelo Huertas", false],
    [1628989, "Huerter", "Kevin", "Kevin Huerter", true],
    [203962, "Huestis", "Josh", "Josh Huestis", false],
    [1630643, "Huff", "Jay", "Jay Huff", true],
    [1572, "Huffman", "Nate", "Nate Huffman", false],
    [77076, "Hughes", "Alfredrick", "Alfredrick Hughes", false],
    [77077, "Hughes", "Eddie", "Eddie Hughes", false],
    [1630190, "Hughes", "Elijah", "Elijah Hughes", false],
    [77078, "Hughes", "Kim", "Kim Hughes", false],
    [1716, "Hughes", "Larry", "Larry Hughes", false],
    [1965, "Hughes", "Rick", "Rick Hughes", false],
    [203133, "Hummel", "Robbie", "Robbie Hummel", false],
    [77080, "Hummer", "John", "John Hummer", false],
    [2415, "Humphrey", "Ryan", "Ryan Humphrey", false],
    [1629353, "Humphries", "Isaac", "Isaac Humphries", false],
    [77081, "Humphries", "Jay", "Jay Humphries", false],
    [2743, "Humphries", "Kris", "Kris Humphries", false],
    [77082, "Hundley", "Rod", "Rod Hundley", false],
    [1630624, "Hunt", "Feron", "Feron Hunt", false],
    [2599, "Hunter", "Brandon", "Brandon Hunter", false],
    [77083, "Hunter", "Cedric", "Cedric Hunter", false],
    [201805, "Hunter", "Chris", "Chris Hunter", false],
    [1629631, "Hunter", "De'Andre", "De'Andre Hunter", true],
    [77084, "Hunter", "Les", "Les Hunter", false],
    [283, "Hunter", "Lindsey", "Lindsey Hunter", false],
    [201629, "Hunter", "Othello", "Othello Hunter", false],
    [1626154, "Hunter", "RJ", "RJ Hunter", false],
    [2212, "Hunter", "Steven", "Steven Hunter", false],
    [1626205, "Hunter", "Vincent", "Vincent Hunter", false],
    [310, "Hurley", "Bobby", "Bobby Hurley", false],
    [77086, "Hurley", "Roy", "Roy Hurley", false],
    [77087, "Huston", "Geoff", "Geoff Huston", false],
    [77088, "Huston", "Paul", "Paul Huston", false],
    [77089, "Hutchins", "Mel", "Mel Hutchins", false],
    [1628990, "Hutchison", "Chandler", "Chandler Hutchison", false],
    [77090, "Hutton", "Joe", "Joe Hutton", false],
    [77091, "Hyder", "Greg", "Greg Hyder", false],
    [1630538, "Hyland", "Bones", "Bones Hyland", true],
    [77092, "Iavaroni", "Marc", "Marc Iavaroni", false],
    [201586, "Ibaka", "Serge", "Serge Ibaka", true],
    [2738, "Iguodala", "Andre", "Andre Iguodala", true],
    [980, "Ilgauskas", "Zydrunas", "Zydrunas Ilgauskas", false],
    [101148, "Ilic", "Mile", "Mile Ilic", false],
    [101141, "Ilyasova", "Ersan", "Ersan Ilyasova", false],
    [77093, "Imhoff", "Darrall", "Darrall Imhoff", false],
    [77094, "Ingelsby", "Tom", "Tom Ingelsby", false],
    [204060, "Ingles", "Joe", "Joe Ingles", true],
    [203996, "Inglis", "Damien", "Damien Inglis", false],
    [201281, "Ingram", "Andre", "Andre Ingram", false],
    [1627742, "Ingram", "Brandon", "Brandon Ingram", true],
    [77095, "Ingram", "Joel", "Joel Ingram", false],
    [77096, "Irvin", "Byron", "Byron Irvin", false],
    [202681, "Irving", "Kyrie", "Kyrie Irving", true],
    [1628371, "Isaac", "Jonathan", "Jonathan Isaac", true],
    [77097, "Issel", "Dan", "Dan Issel", false],
    [77098, "Iuzzolino", "Mike", "Mike Iuzzolino", false],
    [947, "Iverson", "Allen", "Allen Iverson", false],
    [1631093, "Ivey", "Jaden", "Jaden Ivey", true],
    [2767, "Ivey", "Royal", "Royal Ivey", false],
    [1628411, "Iwundu", "Wes", "Wes Iwundu", false],
    [101127, "Jack", "Jarrett", "Jarrett Jack", false],
    [1628935, "Jackson", "Aaron", "Aaron Jackson", false],
    [77099, "Jackson", "Al", "Al Jackson", false],
    [1517, "Jackson", "Bobby", "Bobby Jackson", false],
    [202132, "Jackson", "Cedric", "Cedric Jackson", false],
    [201616, "Jackson", "Darnell", "Darnell Jackson", false],
    [1627743, "Jackson", "Demetrius", "Demetrius Jackson", false],
    [1628402, "Jackson", "Frank", "Frank Jackson", true],
    [77101, "Jackson", "Greg", "Greg Jackson", false],
    [1630543, "Jackson", "Isaiah", "Isaiah Jackson", true],
    [1114, "Jackson", "Jaren", "Jaren Jackson", false],
    [1983, "Jackson", "Jermaine", "Jermaine Jackson", false],
    [754, "Jackson", "Jim", "Jim Jackson", false],
    [1628367, "Jackson", "Josh", "Josh Jackson", false],
    [1628382, "Jackson", "Justin", "Justin Jackson", true],
    [77103, "Jackson", "Lucious", "Lucious Jackson", false],
    [2739, "Jackson", "Luke", "Luke Jackson", false],
    [1531, "Jackson", "Marc", "Marc Jackson", false],
    [349, "Jackson", "Mark", "Mark Jackson", false],
    [77104, "Jackson", "Michael", "Michael Jackson", false],
    [77105, "Jackson", "Myron", "Myron Jackson", false],
    [77106, "Jackson", "Phil", "Phil Jackson", false],
    [203510, "Jackson", "Pierre", "Pierre Jackson", false],
    [1631245, "Jackson", "Quenton", "Quenton Jackson", true],
    [77107, "Jackson", "Ralph", "Ralph Jackson", false],
    [1872, "Jackson", "Randell", "Randell Jackson", false],
    [202704, "Jackson", "Reggie", "Reggie Jackson", true],
    [77109, "Jackson", "Stanley", "Stanley Jackson", false],
    [1536, "Jackson", "Stephen", "Stephen Jackson", false],
    [77100, "Jackson", "Tony", "Tony Jackson", false],
    [77110, "Jackson", "Tracy", "Tracy Jackson", false],
    [77111, "Jackson", "Wardell", "Wardell Jackson", false],
    [1628991, "Jackson Jr.", "Jaren", "Jaren Jackson Jr.", true],
    [77112, "Jacobs", "Winfred", "Winfred Jacobs", false],
    [2418, "Jacobsen", "Casey", "Casey Jacobsen", false],
    [1734, "Jacobson", "Sam", "Sam Jacobson", false],
    [77114, "Jamerson", "Dave", "Dave Jamerson", false],
    [77115, "James", "Aaron", "Aaron James", false],
    [203108, "James", "Bernard", "Bernard James", false],
    [202345, "James", "Damion", "Damion James", false],
    [77116, "James", "Gene", "Gene James", false],
    [1080, "James", "Henry", "Henry James", false],
    [1744, "James", "Jerome", "Jerome James", false],
    [1629713, "James", "Justin", "Justin James", false],
    [2544, "James", "LeBron", "LeBron James", true],
    [2229, "James", "Mike", "Mike James", false],
    [1628455, "James", "Mike", "Mike James", false],
    [1906, "James", "Tim", "Tim James", false],
    [1712, "Jamison", "Antawn", "Antawn Jamison", false],
    [1942, "Jamison", "Harold", "Harold Jamison", false],
    [77120, "Janisch", "John", "John Janisch", false],
    [77122, "Janotta", "Howie", "Howie Janotta", false],
    [2060, "Jaric", "Marko", "Marko Jaric", false],
    [77123, "Jaros", "Tony", "Tony Jaros", false],
    [1630610, "Jarreau", "DeJon", "DeJon Jarreau", false],
    [101180, "Jasikevicius", "Sarunas", "Sarunas Jasikevicius", false],
    [201605, "Jawai", "Nathan", "Nathan Jawai", false],
    [77125, "Jeannette", "Buddy", "Buddy Jeannette", false],
    [77126, "Jeelani", "Abdul", "Abdul Jeelani", false],
    [2423, "Jefferies", "Chris", "Chris Jefferies", false],
    [201785, "Jeffers", "Othyus", "Othyus Jeffers", false],
    [2744, "Jefferson", "Al", "Al Jefferson", false],
    [1628518, "Jefferson", "Amile", "Amile Jefferson", false],
    [203928, "Jefferson", "Cory", "Cory Jefferson", false],
    [200971, "Jefferson", "Dontell", "Dontell Jefferson", false],
    [2210, "Jefferson", "Richard", "Richard Jefferson", false],
    [1629610, "Jeffries", "DaQuan", "DaQuan Jeffries", true],
    [2407, "Jeffries", "Jared", "Jared Jeffries", false],
    [202724, "Jenkins", "Charles", "Charles Jenkins", false],
    [2798, "Jenkins", "Horace", "Horace Jenkins", false],
    [203098, "Jenkins", "John", "John Jenkins", false],
    [201943, "Jennings", "Brandon", "Brandon Jennings", false],
    [24, "Jennings", "Keith", "Keith Jennings", false],
    [1137, "Jent", "Chris", "Chris Jent", false],
    [77129, "Jepsen", "Les", "Les Jepsen", false],
    [201973, "Jerebko", "Jonas", "Jonas Jerebko", false],
    [1629660, "Jerome", "Ty", "Ty Jerome", true],
    [201998, "Jerrells", "Curtis", "Curtis Jerrells", false],
    [203511, "Jerrett", "Grant", "Grant Jerrett", false],
    [200817, "Jeter", "Pooh", "Pooh Jeter", false],
    [1630198, "Joe", "Isaiah", "Isaiah Joe", true],
    [2639, "Johnsen", "Britton", "Britton Johnsen", false],
    [200792, "Johnson", "Alexander", "Alexander Johnson", false],
    [1628993, "Johnson", "Alize", "Alize Johnson", true],
    [101161, "Johnson", "Amir", "Amir Johnson", false],
    [77131, "Johnson", "Andy", "Andy Johnson", false],
    [1533, "Johnson", "Anthony", "Anthony Johnson", false],
    [202356, "Johnson", "Armon", "Armon Johnson", false],
    [77132, "Johnson", "Arnie", "Arnie Johnson", false],
    [422, "Johnson", "Avery", "Avery Johnson", false],
    [1629168, "Johnson", "BJ", "BJ Johnson", false],
    [1627744, "Johnson", "Brice", "Brice Johnson", false],
    [77130, "Johnson", "Buck", "Buck Johnson", false],
    [1629661, "Johnson", "Cameron", "Cameron Johnson", true],
    [201291, "Johnson", "Carldell", "Carldell Johnson", false],
    [77133, "Johnson", "Charlie", "Charlie Johnson", false],
    [202419, "Johnson", "Chris", "Chris Johnson", false],
    [203187, "Johnson", "Chris", "Chris Johnson", false],
    [77135, "Johnson", "Clay", "Clay Johnson", false],
    [77136, "Johnson", "Clemon", "Clemon Johnson", false],
    [1626177, "Johnson", "Dakari", "Dakari Johnson", false],
    [683, "Johnson", "Darryl", "Darryl Johnson", false],
    [77138, "Johnson", "Dave", "Dave Johnson", false],
    [77139, "Johnson", "David", "David Johnson", false],
    [1630525, "Johnson", "David", "David Johnson", false],
    [1746, "Johnson", "DeMarco", "DeMarco Johnson", false],
    [77141, "Johnson", "Dennis", "Dennis Johnson", false],
    [2035, "Johnson", "DerMarr", "DerMarr Johnson", false],
    [698, "Johnson", "Eddie", "Eddie Johnson", false],
    [77144, "Johnson", "Eddie", "Eddie Johnson", false],
    [77145, "Johnson", "Eric", "Eric Johnson", false],
    [911, "Johnson", "Ervin", "Ervin Johnson", false],
    [77146, "Johnson", "Frank", "Frank Johnson", false],
    [77147, "Johnson", "George", "George Johnson", false],
    [77148, "Johnson", "George", "George Johnson", false],
    [77149, "Johnson", "George", "George Johnson", false],
    [77150, "Johnson", "Gus", "Gus Johnson", false],
    [77151, "Johnson", "Harold", "Harold Johnson", false],
    [201274, "Johnson", "Ivan", "Ivan Johnson", false],
    [202707, "Johnson", "JaJuan", "JaJuan Johnson", false],
    [1630552, "Johnson", "Jalen", "Jalen Johnson", true],
    [201949, "Johnson", "James", "James Johnson", true],
    [2207, "Johnson", "Joe", "Joe Johnson", false],
    [77152, "Johnson", "John", "John Johnson", false],
    [77153, "Johnson", "Kannard", "Kannard Johnson", false],
    [1629640, "Johnson", "Keldon", "Keldon Johnson", true],
    [2256, "Johnson", "Ken", "Ken Johnson", false],
    [77154, "Johnson", "Ken", "Ken Johnson", false],
    [1630553, "Johnson", "Keon", "Keon Johnson", true],
    [134, "Johnson", "Kevin", "Kevin Johnson", false],
    [913, "Johnson", "Larry", "Larry Johnson", false],
    [77156, "Johnson", "Larry", "Larry Johnson", false],
    [77158, "Johnson", "Lee", "Lee Johnson", false],
    [2669, "Johnson", "Linton", "Linton Johnson", false],
    [77159, "Johnson", "Lynbert", "Lynbert Johnson", false],
    [77142, "Johnson", "Magic", "Magic Johnson", false],
    [77160, "Johnson", "Marques", "Marques Johnson", false],
    [77168, "Johnson", "Mickey", "Mickey Johnson", false],
    [77161, "Johnson", "Neil", "Neil Johnson", false],
    [203910, "Johnson", "Nick", "Nick Johnson", false],
    [77162, "Johnson", "Ollie", "Ollie Johnson", false],
    [204179, "Johnson", "Omari", "Omari Johnson", false],
    [203111, "Johnson", "Orlando", "Orlando Johnson", false],
    [77163, "Johnson", "Reggie", "Reggie Johnson", false],
    [77164, "Johnson", "Rich", "Rich Johnson", false],
    [77165, "Johnson", "Ron", "Ron Johnson", false],
    [1626169, "Johnson", "Stanley", "Stanley Johnson", true],
    [77166, "Johnson", "Steffond", "Steffond Johnson", false],
    [77134, "Johnson", "Steve", "Steve Johnson", false],
    [201234, "Johnson", "Trey", "Trey Johnson", false],
    [204020, "Johnson", "Tyler", "Tyler Johnson", false],
    [77167, "Johnson", "Vinnie", "Vinnie Johnson", false],
    [202325, "Johnson", "Wesley", "Wesley Johnson", false],
    [203130, "Johnson-Odom", "Darius", "Darius Johnson-Odom", false],
    [77170, "Johnston", "Nate", "Nate Johnston", false],
    [77169, "Johnston", "Neil", "Neil Johnston", false],
    [77171, "Johnstone", "Jim", "Jim Johnstone", false],
    [203999, "Jokic", "Nikola", "Nikola Jokic", true],
    [77172, "Jolliff", "Howie", "Howie Jolliff", false],
    [2264, "Jones", "Alvin", "Alvin Jones", false],
    [77173, "Jones", "Anthony", "Anthony Jones", false],
    [77174, "Jones", "Askia", "Askia Jones", false],
    [77180, "Jones", "Bill", "Bill Jones", false],
    [77193, "Jones", "Bobby", "Bobby Jones", false],
    [200784, "Jones", "Bobby", "Bobby Jones", false],
    [77175, "Jones", "Caldwell", "Caldwell Jones", false],
    [1630637, "Jones", "Carlik", "Carlik Jones", true],
    [279, "Jones", "Charles", "Charles Jones", false],
    [77178, "Jones", "Charles", "Charles Jones", false],
    [1869, "Jones", "Charles R.", "Charles R. Jones", false],
    [2563, "Jones", "Dahntay", "Dahntay Jones", false],
    [1627745, "Jones", "Damian", "Damian Jones", true],
    [1800, "Jones", "Damon", "Damon Jones", false],
    [203199, "Jones", "DeQuan", "DeQuan Jones", false],
    [202346, "Jones", "Dominique", "Dominique Jones", false],
    [963, "Jones", "Dontae'", "Dontae' Jones", false],
    [101204, "Jones", "Dwayne", "Dwayne Jones", false],
    [77182, "Jones", "Dwight", "Dwight Jones", false],
    [77183, "Jones", "Earl", "Earl Jones", false],
    [224, "Jones", "Eddie", "Eddie Jones", false],
    [77184, "Jones", "Edgar", "Edgar Jones", false],
    [2410, "Jones", "Fred", "Fred Jones", false],
    [1630529, "Jones", "Herbert", "Herbert Jones", true],
    [77185, "Jones", "Jake", "Jake Jones", false],
    [1627883, "Jones", "Jalen", "Jalen Jones", false],
    [2592, "Jones", "James", "James Jones", false],
    [1629203, "Jones", "Jemerrio", "Jemerrio Jones", false],
    [77186, "Jones", "Jimmy", "Jimmy Jones", false],
    [77187, "Jones", "Johnny", "Johnny Jones", false],
    [1908, "Jones", "Jumaine", "Jumaine Jones", false],
    [77188, "Jones", "K.C.", "K.C. Jones", false],
    [1630539, "Jones", "Kai", "Kai Jones", true],
    [203158, "Jones", "Kevin", "Kevin Jones", false],
    [77200, "Jones", "Larry", "Larry Jones", false],
    [77189, "Jones", "Major", "Major Jones", false],
    [2891, "Jones", "Mark", "Mark Jones", false],
    [90000, "Jones", "Mark", "Mark Jones", false],
    [1630222, "Jones", "Mason", "Mason Jones", false],
    [77195, "Jones", "Nick", "Nick Jones", false],
    [77191, "Jones", "Ozell", "Ozell Jones", false],
    [461, "Jones", "Popeye", "Popeye Jones", false],
    [77192, "Jones", "Rich", "Rich Jones", false],
    [77194, "Jones", "Robin", "Robin Jones", false],
    [77196, "Jones", "Sam", "Sam Jones", false],
    [2652, "Jones", "Shelton", "Shelton Jones", false],
    [200780, "Jones", "Solomon", "Solomon Jones", false],
    [77197, "Jones", "Steve", "Steve Jones", false],
    [203093, "Jones", "Terrence", "Terrence Jones", false],
    [1630200, "Jones", "Tre", "Tre Jones", true],
    [1626145, "Jones", "Tyus", "Tyus Jones", true],
    [77198, "Jones", "Wali", "Wali Jones", false],
    [77199, "Jones", "Wallace", "Wallace Jones", false],
    [77201, "Jones", "Wil", "Wil Jones", false],
    [77202, "Jones", "Willie", "Willie Jones", false],
    [77203, "Jones", "Willie", "Willie Jones", false],
    [203103, "Jones III", "Perry", "Perry Jones III", false],
    [1627884, "Jones Jr.", "Derrick", "Derrick Jones Jr.", true],
    [1817, "Jordan", "Adonis", "Adonis Jordan", false],
    [201599, "Jordan", "DeAndre", "DeAndre Jordan", true],
    [77205, "Jordan", "Eddie", "Eddie Jordan", false],
    [202366, "Jordan", "Jerome", "Jerome Jordan", false],
    [893, "Jordan", "Michael", "Michael Jordan", false],
    [674, "Jordan", "Reggie", "Reggie Jordan", false],
    [77208, "Jordan", "Thomas", "Thomas Jordan", false],
    [77209, "Jordan", "Walter", "Walter Jordan", false],
    [77210, "Jordon", "Phil", "Phil Jordon", false],
    [77211, "Jorgensen", "Johnny", "Johnny Jorgensen", false],
    [77212, "Jorgensen", "Noble", "Noble Jorgensen", false],
    [77213, "Jorgensen", "Roger", "Roger Jorgensen", false],
    [202709, "Joseph", "Cory", "Cory Joseph", true],
    [2123, "Joseph", "Garth", "Garth Joseph", false],
    [203126, "Joseph", "Kris", "Kris Joseph", false],
    [77214, "Joseph", "Yvon", "Yvon Joseph", false],
    [1631107, "Jovic", "Nikola", "Nikola Jovic", true],
    [77215, "Judkins", "Jeff", "Jeff Judkins", false],
    [1630548, "Juzang", "Johnny", "Johnny Juzang", true],
    [1629662, "Kabengele", "Mfiondu", "Mfiondu Kabengele", true],
    [77217, "Kachan", "Edwin", "Edwin Kachan", false],
    [77218, "Kaftan", "George", "George Kaftan", false],
    [77219, "Kalafat", "Ed", "Ed Kalafat", false],
    [1630686, "Kalaitzakis", "Georgios", "Georgios Kalaitzakis", false],
    [2549, "Kaman", "Chris", "Chris Kaman", false],
    [1626163, "Kaminsky", "Frank", "Frank Kaminsky", true],
    [77221, "Kaplowitz", "Ralph", "Ralph Kaplowitz", false],
    [2574, "Kapono", "Jason", "Jason Kapono", false],
    [77222, "Kappen", "Tony", "Tony Kappen", false],
    [203508, "Karasev", "Sergey", "Sergey Karasev", false],
    [201207, "Karl", "Coby", "Coby Karl", false],
    [77223, "Karl", "George", "George Karl", false],
    [77225, "Kasid", "Ed", "Ed Kasid", false],
    [2435, "Kasun", "Mario", "Mario Kasun", false],
    [77226, "Katkaveck", "Leo", "Leo Katkaveck", false],
    [77227, "Kauffman", "Bob", "Bob Kauffman", false],
    [201619, "Kaun", "Sasha", "Sasha Kaun", false],
    [77228, "Kautz", "Wilbert", "Wilbert Kautz", false],
    [77229, "Kea", "Clarence", "Clarence Kea", false],
    [77230, "Kearns", "Michael", "Michael Kearns", false],
    [77231, "Kearns", "Tommy", "Tommy Kearns", false],
    [228, "Keefe", "Adam", "Adam Keefe", false],
    [77233, "Keeling", "Harold", "Harold Keeling", false],
    [1631211, "Keels", "Trevor", "Trevor Keels", true],
    [77234, "Keller", "Ken", "Ken Keller", false],
    [77235, "Kelley", "Rich", "Rich Kelley", false],
    [77236, "Kellogg", "Clark", "Clark Kellogg", false],
    [77237, "Kelly", "Gerard", "Gerard Kelly", false],
    [203527, "Kelly", "Ryan", "Ryan Kelly", false],
    [77238, "Kelly", "Tom", "Tom Kelly", false],
    [77239, "Kelser", "Greg", "Greg Kelser", false],
    [77240, "Kelso", "Ben", "Ben Kelso", false],
    [431, "Kemp", "Shawn", "Shawn Kemp", false],
    [1118, "Kempton", "Tim", "Tim Kempton", false],
    [77242, "Kendrick", "Frank", "Frank Kendrick", false],
    [1628379, "Kennard", "Luke", "Luke Kennard", true],
    [202810, "Kennedy", "D.J.", "D.J. Kennedy", false],
    [77243, "Kennedy", "Eugene", "Eugene Kennedy", false],
    [77244, "Kennedy", "Joe", "Joe Kennedy", false],
    [77245, "Kennedy", "William", "William Kennedy", false],
    [77246, "Kenon", "Larry", "Larry Kenon", false],
    [77247, "Kenville", "Bill", "Bill Kenville", false],
    [1801, "Kerner", "Jonathan", "Jonathan Kerner", false],
    [77248, "Kerr", "Johnny", "Johnny Kerr", false],
    [70, "Kerr", "Steve", "Steve Kerr", false],
    [77249, "Kerris", "Jack", "Jack Kerris", false],
    [760, "Kersey", "Jerome", "Jerome Kersey", false],
    [77251, "Kessler", "Alec", "Alec Kessler", false],
    [1631117, "Kessler", "Walker", "Walker Kessler", true],
    [1930, "Ketner", "Lari", "Lari Ketner", false],
    [1630296, "Key", "Braxton", "Braxton Key", true],
    [705, "Keys", "Randolph", "Randolph Keys", false],
    [2751, "Khryapa", "Viktor", "Viktor Khryapa", false],
    [467, "Kidd", "Jason", "Jason Kidd", false],
    [1629742, "Kidd", "Stanton", "Stanton Kidd", false],
    [77254, "Kidd", "Warren", "Warren Kidd", false],
    [203077, "Kidd-Gilchrist", "Michael", "Michael Kidd-Gilchrist", false],
    [77255, "Kiffin", "Irv", "Irv Kiffin", false],
    [77256, "Kiley", "Jack", "Jack Kiley", false],
    [77257, "Killum", "Ernie", "Ernie Killum", false],
    [77258, "Kilpatrick", "Carl", "Carl Kilpatrick", false],
    [203930, "Kilpatrick", "Sean", "Sean Kilpatrick", false],
    [77259, "Kimball", "Toby", "Toby Kimball", false],
    [77260, "Kimble", "Bo", "Bo Kimble", false],
    [77261, "Kimbrough", "Stan", "Stan Kimbrough", false],
    [77262, "Kinch", "Chad", "Chad Kinch", false],
    [77263, "King", "Albert", "Albert King", false],
    [77264, "King", "Bernard", "Bernard King", false],
    [12, "King", "Chris", "Chris King", false],
    [77266, "King", "Dan", "Dan King", false],
    [701, "King", "Frankie", "Frankie King", false],
    [77268, "King", "George", "George King", false],
    [1628994, "King", "George", "George King", false],
    [1562, "King", "Gerard", "Gerard King", false],
    [77270, "King", "Jim", "Jim King", false],
    [728, "King", "Jimmy", "Jimmy King", false],
    [1629663, "King", "Louis", "Louis King", true],
    [77272, "King", "Maury", "Maury King", false],
    [77273, "King", "Reggie", "Reggie King", false],
    [1101, "King", "Rich", "Rich King", false],
    [351, "King", "Stacey", "Stacey King", false],
    [77276, "King", "Tom", "Tom King", false],
    [77277, "Kinney", "Bob", "Bob Kinney", false],
    [200814, "Kinsey", "Tarence", "Tarence Kinsey", false],
    [1905, "Kirilenko", "Andrei", "Andrei Kirilenko", false],
    [203945, "Kirk", "Alex", "Alex Kirk", false],
    [77278, "Kirk", "Walt", "Walt Kirk", false],
    [1630557, "Kispert", "Corey", "Corey Kispert", true],
    [77279, "Kistler", "Doug", "Doug Kistler", false],
    [77280, "Kitchen", "Curtis", "Curtis Kitchen", false],
    [1123, "Kite", "Greg", "Greg Kite", false],
    [954, "Kittles", "Kerry", "Kerry Kittles", false],
    [1628467, "Kleber", "Maxi", "Maxi Kleber", true],
    [170, "Kleine", "Joe", "Joe Kleine", false],
    [101132, "Kleiza", "Linas", "Linas Kleiza", false],
    [77283, "Klier", "Leo", "Leo Klier", false],
    [77284, "Klotz", "Herm", "Herm Klotz", false],
    [77285, "Klueh", "Duane", "Duane Klueh", false],
    [77290, "Knight", "Billy", "Billy Knight", false],
    [77287, "Knight", "Bob", "Bob Knight", false],
    [2688, "Knight", "Brandin", "Brandin Knight", false],
    [202688, "Knight", "Brandon", "Brandon Knight", false],
    [1510, "Knight", "Brevin", "Brevin Knight", false],
    [1630233, "Knight", "Nathan", "Nathan Knight", true],
    [1861, "Knight", "Negele", "Negele Knight", false],
    [77288, "Knight", "Ron", "Ron Knight", false],
    [77289, "Knight", "Toby", "Toby Knight", false],
    [969, "Knight", "Travis", "Travis Knight", false],
    [77291, "Knorek", "Lee", "Lee Knorek", false],
    [77292, "Knostman", "Dick", "Dick Knostman", false],
    [77293, "Knowles", "Rod", "Rod Knowles", false],
    [1628995, "Knox II", "Kevin", "Kevin Knox II", true],
    [77294, "Kofoed", "Bart", "Bart Kofoed", false],
    [77295, "Kojis", "Don", "Don Kojis", false],
    [1631132, "Koloko", "Christian", "Christian Koloko", true],
    [77296, "Komenich", "Milo", "Milo Komenich", false],
    [77297, "Komives", "Howard", "Howard Komives", false],
    [402, "Koncak", "Jon", "Jon Koncak", false],
    [1629723, "Konchar", "John", "John Konchar", true],
    [77299, "Koper", "Bud", "Bud Koper", false],
    [77300, "Kopicki", "Joe", "Joe Kopicki", false],
    [1627788, "Korkmaz", "Furkan", "Furkan Korkmaz", true],
    [77301, "Kornet", "Frank", "Frank Kornet", false],
    [1628436, "Kornet", "Luke", "Luke Kornet", true],
    [101117, "Korolev", "Yaroslav", "Yaroslav Korolev", false],
    [2594, "Korver", "Kyle", "Kyle Korver", false],
    [77302, "Kosmalski", "Len", "Len Kosmalski", false],
    [77303, "Kostecka", "Andy", "Andy Kostecka", false],
    [77304, "Kottman", "Harold", "Harold Kottman", false],
    [201585, "Koufos", "Kosta", "Kosta Koufos", false],
    [77305, "Kozelko", "Tom", "Tom Kozelko", false],
    [77306, "Kramer", "Arvid", "Arvid Kramer", false],
    [77307, "Kramer", "Barry", "Barry Kramer", false],
    [77308, "Kramer", "Joel", "Joel Kramer", false],
    [77309, "Kraus", "Dan", "Dan Kraus", false],
    [77310, "Krautblatt", "Herb", "Herb Krautblatt", false],
    [203139, "Kravtsov", "Viacheslav", "Viacheslav Kravtsov", false],
    [77311, "Krebs", "Jim", "Jim Krebs", false],
    [1630249, "Krejci", "Vit", "Vit Krejci", true],
    [77312, "Kreklow", "Wayne", "Wayne Kreklow", false],
    [77313, "Kron", "Tom", "Tom Kron", false],
    [77314, "Kropp", "Tom", "Tom Kropp", false],
    [2420, "Krstic", "Nenad", "Nenad Krstic", false],
    [1474, "Krystkowiak", "Larry", "Larry Krystkowiak", false],
    [77316, "Kuberski", "Steve", "Steve Kuberski", false],
    [77317, "Kubiak", "Leo", "Leo Kubiak", false],
    [77318, "Kuczenski", "Bruce", "Bruce Kuczenski", false],
    [77319, "Kudelka", "Frank", "Frank Kudelka", false],
    [77320, "Kuester", "John", "John Kuester", false],
    [77322, "Kuka", "Ray", "Ray Kuka", false],
    [389, "Kukoc", "Toni", "Toni Kukoc", false],
    [1629083, "Kulboka", "Arnoldas", "Arnoldas Kulboka", false],
    [1630228, "Kuminga", "Jonathan", "Jonathan Kuminga", true],
    [77323, "Kunnert", "Kevin", "Kevin Kunnert", false],
    [77324, "Kupchak", "Mitch", "Mitch Kupchak", false],
    [77325, "Kupec", "Charles", "Charles Kupec", false],
    [1629066, "Kurucs", "Rodions", "Rodions Kurucs", false],
    [201633, "Kurz", "Rob", "Rob Kurz", false],
    [2825, "Kutluay", "Ibrahim", "Ibrahim Kutluay", false],
    [1628398, "Kuzma", "Kyle", "Kyle Kuzma", true],
    [203136, "Kuzmic", "Ognjen", "Ognjen Kuzmic", false],
    [1627851, "Kuzminskas", "Mindaugas", "Mindaugas Kuzminskas", false],
    [77327, "LaCour", "Fred", "Fred LaCour", false],
    [1711, "LaFrentz", "Raef", "Raef LaFrentz", false],
    [77328, "LaGarde", "Tom", "Tom LaGarde", false],
    [1631222, "LaRavia", "Jake", "Jake LaRavia", true],
    [1601, "LaRue", "Rusty", "Rusty LaRue", false],
    [77340, "LaRusso", "Rudy", "Rudy LaRusso", false],
    [203897, "LaVine", "Zach", "Zach LaVine", true],
    [1627746, "Labissiere", "Skal", "Skal Labissiere", false],
    [77326, "Lacey", "Sam", "Sam Lacey", false],
    [363, "Laettner", "Christian", "Christian Laettner", false],
    [201802, "Lafayette", "Oliver", "Oliver Lafayette", false],
    [100263, "Laimbeer", "Bill", "Bill Laimbeer", false],
    [77329, "Lalich", "Pete", "Pete Lalich", false],
    [77330, "Lamar", "Bo", "Bo Lamar", false],
    [1630237, "Lamb", "Anthony", "Anthony Lamb", true],
    [203117, "Lamb", "Doron", "Doron Lamb", false],
    [203087, "Lamb", "Jeremy", "Jeremy Lamb", false],
    [77331, "Lambert", "John", "John Lambert", false],
    [77332, "Lamp", "Jeff", "Jeff Lamp", false],
    [2573, "Lampe", "Maciej", "Maciej Lampe", false],
    [77333, "Lampley", "Jim", "Jim Lampley", false],
    [2253, "Lampley", "Sean", "Sean Lampley", false],
    [1629111, "Landale", "Jock", "Jock Landale", true],
    [201171, "Landry", "Carl", "Carl Landry", false],
    [202068, "Landry", "Marcus", "Marcus Landry", false],
    [77334, "Landsberger", "Mark", "Mark Landsberger", false],
    [77335, "Lane", "Jerome", "Jerome Lane", false],
    [457, "Lang", "Andrew", "Andrew Lang", false],
    [226, "Lang", "Antonio", "Antonio Lang", false],
    [2591, "Lang", "James", "James Lang", false],
    [1892, "Langdon", "Trajan", "Trajan Langdon", false],
    [101247, "Langford", "Keith", "Keith Langford", false],
    [1629641, "Langford", "Romeo", "Romeo Langford", true],
    [2061, "Langhi", "Dan", "Dan Langhi", false],
    [600005, "Lanier", "Bob", "Bob Lanier", false],
    [77338, "Lantz", "Stu", "Stu Lantz", false],
    [1627879, "Laprovittola", "Nicolas", "Nicolas Laprovittola", false],
    [77339, "Larese", "York", "York Larese", false],
    [203499, "Larkin", "Shane", "Shane Larkin", false],
    [77341, "Laskowski", "John", "John Laskowski", false],
    [201186, "Lasme", "Stephane", "Stephane Lasme", false],
    [77342, "Lattin", "Dave", "Dave Lattin", false],
    [968, "Lauderdale", "Priest", "Priest Lauderdale", false],
    [77344, "Laurel", "Rich", "Rich Laurel", false],
    [77345, "Lautenbach", "Walt", "Walt Lautenbach", false],
    [203530, "Lauvergne", "Joffrey", "Joffrey Lauvergne", false],
    [77346, "Lavelli", "Tony", "Tony Lavelli", false],
    [77347, "Lavoy", "Bob", "Bob Lavoy", false],
    [201151, "Law", "Acie", "Acie Law", false],
    [1629724, "Law", "Vic", "Vic Law", false],
    [202371, "Lawal", "Gani", "Gani Lawal", false],
    [77348, "Lawrence", "Ed", "Ed Lawrence", false],
    [1630639, "Lawson", "A.J.", "A.J. Lawson", true],
    [1535, "Lawson", "Jason", "Jason Lawson", false],
    [201951, "Lawson", "Ty", "Ty Lawson", false],
    [1627774, "Layman", "Jake", "Jake Layman", false],
    [77350, "Layton", "Dennis", "Dennis Layton", false],
    [1627747, "LeVert", "Caris", "Caris LeVert", true],
    [1628388, "Leaf", "T.J.", "T.J. Leaf", false],
    [77351, "Leaks", "Emanuel", "Emanuel Leaks", false],
    [77352, "Lear", "Hal", "Hal Lear", false],
    [77353, "Leavell", "Allen", "Allen Leavell", false],
    [77354, "Lebo", "Jeff", "Jeff Lebo", false],
    [287, "Leckner", "Eric", "Eric Leckner", false],
    [1629665, "Lecque", "Jalen", "Jalen Lecque", false],
    [203495, "Ledo", "Ricky", "Ricky Ledo", false],
    [77356, "Lee", "Butch", "Butch Lee", false],
    [77357, "Lee", "Clyde", "Clyde Lee", false],
    [201584, "Lee", "Courtney", "Courtney Lee", false],
    [1627814, "Lee", "Damion", "Damion Lee", true],
    [101135, "Lee", "David", "David Lee", false],
    [77358, "Lee", "Doug", "Doug Lee", false],
    [77359, "Lee", "George", "George Lee", false],
    [77360, "Lee", "Greg", "Greg Lee", false],
    [77361, "Lee", "Keith", "Keith Lee", false],
    [77362, "Lee", "Kurk", "Kurk Lee", false],
    [202723, "Lee", "Malcolm", "Malcolm Lee", false],
    [77363, "Lee", "Rock", "Rock Lee", false],
    [77364, "Lee", "Ron", "Ron Lee", false],
    [77365, "Lee", "Russell", "Russell Lee", false],
    [1630240, "Lee", "Saben", "Saben Lee", true],
    [77366, "Leede", "Ed", "Ed Leede", false],
    [77367, "Lefkowitz", "Hank", "Hank Lefkowitz", false],
    [100, "Legler", "Tim", "Tim Legler", false],
    [77369, "Lehmann", "George", "George Lehmann", false],
    [1627215, "Lemon Jr.", "Walt", "Walt Lemon Jr.", false],
    [203458, "Len", "Alex", "Alex Len", true],
    [702, "Lenard", "Voshon", "Voshon Lenard", false],
    [77371, "Leonard", "Bob", "Bob Leonard", false],
    [77370, "Leonard", "Gary", "Gary Leonard", false],
    [202695, "Leonard", "Kawhi", "Kawhi Leonard", true],
    [203086, "Leonard", "Meyers", "Meyers Leonard", true],
    [77372, "Les", "Jim", "Jim Les", false],
    [202727, "Leslie", "Travis", "Travis Leslie", false],
    [77373, "Lester", "Ronnie", "Ronnie Lester", false],
    [77374, "Lett", "Clifford", "Clifford Lett", false],
    [202720, "Leuer", "Jon", "Jon Leuer", false],
    [77375, "Levane", "Andrew", "Andrew Levane", false],
    [77376, "Lever", "Lafayette", "Lafayette Lever", false],
    [77377, "Levingston", "Cliff", "Cliff Levingston", false],
    [77385, "Lewis", "Bobby", "Bobby Lewis", false],
    [736, "Lewis", "Cedric", "Cedric Lewis", false],
    [77379, "Lewis", "Fred", "Fred Lewis", false],
    [77380, "Lewis", "Freddie", "Freddie Lewis", false],
    [77381, "Lewis", "Grady", "Grady Lewis", false],
    [1631171, "Lewis", "Justin", "Justin Lewis", true],
    [729, "Lewis", "Martin", "Martin Lewis", false],
    [1900, "Lewis", "Quincy", "Quincy Lewis", false],
    [77383, "Lewis", "Ralph", "Ralph Lewis", false],
    [1740, "Lewis", "Rashard", "Rashard Lewis", false],
    [77384, "Lewis", "Reggie", "Reggie Lewis", false],
    [1630575, "Lewis", "Scottie", "Scottie Lewis", false],
    [1630184, "Lewis Jr.", "Kira", "Kira Lewis Jr.", true],
    [77386, "Liberty", "Marcus", "Marcus Liberty", false],
    [77387, "Lichti", "Todd", "Todd Lichti", false],
    [1630604, "Liddell", "E.J.", "E.J. Liddell", true],
    [202732, "Liggins", "DeAndre", "DeAndre Liggins", false],
    [77388, "Ligon", "Bill", "Bill Ligon", false],
    [203081, "Lillard", "Damian", "Damian Lillard", true],
    [202391, "Lin", "Jeremy", "Jeremy Lin", false],
    [77389, "Lingenfelter", "Steve", "Steve Lingenfelter", false],
    [175, "Lister", "Alton", "Alton Lister", false],
    [1629642, "Little", "Nassir", "Nassir Little", true],
    [1630587, "Livers", "Isaiah", "Isaiah Livers", true],
    [988, "Livingston", "Randy", "Randy Livingston", false],
    [2733, "Livingston", "Shaun", "Shaun Livingston", false],
    [77392, "Livingstone", "Ron", "Ron Livingstone", false],
    [1024, "Llamas", "Horacio", "Horacio Llamas", false],
    [77394, "Lloyd", "Earl", "Earl Lloyd", false],
    [77395, "Lloyd", "Lewis", "Lewis Lloyd", false],
    [77396, "Lloyd", "Scott", "Scott Lloyd", false],
    [77397, "Lochmueller", "Bob", "Bob Lochmueller", false],
    [77398, "Lock", "Robert", "Robert Lock", false],
    [77399, "Lockhart", "Darrell", "Darrell Lockhart", false],
    [1100, "Lockhart", "Ian", "Ian Lockhart", false],
    [77401, "Loder", "Kevin", "Kevin Loder", false],
    [77402, "Lofgran", "Don", "Don Lofgran", false],
    [1629155, "Lofton", "Zach", "Zach Lofton", false],
    [1631254, "Lofton Jr.", "Kenneth", "Kenneth Lofton Jr.", true],
    [77403, "Logan", "Johnny", "Johnny Logan", false],
    [38, "Lohaus", "Brad", "Brad Lohaus", false],
    [1609, "Long", "Art", "Art Long", false],
    [3, "Long", "Grant", "Grant Long", false],
    [1381, "Long", "John", "John Long", false],
    [77406, "Long", "Paul", "Paul Long", false],
    [1627848, "Long", "Shawn", "Shawn Long", false],
    [26, "Longley", "Luc", "Luc Longley", false],
    [1626172, "Looney", "Kevon", "Kevon Looney", true],
    [201572, "Lopez", "Brook", "Brook Lopez", true],
    [1732, "Lopez", "Felipe", "Felipe Lopez", false],
    [2221, "Lopez", "Raul", "Raul Lopez", false],
    [201577, "Lopez", "Robin", "Robin Lopez", true],
    [943, "Lorthridge", "Ryan", "Ryan Lorthridge", false],
    [77409, "Loscutoff", "Jim", "Jim Loscutoff", false],
    [77410, "Lott", "Plummer", "Plummer Lott", false],
    [77411, "Loughery", "Kevin", "Kevin Loughery", false],
    [1629712, "Louzada", "Didi", "Didi Louzada", false],
    [77412, "Love", "Bob", "Bob Love", false],
    [201567, "Love", "Kevin", "Kevin Love", true],
    [77413, "Love", "Stan", "Stan Love", false],
    [77414, "Lovellette", "Clyde", "Clyde Lovellette", false],
    [77415, "Lowe", "Sidney", "Sidney Lowe", false],
    [77416, "Lowery", "Chuck", "Chuck Lowery", false],
    [200768, "Lowry", "Kyle", "Kyle Lowry", true],
    [1628070, "Loyd", "Jordan", "Jordan Loyd", false],
    [77417, "Lucas", "Al", "Al Lucas", false],
    [77418, "Lucas", "Jerry", "Jerry Lucas", false],
    [77419, "Lucas", "John", "John Lucas", false],
    [203564, "Lucas", "Kalin", "Kalin Lucas", false],
    [77420, "Lucas", "Maurice", "Maurice Lucas", false],
    [101249, "Lucas III", "John", "John Lucas III", false],
    [77421, "Luckenbill", "Ted", "Ted Luckenbill", false],
    [1731, "Lue", "Tyronn", "Tyronn Lue", false],
    [77422, "Luisi", "Jim", "Jim Luisi", false],
    [77423, "Lujack", "Al", "Al Lujack", false],
    [77424, "Lumpkin", "Phil", "Phil Lumpkin", false],
    [77425, "Lumpp", "Ray", "Ray Lumpp", false],
    [1630994, "Lundberg", "Gabriel", "Gabriel Lundberg", false],
    [1627789, "Luwawu-Cabarrot", "Timothe", "Timothe Luwawu-Cabarrot", false],
    [1628399, "Lydon", "Tyler", "Tyler Lydon", false],
    [1626168, "Lyles", "Trey", "Trey Lyles", true],
    [248, "Lynch", "George", "George Lynch", false],
    [77426, "Lynch", "Kevin", "Kevin Lynch", false],
    [77427, "Lynn", "Mike", "Mike Lynn", false],
    [1627815, "Mac", "Sheldon", "Sheldon Mac", false],
    [1928, "MacCulloch", "Todd", "Todd MacCulloch", false],
    [77430, "MacGilvray", "Ronnie", "Ronnie MacGilvray", false],
    [931, "MacLean", "Don", "Don MacLean", false],
    [77428, "Macaluso", "Mike", "Mike Macaluso", false],
    [77429, "Macauley", "Ed", "Ed Macauley", false],
    [203159, "Machado", "Scott", "Scott Machado", false],
    [101178, "Macijauskas", "Arvydas", "Arvydas Macijauskas", false],
    [77432, "Mack", "Ollie", "Ollie Mack", false],
    [694, "Mack", "Sam", "Sam Mack", false],
    [202714, "Mack", "Shelvin", "Shelvin Mack", false],
    [1635, "Mackey", "Malcolm", "Malcolm Mackey", false],
    [77435, "Macklin", "Rudy", "Rudy Macklin", false],
    [202731, "Macklin", "Vernon", "Vernon Macklin", false],
    [77436, "Macknowski", "Johnny", "Johnny Macknowski", false],
    [1629133, "Macon", "Daryl", "Daryl Macon", false],
    [1855, "Macon", "Mark", "Mark Macon", false],
    [1629122, "Macura", "J.P.", "J.P. Macura", false],
    [77438, "Macy", "Kyle", "Kyle Macy", false],
    [77439, "Maddox", "Jack", "Jack Maddox", false],
    [2432, "Maddox", "Tito", "Tito Maddox", false],
    [1666, "Madkins", "Gerald", "Gerald Madkins", false],
    [2058, "Madsen", "Mark", "Mark Madsen", false],
    [77441, "Mager", "Norm", "Norm Mager", false],
    [203705, "Magette", "Josh", "Josh Magette", false],
    [1894, "Maggette", "Corey", "Corey Maggette", false],
    [77442, "Magley", "Dave", "Dave Magley", false],
    [2048, "Magloire", "Jamaal", "Jamaal Magloire", false],
    [1630266, "Magnay", "Will", "Will Magnay", false],
    [101133, "Mahinmi", "Ian", "Ian Mahinmi", false],
    [77443, "Mahnken", "John", "John Mahnken", false],
    [77444, "Mahoney", "Francis", "Francis Mahoney", false],
    [328, "Mahorn", "Rick", "Rick Mahorn", false],
    [105, "Majerle", "Dan", "Dan Majerle", false],
    [200970, "Major", "Renaldo", "Renaldo Major", false],
    [1627748, "Maker", "Thon", "Thon Maker", false],
    [77447, "Malamed", "Lionel", "Lionel Malamed", false],
    [1630177, "Maledon", "Theo", "Theo Maledon", true],
    [117, "Malone", "Jeff", "Jeff Malone", false],
    [252, "Malone", "Karl", "Karl Malone", false],
    [77449, "Malone", "Moses", "Moses Malone", false],
    [1074, "Maloney", "Matt", "Matt Maloney", false],
    [77450, "Malovic", "Steve", "Steve Malovic", false],
    [1630572, "Mamukelashvili", "Sandro", "Sandro Mamukelashvili", true],
    [77451, "Manakas", "Ted", "Ted Manakas", false],
    [77452, "Mandic", "John", "John Mandic", false],
    [1630211, "Mane", "Karim", "Karim Mane", false],
    [77453, "Mangiapane", "Frank", "Frank Mangiapane", false],
    [986, "Mann", "Marcus", "Marcus Mann", false],
    [1629611, "Mann", "Terance", "Terance Mann", true],
    [1630544, "Mann", "Tre", "Tre Mann", true],
    [330, "Manning", "Danny", "Danny Manning", false],
    [77454, "Manning", "Ed", "Ed Manning", false],
    [316, "Manning", "Richard", "Richard Manning", false],
    [1630185, "Mannion", "Nico", "Nico Mannion", false],
    [77456, "Mannion", "Pace", "Pace Mannion", false],
    [77457, "Mantis", "Nick", "Nick Mantis", false],
    [77459, "Maravich", "Pete", "Pete Maravich", false],
    [77458, "Maravich", "Peter", "Peter Maravich", false],
    [203906, "Marble", "Devyn", "Devyn Marble", false],
    [77460, "Marble", "Roy", "Roy Marble", false],
    [950, "Marbury", "Stephon", "Stephon Marbury", false],
    [36, "Marciulionis", "Sarunas", "Sarunas Marciulionis", false],
    [77462, "Mariaschin", "Saul", "Saul Mariaschin", false],
    [77463, "Marin", "Jack", "Jack Marin", false],
    [1890, "Marion", "Shawn", "Shawn Marion", false],
    [1626246, "Marjanovic", "Boban", "Boban Marjanovic", true],
    [1628374, "Markkanen", "Lauri", "Lauri Markkanen", true],
    [200806, "Markota", "Damir", "Damir Markota", false],
    [1752, "Marks", "Sean", "Sean Marks", false],
    [77464, "Marlatt", "Harvey", "Harvey Marlatt", false],
    [77465, "Marsh", "Eric", "Eric Marsh", false],
    [77466, "Marsh", "Jim", "Jim Marsh", false],
    [681, "Marshall", "Donny", "Donny Marshall", false],
    [923, "Marshall", "Donyell", "Donyell Marshall", false],
    [203088, "Marshall", "Kendall", "Kendall Marshall", false],
    [1630230, "Marshall", "Naji", "Naji Marshall", true],
    [101185, "Marshall", "Rawle", "Rawle Marshall", false],
    [77467, "Marshall", "Tom", "Tom Marshall", false],
    [77468, "Marshall", "Vester", "Vester Marshall", false],
    [77481, "Martin", "Bill", "Bill Martin", false],
    [77478, "Martin", "Bob", "Bob Martin", false],
    [77469, "Martin", "Brian", "Brian Martin", false],
    [1628997, "Martin", "Caleb", "Caleb Martin", true],
    [201858, "Martin", "Cartier", "Cartier Martin", false],
    [1628998, "Martin", "Cody", "Cody Martin", true],
    [733, "Martin", "Cuonzo", "Cuonzo Martin", false],
    [239, "Martin", "Darrick", "Darrick Martin", false],
    [77473, "Martin", "Don", "Don Martin", false],
    [77471, "Martin", "Donald", "Donald Martin", false],
    [77472, "Martin", "Fernando", "Fernando Martin", false],
    [1626185, "Martin", "Jarell", "Jarell Martin", false],
    [77474, "Martin", "Jeff", "Jeff Martin", false],
    [1629725, "Martin", "Jeremiah", "Jeremiah Martin", false],
    [1629103, "Martin", "Kelan", "Kelan Martin", false],
    [2030, "Martin", "Kenyon", "Kenyon Martin", false],
    [2755, "Martin", "Kevin", "Kevin Martin", false],
    [77475, "Martin", "Larue", "Larue Martin", false],
    [77476, "Martin", "Mo", "Mo Martin", false],
    [77477, "Martin", "Phil", "Phil Martin", false],
    [77479, "Martin", "Ronald", "Ronald Martin", false],
    [77480, "Martin", "Slater", "Slater Martin", false],
    [1631213, "Martin", "Tyrese", "Tyrese Martin", true],
    [1630231, "Martin Jr.", "Kenyon", "Kenyon Martin Jr.", true],
    [469, "Mashburn", "Jamal", "Jamal Mashburn", false],
    [77482, "Masino", "Al", "Al Masino", false],
    [193, "Mason", "Anthony", "Anthony Mason", false],
    [2046, "Mason", "Desmond", "Desmond Mason", false],
    [1628412, "Mason III", "Frank", "Frank Mason III", false],
    [2427, "Mason Jr.", "Roger", "Roger Mason Jr.", false],
    [763, "Massenburg", "Tony", "Tony Massenburg", false],
    [77483, "Mast", "Eddie", "Eddie Mast", false],
    [1628999, "Maten", "Yante", "Yante Maten", false],
    [1629726, "Mathews", "Garrison", "Garrison Mathews", true],
    [1628493, "Mathiang", "Mangok", "Mangok Mathiang", false],
    [1629751, "Mathias", "Dakota", "Dakota Mathias", false],
    [1631097, "Mathurin", "Bennedict", "Bennedict Mathurin", true],
    [77484, "Matthews", "Wes", "Wes Matthews", false],
    [202083, "Matthews", "Wesley", "Wesley Matthews", true],
    [77485, "Maughan", "Ariel", "Ariel Maughan", false],
    [77486, "Maxey", "Marlon", "Marlon Maxey", false],
    [1630178, "Maxey", "Tyrese", "Tyrese Maxey", true],
    [101131, "Maxiell", "Jason", "Jason Maxiell", false],
    [77487, "Maxwell", "Cedric", "Cedric Maxwell", false],
    [137, "Maxwell", "Vernon", "Vernon Maxwell", false],
    [77489, "May", "Don", "Don May", false],
    [77490, "May", "Scott", "Scott May", false],
    [101118, "May", "Sean", "Sean May", false],
    [195, "Mayberry", "Lee", "Lee Mayberry", false],
    [77492, "Mayes", "Clyde", "Clyde Mayes", false],
    [77493, "Mayes", "Tharon", "Tharon Mayes", false],
    [77495, "Mayfield", "Bill", "Bill Mayfield", false],
    [77494, "Mayfield", "Ken", "Ken Mayfield", false],
    [201953, "Maynor", "Eric", "Eric Maynor", false],
    [201564, "Mayo", "O.J.", "O.J. Mayo", false],
    [1630219, "Mays", "Skylar", "Skylar Mays", false],
    [77496, "Mays", "Travis", "Travis Mays", false],
    [77497, "Mazza", "Matt", "Matt Mazza", false],
    [201601, "Mbah a Moute", "Luc", "Luc Mbah a Moute", false],
    [2788, "Mbenga", "DJ", "DJ Mbenga", false],
    [77498, "McAdoo", "Bob", "Bob McAdoo", false],
    [203949, "McAdoo", "James Michael", "James Michael McAdoo", false],
    [77499, "McBride", "Ken", "Ken McBride", false],
    [1630540, "McBride", "Miles", "Miles McBride", true],
    [1628769, "McCall", "Tahjere", "Tahjere McCall", false],
    [203492, "McCallum", "Ray", "Ray McCallum", false],
    [737, "McCann", "Bob", "Bob McCann", false],
    [77500, "McCann", "Brendan", "Brendan McCann", false],
    [77502, "McCants", "Mel", "Mel McCants", false],
    [101119, "McCants", "Rashad", "Rashad McCants", false],
    [77503, "McCarron", "Mike", "Mike McCarron", false],
    [77504, "McCarter", "Andre", "Andre McCarter", false],
    [77505, "McCarter", "Willie", "Willie McCarter", false],
    [77506, "McCarthy", "Johnny", "Johnny McCarthy", false],
    [77507, "McCarty", "Howie", "Howie McCarty", false],
    [1820, "McCarty", "Kelly", "Kelly McCarty", false],
    [962, "McCarty", "Walter", "Walter McCarty", false],
    [1043, "McCaskill", "Amal", "Amal McCaskill", false],
    [1627775, "McCaw", "Patrick", "Patrick McCaw", false],
    [77509, "McClain", "Dwayne", "Dwayne McClain", false],
    [77510, "McClain", "Ted 'Hound Dog'", "Ted 'Hound Dog' McClain", false],
    [2082, "McClintock", "Dan", "Dan McClintock", false],
    [77512, "McCloskey", "Jack", "Jack McCloskey", false],
    [45, "McCloud", "George", "George McCloud", false],
    [1630644, "McClung", "Mac", "Mac McClung", true],
    [203468, "McCollum", "CJ", "CJ McCollum", true],
    [77513, "McConathy", "John", "John McConathy", false],
    [77514, "McConnell", "Paul", "Paul McConnell", false],
    [204456, "McConnell", "T.J.", "T.J. McConnell", true],
    [77515, "McCord", "Keith", "Keith McCord", false],
    [77516, "McCormick", "Tim", "Tim McCormick", false],
    [1741, "McCoy", "Jelani", "Jelani McCoy", false],
    [77517, "McCracken", "Paul", "Paul McCracken", false],
    [200840, "McCray", "Chris", "Chris McCray", false],
    [1622, "McCray", "Rodney", "Rodney McCray", false],
    [77518, "McCray", "Scooter", "Scooter McCray", false],
    [1628571, "McCree", "Erik", "Erik McCree", false],
    [1626191, "McCullough", "Chris", "Chris McCullough", false],
    [77520, "McCullough", "John", "John McCullough", false],
    [723, "McDaniel", "Clint", "Clint McDaniel", false],
    [1365, "McDaniel", "Xavier", "Xavier McDaniel", false],
    [1630183, "McDaniels", "Jaden", "Jaden McDaniels", true],
    [1629667, "McDaniels", "Jalen", "Jalen McDaniels", true],
    [77523, "McDaniels", "Jim", "Jim McDaniels", false],
    [203909, "McDaniels", "KJ", "KJ McDaniels", false],
    [203926, "McDermott", "Doug", "Doug McDermott", true],
    [1630253, "McDermott", "Sean", "Sean McDermott", false],
    [77524, "McDonald", "Ben", "Ben McDonald", false],
    [77525, "McDonald", "Glenn", "Glenn McDonald", false],
    [1075, "McDonald", "Michael", "Michael McDonald", false],
    [77527, "McDowell", "Hank", "Hank McDowell", false],
    [686, "McDyess", "Antonio", "Antonio McDyess", false],
    [77528, "McElroy", "Jim", "Jim McElroy", false],
    [200829, "McFarlin", "Ivan", "Ivan McFarlin", false],
    [77529, "McGaha", "Mel", "Mel McGaha", false],
    [203956, "McGary", "Mitch", "Mitch McGary", false],
    [201580, "McGee", "JaVale", "JaVale McGee", true],
    [77530, "McGee", "Mike", "Mike McGee", false],
    [77531, "McGill", "Bill", "Bill McGill", false],
    [77532, "McGinnis", "George", "George McGinnis", false],
    [77533, "McGlocklin", "Jon", "Jon McGlocklin", false],
    [1631121, "McGowens", "Bryce", "Bryce McGowens", true],
    [1503, "McGrady", "Tracy", "Tracy McGrady", false],
    [77534, "McGregor", "Gil", "Gil McGregor", false],
    [1630787, "McGriff", "Cameron", "Cameron McGriff", false],
    [203585, "McGruder", "Rodney", "Rodney McGruder", true],
    [77536, "McGuire", "Al", "Al McGuire", false],
    [77535, "McGuire", "Allie", "Allie McGuire", false],
    [77537, "McGuire", "Dick", "Dick McGuire", false],
    [201187, "McGuire", "Dominic", "Dominic McGuire", false],
    [1450, "McHale", "Kevin", "Kevin McHale", false],
    [29, "McIlvaine", "Jim", "Jim McIlvaine", false],
    [976, "McInnis", "Jeff", "Jeff McInnis", false],
    [77540, "McIntosh", "Kenny", "Kenny McIntosh", false],
    [77541, "McKenna", "Kevin", "Kevin McKenna", false],
    [77542, "McKenzie", "Forrest", "Forrest McKenzie", false],
    [77543, "McKenzie", "Stan", "Stan McKenzie", false],
    [365, "McKey", "Derrick", "Derrick McKey", false],
    [243, "McKie", "Aaron", "Aaron McKie", false],
    [77547, "McKinney", "Billy", "Billy McKinney", false],
    [77545, "McKinney", "Carlton", "Carlton McKinney", false],
    [77546, "McKinney", "Horace", "Horace McKinney", false],
    [203590, "McKinney-Jones", "Trey", "Trey McKinney-Jones", false],
    [1628035, "McKinnie", "Alfonzo", "Alfonzo McKinnie", false],
    [1630605, "McLaughlin", "JaQuori", "JaQuori McLaughlin", false],
    [1629162, "McLaughlin", "Jordan", "Jordan McLaughlin", true],
    [203463, "McLemore", "Ben", "Ben McLemore", false],
    [77548, "McLemore", "Mccoy", "Mccoy McLemore", false],
    [77549, "McLeod", "George", "George McLeod", false],
    [2693, "McLeod", "Keith", "Keith McLeod", false],
    [1728, "McLeod", "Roshown", "Roshown McLeod", false],
    [77551, "McMahon", "Jack", "Jack McMahon", false],
    [203, "McMillan", "Nate", "Nate McMillan", false],
    [77553, "McMillen", "Tom", "Tom McMillen", false],
    [77554, "McMillian", "Jim", "Jim McMillian", false],
    [77555, "McMillon", "Shellie", "Shellie McMillon", false],
    [77556, "McMullan", "Mal", "Mal McMullan", false],
    [77557, "McNabb", "Chet", "Chet McNabb", false],
    [77558, "McNamara", "Mark", "Mark McNamara", false],
    [77559, "McNamee", "Joe", "Joe McNamee", false],
    [202077, "McNeal", "Jerel", "Jerel McNeal", false],
    [77560, "McNealy", "Chris", "Chris McNealy", false],
    [77562, "McNeill", "Bob", "Bob McNeill", false],
    [77561, "McNeill", "Larry", "Larry McNeill", false],
    [77563, "McNulty", "Carl", "Carl McNulty", false],
    [2101, "McPherson", "Paul", "Paul McPherson", false],
    [77565, "McQueen", "Cozell", "Cozell McQueen", false],
    [203895, "McRae", "Jordan", "Jordan McRae", false],
    [77566, "McReynolds", "Thales", "Thales McReynolds", false],
    [201177, "McRoberts", "Josh", "Josh McRoberts", false],
    [77567, "McWilliams", "Eric", "Eric McWilliams", false],
    [77568, "Mearns", "George", "George Mearns", false],
    [2098, "Medvedenko", "Slava", "Slava Medvedenko", false],
    [1577, "Mee", "Darnell", "Darnell Mee", false],
    [201975, "Meeks", "Jodie", "Jodie Meeks", false],
    [77570, "Meely", "Cliff", "Cliff Meely", false],
    [77571, "Meents", "Scott", "Scott Meents", false],
    [77572, "Mehen", "Dick", "Dick Mehen", false],
    [77573, "Meineke", "Don", "Don Meineke", false],
    [77574, "Meinhold", "Carl", "Carl Meinhold", false],
    [1626257, "Mejri", "Salah", "Salah Mejri", false],
    [203539, "Mekel", "Gal", "Gal Mekel", false],
    [77576, "Melchionni", "Bill", "Bill Melchionni", false],
    [77575, "Melchionni", "Gary", "Gary Melchionni", false],
    [1629740, "Melli", "Nicolo", "Nicolo Melli", false],
    [203097, "Melo", "Fab", "Fab Melo", false],
    [1629001, "Melton", "De'Anthony", "De'Anthony Melton", true],
    [77577, "Melvin", "Ed", "Ed Melvin", false],
    [77578, "Meminger", "Dean", "Dean Meminger", false],
    [77579, "Mencel", "Chuck", "Chuck Mencel", false],
    [77580, "Mengelt", "John", "John Mengelt", false],
    [77581, "Menke", "Ken", "Ken Menke", false],
    [200822, "Mensah-Bonsu", "Pops", "Pops Mensah-Bonsu", false],
    [1500, "Mercer", "Ron", "Ron Mercer", false],
    [77582, "Meriweather", "Joe", "Joe Meriweather", false],
    [77583, "Meriwether", "Porter", "Porter Meriwether", false],
    [1630241, "Merrill", "Sam", "Sam Merrill", true],
    [77584, "Meschery", "Tom", "Tom Meschery", false],
    [1629002, "Metu", "Chimezie", "Chimezie Metu", true],
    [684, "Meyer", "Loren", "Loren Meyer", false],
    [77586, "Meyers", "Dave", "Dave Meyers", false],
    [77587, "Miasek", "Stan", "Stan Miasek", false],
    [77588, "Micheaux", "Larry", "Larry Micheaux", false],
    [1626175, "Mickey", "Jordan", "Jordan Mickey", false],
    [203114, "Middleton", "Khris", "Khris Middleton", true],
    [77589, "Mihalik", "Zigmund", "Zigmund Mihalik", false],
    [2036, "Mihm", "Chris", "Chris Mihm", false],
    [1628450, "Mika", "Eric", "Eric Mika", false],
    [77590, "Mikan", "Ed", "Ed Mikan", false],
    [600012, "Mikan", "George", "George Mikan", false],
    [77591, "Mikan", "Larry", "Larry Mikan", false],
    [77593, "Mikkelsen", "Vern", "Vern Mikkelsen", false],
    [77594, "Miksis", "Al", "Al Miksis", false],
    [101223, "Miles", "Aaron", "Aaron Miles", false],
    [101139, "Miles", "CJ", "CJ Miles", false],
    [2032, "Miles", "Darius", "Darius Miles", false],
    [77596, "Miles", "Eddie", "Eddie Miles", false],
    [1527, "Milic", "Marko", "Marko Milic", false],
    [2545, "Milicic", "Darko", "Darko Milicic", false],
    [77598, "Militzok", "Nat", "Nat Militzok", false],
    [1889, "Miller", "Andre", "Andre Miller", false],
    [292, "Miller", "Anthony", "Anthony Miller", false],
    [77605, "Miller", "Bill", "Bill Miller", false],
    [77603, "Miller", "Bob", "Bob Miller", false],
    [1802, "Miller", "Brad", "Brad Miller", false],
    [203121, "Miller", "Darius", "Darius Miller", false],
    [77602, "Miller", "Dick", "Dick Miller", false],
    [77599, "Miller", "Eddie", "Eddie Miller", false],
    [77600, "Miller", "Harry", "Harry Miller", false],
    [77601, "Miller", "Jay", "Jay Miller", false],
    [1626259, "Miller", "Malcolm", "Malcolm Miller", false],
    [2034, "Miller", "Mike", "Mike Miller", false],
    [932, "Miller", "Oliver", "Oliver Miller", false],
    [203113, "Miller", "Quincy", "Quincy Miller", false],
    [397, "Miller", "Reggie", "Reggie Miller", false],
    [77604, "Miller", "Walt", "Walt Miller", false],
    [168, "Mills", "Chris", "Chris Mills", false],
    [77606, "Mills", "John", "John Mills", false],
    [201988, "Mills", "Patty", "Patty Mills", true],
    [371, "Mills", "Terry", "Terry Mills", false],
    [202407, "Millsap", "Elijah", "Elijah Millsap", false],
    [200794, "Millsap", "Paul", "Paul Millsap", false],
    [1629003, "Milton", "Shake", "Shake Milton", true],
    [114, "Miner", "Harold", "Harold Miner", false],
    [77609, "Minniefield", "Dirk", "Dirk Minniefield", false],
    [77610, "Minor", "Dave", "Dave Minor", false],
    [65, "Minor", "Greg", "Greg Minor", false],
    [77612, "Minor", "Mark", "Mark Minor", false],
    [1631169, "Minott", "Josh", "Josh Minott", true],
    [202703, "Mirotic", "Nikola", "Nikola Mirotic", false],
    [77613, "Misaka", "Wat", "Wat Misaka", false],
    [1954, "Miskiri", "Jason", "Jason Miskiri", false],
    [1630558, "Mitchell", "Davion", "Davion Mitchell", true],
    [1628378, "Mitchell", "Donovan", "Donovan Mitchell", true],
    [77615, "Mitchell", "Mike", "Mike Mitchell", false],
    [77616, "Mitchell", "Murray", "Murray Mitchell", false],
    [417, "Mitchell", "Sam", "Sam Mitchell", false],
    [77614, "Mitchell", "Todd", "Todd Mitchell", false],
    [203183, "Mitchell", "Tony", "Tony Mitchell", false],
    [203502, "Mitchell", "Tony", "Tony Mitchell", false],
    [1628513, "Mitrou-Long", "Naz", "Naz Mitrou-Long", false],
    [77618, "Mix", "Steve", "Steve Mix", false],
    [77619, "Mlkvy", "Bill", "Bill Mlkvy", false],
    [1749, "Mobley", "Cuttino", "Cuttino Mobley", false],
    [211, "Mobley", "Eric", "Eric Mobley", false],
    [1630596, "Mobley", "Evan", "Evan Mobley", true],
    [1630600, "Mobley", "Isaiah", "Isaiah Mobley", true],
    [77621, "Moffett", "Larry", "Larry Moffett", false],
    [77622, "Mogus", "Leo", "Leo Mogus", false],
    [1737, "Mohammed", "Nazr", "Nazr Mohammed", false],
    [2040, "Moiso", "Jerome", "Jerome Moiso", false],
    [77623, "Mokeski", "Paul", "Paul Mokeski", false],
    [1629690, "Mokoka", "Adam", "Adam Mokoka", false],
    [77624, "Molinas", "Jack", "Jack Molinas", false],
    [77625, "Molis", "Wayne", "Wayne Molis", false],
    [77626, "Moncrief", "Sidney", "Sidney Moncrief", false],
    [1631320, "Moneke", "Chima", "Chima Moneke", true],
    [77627, "Money", "Eric", "Eric Money", false],
    [2752, "Monia", "Sergei", "Sergei Monia", false],
    [1628370, "Monk", "Malik", "Malik Monk", true],
    [600006, "Monroe", "Earl", "Earl Monroe", false],
    [202328, "Monroe", "Greg", "Greg Monroe", false],
    [77628, "Monroe", "Rodney", "Rodney Monroe", false],
    [1626242, "Montero", "Luis", "Luis Montero", false],
    [77630, "Montgomery", "Howie", "Howie Montgomery", false],
    [376, "Montross", "Eric", "Eric Montross", false],
    [1630541, "Moody", "Moses", "Moses Moody", true],
    [200081, "Moon", "Jamario", "Jamario Moon", false],
    [1629875, "Moon", "Xavier", "Xavier Moon", true],
    [77631, "Mooney", "Jim", "Jim Mooney", false],
    [1629760, "Mooney", "Matt", "Matt Mooney", false],
    [77632, "Moore", "Andre", "Andre Moore", false],
    [1628500, "Moore", "Ben", "Ben Moore", false],
    [202734, "Moore", "E'Twaun", "E'Twaun Moore", false],
    [77634, "Moore", "Jackie", "Jackie Moore", false],
    [77633, "Moore", "Johnny", "Johnny Moore", false],
    [77635, "Moore", "Lowes", "Lowes Moore", false],
    [1630, "Moore", "Mikki", "Mikki Moore", false],
    [77636, "Moore", "Otto", "Otto Moore", false],
    [77637, "Moore", "Ron", "Ron Moore", false],
    [929, "Moore", "Tracy", "Tracy Moore", false],
    [1631111, "Moore Jr.", "Wendell", "Wendell Moore Jr.", true],
    [1629630, "Morant", "Ja", "Ja Morant", true],
    [203961, "Moreland", "Eric", "Eric Moreland", false],
    [77639, "Moreland", "Jackie", "Jackie Moreland", false],
    [77640, "Morgan", "Guy", "Guy Morgan", false],
    [1629752, "Morgan", "Juwan", "Juwan Morgan", false],
    [77641, "Morgan", "Rex", "Rex Morgan", false],
    [77642, "Morgenthaler", "Elmo", "Elmo Morgenthaler", false],
    [77644, "Morningstar", "Darren", "Darren Morningstar", false],
    [356, "Morris", "Chris", "Chris Morris", false],
    [202721, "Morris", "Darius", "Darius Morris", false],
    [77647, "Morris", "Isaiah", "Isaiah Morris", false],
    [1628537, "Morris", "Jaylen", "Jaylen Morris", false],
    [202693, "Morris", "Markieff", "Markieff Morris", true],
    [77646, "Morris", "Max", "Max Morris", false],
    [1628420, "Morris", "Monte", "Monte Morris", true],
    [201043, "Morris", "Randolph", "Randolph Morris", false],
    [2242, "Morris", "Terence", "Terence Morris", false],
    [202694, "Morris Sr.", "Marcus", "Marcus Morris Sr.", true],
    [200747, "Morrison", "Adam", "Adam Morrison", false],
    [77648, "Morrison", "Dwight", "Dwight Morrison", false],
    [77649, "Morrison", "Mike", "Mike Morrison", false],
    [201627, "Morrow", "Anthony", "Anthony Morrow", false],
    [132, "Morton", "Dwayne", "Dwayne Morton", false],
    [1972, "Morton", "John", "John Morton", false],
    [77652, "Morton", "Richard", "Richard Morton", false],
    [77653, "Mosley", "Glenn", "Glenn Mosley", false],
    [77654, "Moss", "Perry", "Perry Moss", false],
    [734, "Moten", "Lawrence", "Lawrence Moten", false],
    [202700, "Motiejunas", "Donatas", "Donatas Motiejunas", false],
    [1628405, "Motley", "Johnathan", "Johnathan Motley", false],
    [2069, "Mottola", "Hanno", "Hanno Mottola", false],
    [203102, "Moultrie", "Arnett", "Arnett Moultrie", false],
    [297, "Mourning", "Alonzo", "Alonzo Mourning", false],
    [202389, "Mozgov", "Timofey", "Timofey Mozgov", false],
    [77657, "Mrazovich", "Chuck", "Chuck Mrazovich", false],
    [1626144, "Mudiay", "Emmanuel", "Emmanuel Mudiay", false],
    [77658, "Mueller", "Erwin", "Erwin Mueller", false],
    [203498, "Muhammad", "Shabazz", "Shabazz Muhammad", false],
    [1628539, "Mulder", "Mychal", "Mychal Mulder", false],
    [77659, "Mullaney", "Joe", "Joe Mullaney", false],
    [77660, "Mullens", "Bob", "Bob Mullens", false],
    [201957, "Mullens", "Byron", "Byron Mullens", false],
    [904, "Mullin", "Chris", "Chris Mullin", false],
    [77662, "Mullins", "Jeff", "Jeff Mullins", false],
    [942, "Mundt", "Todd", "Todd Mundt", false],
    [204098, "Munford", "Xavier", "Xavier Munford", false],
    [77664, "Munk", "Chris", "Chris Munk", false],
    [77665, "Munroe", "George", "George Munroe", false],
    [785, "Murdock", "Eric", "Eric Murdock", false],
    [49, "Muresan", "Gheorghe", "Gheorghe Muresan", false],
    [1630278, "Murkey", "Ade", "Ade Murkey", false],
    [77668, "Murphy", "Allen", "Allen Murphy", false],
    [77669, "Murphy", "Calvin", "Calvin Murphy", false],
    [77672, "Murphy", "Dick", "Dick Murphy", false],
    [203513, "Murphy", "Erik", "Erik Murphy", false],
    [77670, "Murphy", "Jay", "Jay Murphy", false],
    [77671, "Murphy", "John", "John Murphy", false],
    [203122, "Murphy", "Kevin", "Kevin Murphy", false],
    [77673, "Murphy", "Ronnie", "Ronnie Murphy", false],
    [77674, "Murphy", "Tod", "Tod Murphy", false],
    [2211, "Murphy", "Troy", "Troy Murphy", false],
    [1630530, "Murphy III", "Trey", "Trey Murphy III", true],
    [1627749, "Murray", "Dejounte", "Dejounte Murray", true],
    [2436, "Murray", "Flip", "Flip Murray", false],
    [1627750, "Murray", "Jamal", "Jamal Murray", true],
    [1631099, "Murray", "Keegan", "Keegan Murray", true],
    [77675, "Murray", "Ken", "Ken Murray", false],
    [441, "Murray", "Lamond", "Lamond Murray", false],
    [145, "Murray", "Tracy", "Tracy Murray", false],
    [77676, "Murrey", "Dorie", "Dorie Murrey", false],
    [203315, "Murry", "Toure'", "Toure' Murry", false],
    [1629058, "Musa", "Dzanan", "Dzanan Musa", false],
    [203488, "Muscala", "Mike", "Mike Muscala", true],
    [77677, "Musi", "Angelo", "Angelo Musi", false],
    [1054, "Mustaf", "Jerrod", "Jerrod Mustaf", false],
    [87, "Mutombo", "Dikembe", "Dikembe Mutombo", false],
    [982, "Muursepp", "Martin", "Martin Muursepp", false],
    [939, "Myers", "Pete", "Pete Myers", false],
    [1629004, "Mykhailiuk", "Svi", "Svi Mykhailiuk", true],
    [1823, "N'diaye", "Makhtar", "Makhtar N'diaye", false],
    [1626122, "N'diaye", "Makhtar", "Makhtar N'diaye", false],
    [2055, "N'diaye", "Mamadou", "Mamadou N'diaye", false],
    [77681, "Naber", "Bob", "Bob Naber", false],
    [77682, "Nachamkin", "Boris", "Boris Nachamkin", false],
    [2411, "Nachbar", "Bostjan", "Bostjan Nachbar", false],
    [1627846, "Nader", "Abdel", "Abdel Nader", false],
    [77683, "Nagel", "Jerry", "Jerry Nagel", false],
    [77684, "Nagy", "Fred", "Fred Nagy", false],
    [1924, "Nailon", "Lee", "Lee Nailon", false],
    [2059, "Najera", "Eduardo", "Eduardo Najera", false],
    [77685, "Nance", "Larry", "Larry Nance", false],
    [1626204, "Nance Jr.", "Larry", "Larry Nance Jr.", true],
    [203894, "Napier", "Shabazz", "Shabazz Napier", false],
    [77686, "Napolitano", "Paul", "Paul Napolitano", false],
    [77688, "Nash", "Bob", "Bob Nash", false],
    [77687, "Nash", "Charles", "Charles Nash", false],
    [959, "Nash", "Steve", "Steve Nash", false],
    [77689, "Nater", "Swen", "Swen Nater", false],
    [77690, "Nathan", "Howard", "Howard Nathan", false],
    [77691, "Natt", "Calvin", "Calvin Natt", false],
    [77692, "Natt", "Kenny", "Kenny Natt", false],
    [77693, "Naulls", "Willie", "Willie Naulls", false],
    [2434, "Navarro", "Juan Carlos", "Juan Carlos Navarro", false],
    [202380, "Ndiaye", "Hamady", "Hamady Ndiaye", false],
    [101238, "Ndong", "Boniface", "Boniface Ndong", false],
    [1626254, "Ndour", "Maurice", "Maurice Ndour", false],
    [77694, "Neal", "Craig", "Craig Neal", false],
    [202390, "Neal", "Gary", "Gary Neal", false],
    [77695, "Neal", "Jim", "Jim Neal", false],
    [77696, "Neal", "Lloyd", "Lloyd Neal", false],
    [77697, "Nealy", "Ed", "Ed Nealy", false],
    [203517, "Nedovic", "Nemanja", "Nemanja Nedovic", false],
    [77698, "Negratti", "Al", "Al Negratti", false],
    [77699, "Nelson", "Barry", "Barry Nelson", false],
    [201634, "Nelson", "DeMarcus", "DeMarcus Nelson", false],
    [77700, "Nelson", "Don", "Don Nelson", false],
    [2749, "Nelson", "Jameer", "Jameer Nelson", false],
    [77701, "Nelson", "Louie", "Louie Nelson", false],
    [1629614, "Nembhard", "Andrew", "Andrew Nembhard", true],
    [1129, "Nembhard", "Ruben", "Ruben Nembhard", false],
    [1630612, "Nembhard Jr.", "RJ", "RJ Nembhard Jr.", false],
    [2403, "Nene", "", "Nene", false],
    [1838, "Nesby", "Tyrone", "Tyrone Nesby", false],
    [1630174, "Nesmith", "Aaron", "Aaron Nesmith", true],
    [77704, "Nessley", "Martin", "Martin Nessley", false],
    [1725, "Nesterovic", "Rasho", "Rasho Nesterovic", false],
    [203526, "Neto", "Raul", "Raul Neto", true],
    [77705, "Neumann", "Johnny", "Johnny Neumann", false],
    [77706, "Neumann", "Paul", "Paul Neumann", false],
    [77707, "Nevitt", "Chuck", "Chuck Nevitt", false],
    [1116, "Newbern", "Melvin", "Melvin Newbern", false],
    [1096, "Newbill", "Ivano", "Ivano Newbill", false],
    [1956, "Newble", "Ira", "Ira Newble", false],
    [77710, "Newlin", "Mike", "Mike Newlin", false],
    [271, "Newman", "Johnny", "Johnny Newman", false],
    [1629005, "Newman", "Malik", "Malik Newman", false],
    [77712, "Newmark", "Dave", "Dave Newmark", false],
    [1627777, "Niang", "Georges", "Georges Niang", true],
    [201193, "Nichols", "Demetris", "Demetris Nichols", false],
    [77713, "Nichols", "Jack", "Jack Nichols", false],
    [203094, "Nicholson", "Andrew", "Andrew Nicholson", false],
    [1093, "Nickerson", "Gaylon", "Gaylon Nickerson", false],
    [77715, "Nicks", "Carl", "Carl Nicks", false],
    [77716, "Niemann", "Rich", "Rich Niemann", false],
    [77717, "Niemiera", "John", "John Niemiera", false],
    [77718, "Niles", "Mike", "Mike Niles", false],
    [77719, "Nimphius", "Kurt", "Kurt Nimphius", false],
    [1630227, "Nix", "Daishen", "Daishen Nix", true],
    [77720, "Nix", "Dyron", "Dyron Nix", false],
    [77721, "Nixon", "Norm", "Norm Nixon", false],
    [1630192, "Nnaji", "Zeke", "Zeke Nnaji", true],
    [201149, "Noah", "Joakim", "Joakim Noah", false],
    [77722, "Noble", "Chuck", "Chuck Noble", false],
    [2804, "Nocioni", "Andres", "Andres Nocioni", false],
    [200786, "Noel", "David", "David Noel", false],
    [203457, "Noel", "Nerlens", "Nerlens Noel", true],
    [77723, "Noel", "Paul", "Paul Noel", false],
    [203512, "Nogueira", "Lucas", "Lucas Nogueira", false],
    [77724, "Nolan", "Jim", "Jim Nolan", false],
    [77725, "Nolen", "Paul", "Paul Nolen", false],
    [999, "Nordgaard", "Jeff", "Jeff Nordgaard", false],
    [77727, "Nordmann", "Robert", "Robert Nordmann", false],
    [77728, "Norlander", "Johnny", "Johnny Norlander", false],
    [77729, "Norman", "Connie", "Connie Norman", false],
    [80, "Norman", "Ken", "Ken Norman", false],
    [77731, "Norris", "Audie", "Audie Norris", false],
    [983, "Norris", "Moochie", "Moochie Norris", false],
    [77732, "Norris", "Sylvester", "Sylvester Norris", false],
    [1629668, "Norvell Jr.", "Zach", "Zach Norvell Jr.", false],
    [77733, "Norwood", "Willie", "Willie Norwood", false],
    [77734, "Nostrand", "George", "George Nostrand", false],
    [77735, "Noszka", "Stan", "Stan Noszka", false],
    [77736, "Novak", "Mike", "Mike Novak", false],
    [200779, "Novak", "Steve", "Steve Novak", false],
    [1629669, "Nowell", "Jaylen", "Jaylen Nowell", true],
    [77737, "Nowell", "Mel", "Mel Nowell", false],
    [1717, "Nowitzki", "Dirk", "Dirk Nowitzki", false],
    [1628373, "Ntilikina", "Frank", "Frank Ntilikina", true],
    [1629134, "Nunn", "Kendrick", "Kendrick Nunn", true],
    [203263, "Nunnally", "James", "James Nunnally", false],
    [203994, "Nurkic", "Jusuf", "Jusuf Nurkic", true],
    [77738, "Nutt", "Dennis", "Dennis Nutt", false],
    [1628021, "Nwaba", "David", "David Nwaba", false],
    [1629670, "Nwora", "Jordan", "Jordan Nwora", true],
    [1022, "Nwosu", "Julius", "Julius Nwosu", false],
    [1525, "O'Bannon", "Charles", "Charles O'Bannon", false],
    [709, "O'Bannon", "Ed", "Ed O'Bannon", false],
    [77742, "O'Boyle", "John", "John O'Boyle", false],
    [77744, "O'Brien", "Bob", "Bob O'Brien", false],
    [77743, "O'Brien", "Buckshot", "Buckshot O'Brien", false],
    [1626266, "O'Brien", "JJ", "JJ O'Brien", false],
    [200753, "O'Bryant", "Patrick", "Patrick O'Bryant", false],
    [203948, "O'Bryant III", "Johnny", "Johnny O'Bryant III", false],
    [77745, "O'Connell", "Dermie", "Dermie O'Connell", false],
    [77746, "O'Donnell", "Andy", "Andy O'Donnell", false],
    [77750, "O'Grady", "Francis", "Francis O'Grady", false],
    [77752, "O'Keefe", "Dick", "Dick O'Keefe", false],
    [77753, "O'Keefe", "Tommy", "Tommy O'Keefe", false],
    [77754, "O'Koren", "Mike", "Mike O'Koren", false],
    [77766, "O'Malley", "Grady", "Grady O'Malley", false],
    [979, "O'Neal", "Jermaine", "Jermaine O'Neal", false],
    [406, "O'Neal", "Shaquille", "Shaquille O'Neal", false],
    [1626220, "O'Neale", "Royce", "Royce O'Neale", true],
    [77767, "O'Neill", "Mike", "Mike O'Neill", false],
    [203124, "O'Quinn", "Kyle", "Kyle O'Quinn", false],
    [77773, "O'Shea", "Kevin", "Kevin O'Shea", false],
    [77774, "O'Shields", "Garland", "Garland O'Shields", false],
    [77776, "O'Sullivan", "Dan", "Dan O'Sullivan", false],
    [891, "Oakley", "Charles", "Charles Oakley", false],
    [101177, "Oberto", "Fabricio", "Fabricio Oberto", false],
    [1627849, "Ochefu", "Daniel", "Daniel Ochefu", false],
    [201141, "Oden", "Greg", "Greg Oden", false],
    [1885, "Odom", "Lamar", "Lamar Odom", false],
    [77747, "Ogden", "Carlos", "Carlos Ogden", false],
    [77748, "Ogden", "Ralph", "Ralph Ogden", false],
    [1628, "Ogg", "Alan", "Alan Ogg", false],
    [77751, "Ohl", "Don", "Don Ohl", false],
    [203317, "Ohlbrecht", "Tim", "Tim Ohlbrecht", false],
    [1628400, "Ojeleye", "Semi", "Semi Ojeleye", false],
    [2731, "Okafor", "Emeka", "Emeka Okafor", false],
    [1626143, "Okafor", "Jahlil", "Jahlil Okafor", false],
    [1629643, "Okeke", "Chuma", "Chuma Okeke", true],
    [1629059, "Okobo", "Elie", "Elie Okobo", false],
    [1629006, "Okogie", "Josh", "Josh Okogie", true],
    [1630168, "Okongwu", "Onyeka", "Onyeka Okongwu", true],
    [1630171, "Okoro", "Isaac", "Isaac Okoro", true],
    [1629644, "Okpala", "KZ", "KZ Okpala", true],
    [2246, "Okur", "Mehmet", "Mehmet Okur", false],
    [203506, "Oladipo", "Victor", "Victor Oladipo", true],
    [165, "Olajuwon", "Hakeem", "Hakeem Olajuwon", false],
    [77756, "Olberding", "Mark", "Mark Olberding", false],
    [77757, "Oldham", "Jawann", "Jawann Oldham", false],
    [77758, "Oldham", "Johnny", "Johnny Oldham", false],
    [77759, "Oleynick", "Frank", "Frank Oleynick", false],
    [77760, "Olive", "John", "John Olive", false],
    [1680, "Oliver", "Brian", "Brian Oliver", false],
    [1628419, "Oliver", "Cameron", "Cameron Oliver", false],
    [2352, "Oliver", "Dean", "Dean Oliver", false],
    [1429, "Oliver", "Jimmy", "Jimmy Oliver", false],
    [1563, "Ollie", "Kevin", "Kevin Ollie", false],
    [77764, "Ollrich", "Gene", "Gene Ollrich", false],
    [1709, "Olowokandi", "Michael", "Michael Olowokandi", false],
    [77765, "Olsen", "Enoch", "Enoch Olsen", false],
    [203482, "Olynyk", "Kelly", "Kelly Olynyk", true],
    [1630647, "Omoruyi", "Eugene", "Eugene Omoruyi", true],
    [1629671, "Oni", "Miye", "Miye Oni", false],
    [202620, "Onuaku", "Arinze", "Arinze Onuaku", false],
    [1627778, "Onuaku", "Chinanu", "Chinanu Onuaku", false],
    [77768, "Orms", "Barry", "Barry Orms", false],
    [77769, "Orr", "Johnny", "Johnny Orr", false],
    [77770, "Orr", "Louis", "Louis Orr", false],
    [77771, "Ortiz", "Jose", "Jose Ortiz", false],
    [202350, "Orton", "Daniel", "Daniel Orton", false],
    [77772, "Osborne", "Chuck", "Chuck Osborne", false],
    [1626224, "Osman", "Cedi", "Cedi Osman", true],
    [77775, "Osterkorn", "Wally", "Wally Osterkorn", false],
    [731, "Ostertag", "Greg", "Greg Ostertag", false],
    [77777, "Othick", "Matt", "Matt Othick", false],
    [77778, "Otten", "Don", "Don Otten", false],
    [77779, "Otten", "Mac", "Mac Otten", false],
    [1630187, "Oturu", "Daniel", "Daniel Oturu", false],
    [1626162, "Oubre Jr.", "Kelly", "Kelly Oubre Jr.", true],
    [448, "Outlaw", "Bo", "Bo Outlaw", false],
    [2566, "Outlaw", "Travis", "Travis Outlaw", false],
    [77780, "Overton", "Claude", "Claude Overton", false],
    [77, "Overton", "Doug", "Doug Overton", false],
    [101261, "Owens", "Andre", "Andre Owens", false],
    [182, "Owens", "Billy", "Billy Owens", false],
    [2442, "Owens", "Chris", "Chris Owens", false],
    [77782, "Owens", "Eddie", "Eddie Owens", false],
    [77783, "Owens", "James", "James Owens", false],
    [77784, "Owens", "Jim", "Jim Owens", false],
    [77785, "Owens", "Keith", "Keith Owens", false],
    [202082, "Owens", "Larry", "Larry Owens", false],
    [1629745, "Owens", "Tariq", "Tariq Owens", false],
    [77786, "Owens", "Tom", "Tom Owens", false],
    [1077, "Owes", "Ray", "Ray Owes", false],
    [2071, "Oyedeji", "Olumide", "Olumide Oyedeji", false],
    [77788, "Pace", "Joe", "Joe Pace", false],
    [2585, "Pachulia", "Zaza", "Zaza Pachulia", false],
    [123, "Pack", "Robert", "Robert Pack", false],
    [77789, "Paddio", "Gerald", "Gerald Paddio", false],
    [1909, "Padgett", "Scott", "Scott Padgett", false],
    [1629873, "Paige", "Jaysean", "Jaysean Paige", false],
    [1627779, "Paige", "Marcus", "Marcus Paige", false],
    [77790, "Paine", "Fred", "Fred Paine", false],
    [1960, "Palacio", "Milt", "Milt Palacio", false],
    [77791, "Palazzi", "Togo", "Togo Palazzi", false],
    [77792, "Palmer", "Jim", "Jim Palmer", false],
    [77793, "Palmer", "John", "John Palmer", false],
    [1629309, "Palmer", "Trayvon", "Trayvon Palmer", false],
    [77794, "Palmer", "Walter", "Walter Palmer", false],
    [1630698, "Pangos", "Kevin", "Kevin Pangos", false],
    [1950, "Panko", "Andy", "Andy Panko", false],
    [1627834, "Papagiannis", "Georgios", "Georgios Papagiannis", false],
    [203123, "Papanikolaou", "Kostas", "Kostas Papanikolaou", false],
    [2457, "Pargo", "Jannero", "Jannero Pargo", false],
    [202951, "Pargo", "Jeremy", "Jeremy Pargo", false],
    [77797, "Parham", "Estes", "Estes Parham", false],
    [305, "Parish", "Robert", "Robert Parish", false],
    [77799, "Park", "Medford", "Medford Park", false],
    [1515, "Parker", "Anthony", "Anthony Parker", false],
    [203953, "Parker", "Jabari", "Jabari Parker", false],
    [2470, "Parker", "Smush", "Smush Parker", false],
    [77800, "Parker", "Sonny", "Sonny Parker", false],
    [2225, "Parker", "Tony", "Tony Parker", false],
    [77801, "Parkinson", "Jack", "Jack Parkinson", false],
    [685, "Parks", "Cherokee", "Cherokee Parks", false],
    [77802, "Parr", "Jack", "Jack Parr", false],
    [77803, "Parrack", "Doyle", "Doyle Parrack", false],
    [77804, "Parsley", "Charlie", "Charlie Parsley", false],
    [202718, "Parsons", "Chandler", "Chandler Parsons", false],
    [1629672, "Paschall", "Eric", "Eric Paschall", false],
    [1628394, "Pasecniks", "Anzejs", "Anzejs Pasecniks", false],
    [1097, "Paspalj", "Zarko", "Zarko Paspalj", false],
    [77806, "Passaglia", "Marty", "Marty Passaglia", false],
    [77807, "Pastushok", "George A.", "George A. Pastushok", false],
    [77808, "Patrick", "Myles", "Myles Patrick", false],
    [77809, "Patrick", "Stan", "Stan Patrick", false],
    [1754, "Patterson", "Andrae", "Andrae Patterson", false],
    [77811, "Patterson", "George", "George Patterson", false],
    [203934, "Patterson", "Lamar", "Lamar Patterson", false],
    [202335, "Patterson", "Patrick", "Patrick Patterson", false],
    [1739, "Patterson", "Ruben", "Ruben Patterson", false],
    [77812, "Patterson", "Steve", "Steve Patterson", false],
    [77813, "Patterson", "Tommy", "Tommy Patterson", false],
    [77814, "Patterson", "Worthy", "Worthy Patterson", false],
    [1628383, "Patton", "Justin", "Justin Patton", false],
    [203464, "Paul", "Brandon", "Brandon Paul", false],
    [101108, "Paul", "Chris", "Chris Paul", true],
    [77815, "Paulk", "Charlie", "Charlie Paulk", false],
    [77816, "Paulson", "Jerry", "Jerry Paulson", false],
    [77817, "Paultz", "Billy", "Billy Paultz", false],
    [2562, "Pavlovic", "Sasha", "Sasha Pavlovic", false],
    [77818, "Paxson", "Jim", "Jim Paxson", false],
    [77819, "Paxson", "Jim", "Jim Paxson", false],
    [77820, "Paxson", "John", "John Paxson", false],
    [77821, "Payak", "Johnny", "Johnny Payak", false],
    [203940, "Payne", "Adreian", "Adreian Payne", false],
    [1626166, "Payne", "Cameron", "Cameron Payne", true],
    [77822, "Payne", "Kenny", "Kenny Payne", false],
    [77823, "Payne", "Tom", "Tom Payne", false],
    [203901, "Payton", "Elfrid", "Elfrid Payton", false],
    [56, "Payton", "Gary", "Gary Payton", false],
    [77824, "Payton", "Mel", "Mel Payton", false],
    [1627780, "Payton II", "Gary", "Gary Payton II", true],
    [77825, "Pearcy", "George", "George Pearcy", false],
    [77826, "Pearcy", "Henry", "Henry Pearcy", false],
    [200762, "Pecherov", "Oleksiy", "Oleksiy Pecherov", false],
    [77827, "Peck", "Wiley", "Wiley Peck", false],
    [324, "Peeler", "Anthony", "Anthony Peeler", false],
    [201593, "Pekovic", "Nikola", "Nikola Pekovic", false],
    [77828, "Pelkington", "Jake", "Jake Pelkington", false],
    [203658, "Pelle", "Norvel", "Norvel Pelle", false],
    [77829, "Pellom", "Sam", "Sam Pellom", false],
    [2130, "Penberthy", "Mike", "Mike Penberthy", false],
    [2667, "Penigar", "Desmond", "Desmond Penigar", false],
    [2632, "Penney", "Kirk", "Kirk Penney", false],
    [46, "Peplowski", "Mike", "Mike Peplowski", false],
    [781, "Perdue", "Will", "Will Perdue", false],
    [2570, "Perkins", "Kendrick", "Kendrick Perkins", false],
    [64, "Perkins", "Sam", "Sam Perkins", false],
    [77834, "Perkins", "Warren", "Warren Perkins", false],
    [200785, "Perovic", "Kosta", "Kosta Perovic", false],
    [1628506, "Perrantes", "London", "London Perrantes", false],
    [77835, "Perry", "Curtis", "Curtis Perry", false],
    [386, "Perry", "Elliot", "Elliot Perry", false],
    [1629617, "Perry", "Reggie", "Reggie Perry", false],
    [897, "Perry", "Tim", "Tim Perry", false],
    [456, "Person", "Chuck", "Chuck Person", false],
    [445, "Person", "Wesley", "Wesley Person", false],
    [1628409, "Peters", "Alec", "Alec Peters", false],
    [77839, "Petersen", "Jim", "Jim Petersen", false],
    [77840, "Petersen", "Loy", "Loy Petersen", false],
    [77843, "Peterson", "Bob", "Bob Peterson", false],
    [77841, "Peterson", "Ed", "Ed Peterson", false],
    [77842, "Peterson", "Mel", "Mel Peterson", false],
    [2050, "Peterson", "Morris", "Morris Peterson", false],
    [77844, "Petrie", "Geoff", "Geoff Petrie", false],
    [101130, "Petro", "Johan", "Johan Petro", false],
    [77845, "Petrovic", "Drazen", "Drazen Petrovic", false],
    [77846, "Petruska", "Richard", "Richard Petruska", false],
    [77847, "Pettit", "Bob", "Bob Pettit", false],
    [77848, "Phegley", "Roger", "Roger Phegley", false],
    [77850, "Phelan", "Jack", "Jack Phelan", false],
    [77849, "Phelan", "Jim", "Jim Phelan", false],
    [74, "Phelps", "Derrick", "Derrick Phelps", false],
    [77852, "Phelps", "Mike", "Mike Phelps", false],
    [77853, "Phillip", "Andy", "Andy Phillip", false],
    [1629341, "Phillip", "Tarik", "Tarik Phillip", false],
    [77854, "Phillips", "Eddie", "Eddie Phillips", false],
    [77855, "Phillips", "Gary", "Gary Phillips", false],
    [184, "Phills", "Bobby", "Bobby Phills", false],
    [15, "Piatkowski", "Eric", "Eric Piatkowski", false],
    [1630691, "Pickett", "Jamorko", "Jamorko Pickett", false],
    [1718, "Pierce", "Paul", "Paul Pierce", false],
    [894, "Pierce", "Ricky", "Ricky Pierce", false],
    [77858, "Pietkiewicz", "Stan", "Stan Pietkiewicz", false],
    [2554, "Pietrus", "Mickael", "Mickael Pietrus", false],
    [77859, "Pilch", "John", "John Pilch", false],
    [291, "Pinckney", "Ed", "Ed Pinckney", false],
    [200838, "Pinkney", "Kevinn", "Kevinn Pinkney", false],
    [77861, "Pinone", "John", "John Pinone", false],
    [1629033, "Pinson", "Theo", "Theo Pinson", true],
    [77862, "Piontek", "Dave", "Dave Piontek", false],
    [77863, "Piotrowski", "Tom", "Tom Piotrowski", false],
    [937, "Pippen", "Scottie", "Scottie Pippen", false],
    [1630590, "Pippen Jr.", "Scotty", "Scotty Pippen Jr.", true],
    [77864, "Pittman", "Charlie", "Charlie Pittman", false],
    [202354, "Pittman", "Dexter", "Dexter Pittman", false],
    [2565, "Planinic", "Zoran", "Zoran Planinic", false],
    [202353, "Pleiss", "Tibor", "Tibor Pleiss", false],
    [1627850, "Plumlee", "Marshall", "Marshall Plumlee", false],
    [203486, "Plumlee", "Mason", "Mason Plumlee", true],
    [203101, "Plumlee", "Miles", "Miles Plumlee", false],
    [77865, "Plummer", "Gary", "Gary Plummer", false],
    [2750, "Podkolzin", "Pavel", "Pavel Podkolzin", false],
    [1627751, "Poeltl", "Jakob", "Jakob Poeltl", true],
    [1629738, "Poirier", "Vincent", "Vincent Poirier", false],
    [1630197, "Pokusevski", "Aleksej", "Aleksej Pokusevski", true],
    [77866, "Polee", "Dwayne", "Dwayne Polee", false],
    [77867, "Pollard", "Jim", "Jim Pollard", false],
    [1513, "Pollard", "Scot", "Scot Pollard", false],
    [77868, "Polson", "Ralph", "Ralph Polson", false],
    [178, "Polynice", "Olden", "Olden Polynice", false],
    [77869, "Pondexter", "Cliff", "Cliff Pondexter", false],
    [202347, "Pondexter", "Quincy", "Quincy Pondexter", false],
    [1629044, "Ponds", "Shamorie", "Shamorie Ponds", false],
    [1630582, "Pons", "Yves", "Yves Pons", false],
    [1629673, "Poole", "Jordan", "Jordan Poole", true],
    [77870, "Pope", "David", "David Pope", false],
    [998, "Pope", "Mark", "Mark Pope", false],
    [77872, "Popson", "Dave", "Dave Popson", false],
    [77873, "Poquette", "Ben", "Ben Poquette", false],
    [2084, "Porter", "Chris", "Chris Porter", false],
    [77875, "Porter", "Howard", "Howard Porter", false],
    [1629007, "Porter", "Jontay", "Jontay Porter", false],
    [77876, "Porter", "Kevin", "Kevin Porter", false],
    [345, "Porter", "Terry", "Terry Porter", false],
    [1629645, "Porter Jr.", "Kevin", "Kevin Porter Jr.", true],
    [1629008, "Porter Jr.", "Michael", "Michael Porter Jr.", true],
    [203490, "Porter Jr.", "Otto", "Otto Porter Jr.", true],
    [1626171, "Portis", "Bobby", "Bobby Portis", true],
    [77878, "Portman", "Bob", "Bob Portman", false],
    [204001, "Porzingis", "Kristaps", "Kristaps Porzingis", true],
    [1899, "Posey", "James", "James Posey", false],
    [2068, "Postell", "Lavor", "Lavor Postell", false],
    [958, "Potapenko", "Vitaly", "Vitaly Potapenko", false],
    [1630695, "Potter", "Micah", "Micah Potter", true],
    [200796, "Powe", "Leon", "Leon Powe", false],
    [203939, "Powell", "Dwight", "Dwight Powell", true],
    [2694, "Powell", "Josh", "Josh Powell", false],
    [2867, "Powell", "Kasib", "Kasib Powell", false],
    [1629619, "Powell", "Myles", "Myles Powell", false],
    [1626181, "Powell", "Norman", "Norman Powell", true],
    [101232, "Powell", "Roger", "Roger Powell", false],
    [1627816, "Poythress", "Alex", "Alex Poythress", false],
    [77879, "Pressey", "Paul", "Paul Pressey", false],
    [203515, "Pressey", "Phil", "Phil Pressey", false],
    [77880, "Pressley", "Dominic", "Dominic Pressley", false],
    [77881, "Pressley", "Harold", "Harold Pressley", false],
    [1630554, "Preston", "Jason", "Jason Preston", true],
    [201985, "Price", "AJ", "AJ Price", false],
    [85, "Price", "Brent", "Brent Price", false],
    [77883, "Price", "Jim", "Jim Price", false],
    [899, "Price", "Mark", "Mark Price", false],
    [77884, "Price", "Mike", "Mike Price", false],
    [101179, "Price", "Ronnie", "Ronnie Price", false],
    [77882, "Price", "Tony", "Tony Price", false],
    [77886, "Priddy", "Bob", "Bob Priddy", false],
    [203143, "Prigioni", "Pablo", "Pablo Prigioni", false],
    [1630563, "Primo", "Joshua", "Joshua Primo", true],
    [1627752, "Prince", "Taurean", "Taurean Prince", true],
    [2419, "Prince", "Tayshaun", "Tayshaun Prince", false],
    [77887, "Pritchard", "John", "John Pritchard", false],
    [340, "Pritchard", "Kevin", "Kevin Pritchard", false],
    [1630202, "Pritchard", "Payton", "Payton Pritchard", true],
    [1919, "Profit", "Laron", "Laron Profit", false],
    [201172, "Pruitt", "Gabe", "Gabe Pruitt", false],
    [2038, "Przybilla", "Joel", "Joel Przybilla", false],
    [77888, "Pugh", "Les", "Les Pugh", false],
    [77889, "Pugh", "Roy", "Roy Pugh", false],
    [77890, "Pullard", "Anthony", "Anthony Pullard", false],
    [1626643, "Pullen", "Jacob", "Jacob Pullen", false],
    [1628681, "Purvis", "Rodney", "Rodney Purvis", false],
    [77891, "Putman", "Don", "Don Putman", false],
    [1627817, "Quarterman", "Tim", "Tim Quarterman", false],
    [1630243, "Queen", "Trevelin", "Trevelin Queen", true],
    [1629674, "Queta", "Neemias", "Neemias Queta", true],
    [77892, "Quick", "Bob", "Bob Quick", false],
    [1630193, "Quickley", "Immanuel", "Immanuel Quickley", true],
    [200809, "Quinn", "Chris", "Chris Quinn", false],
    [77893, "Quinnett", "Brian", "Brian Quinnett", false],
    [1631311, "Quinones", "Lester", "Lester Quinones", true],
    [1628397, "Rabb", "Ivan", "Ivan Rabb", false],
    [77894, "Rackley", "Luke", "Luke Rackley", false],
    [77895, "Rader", "Howie", "Howie Rader", false],
    [77896, "Radford", "Mark", "Mark Radford", false],
    [77897, "Radford", "Wayne", "Wayne Radford", false],
    [129, "Radja", "Dino", "Dino Radja", false],
    [2209, "Radmanovic", "Vladimir", "Vladimir Radmanovic", false],
    [1893, "Radojevic", "Aleksandar", "Aleksandar Radojevic", false],
    [77899, "Radovich", "Frank", "Frank Radovich", false],
    [77900, "Radovich", "Moe", "Moe Radovich", false],
    [203545, "Raduljica", "Miroslav", "Miroslav Raduljica", false],
    [77901, "Radziszewski", "Ray", "Ray Radziszewski", false],
    [77902, "Ragelis", "Ray", "Ray Ragelis", false],
    [77903, "Raiken", "Sherwin", "Sherwin Raiken", false],
    [77904, "Rains", "Ed", "Ed Rains", false],
    [2080, "Rakocevic", "Igor", "Igor Rakocevic", false],
    [77905, "Rambis", "Kurt", "Kurt Rambis", false],
    [2762, "Ramos", "Peter John", "Peter John Ramos", false],
    [77906, "Ramsey", "Cal", "Cal Ramsey", false],
    [77907, "Ramsey", "Frank", "Frank Ramsey", false],
    [1630186, "Ramsey", "Jahmi'us", "Jahmi'us Ramsey", false],
    [77908, "Ramsey", "Ray", "Ray Ramsey", false],
    [1821, "Randall", "Mark", "Mark Randall", false],
    [1626184, "Randle", "Chasson", "Chasson Randle", false],
    [203944, "Randle", "Julius", "Julius Randle", true],
    [201576, "Randolph", "Anthony", "Anthony Randolph", false],
    [101183, "Randolph", "Shavlik", "Shavlik Randolph", false],
    [2216, "Randolph", "Zach", "Zach Randolph", false],
    [77910, "Rank", "Wally", "Wally Rank", false],
    [77911, "Ransey", "Kelvin", "Kelvin Ransey", false],
    [77912, "Ranzino", "Sam", "Sam Ranzino", false],
    [78656, "Rasmussen", "Blair", "Blair Rasmussen", false],
    [1628504, "Rathan-Mayes", "Xavier", "Xavier Rathan-Mayes", false],
    [77913, "Ratkovicz", "George", "George Ratkovicz", false],
    [77914, "Ratleff", "Ed", "Ed Ratleff", false],
    [77915, "Ratliff", "Mike", "Mike Ratliff", false],
    [689, "Ratliff", "Theo", "Theo Ratliff", false],
    [202360, "Rautins", "Andy", "Andy Rautins", false],
    [77916, "Rautins", "Leo", "Leo Rautins", false],
    [200810, "Ray", "Allan", "Allan Ray", false],
    [77917, "Ray", "Clifford", "Clifford Ray", false],
    [77918, "Ray", "Don", "Don Ray", false],
    [77920, "Ray", "Jamesearl", "Jamesearl Ray", false],
    [77919, "Ray", "Jim", "Jim Ray", false],
    [77921, "Raymond", "Craig", "Craig Raymond", false],
    [77922, "Rea", "Connie", "Connie Rea", false],
    [1630559, "Reaves", "Austin", "Austin Reaves", true],
    [77923, "Reaves", "Joe", "Joe Reaves", false],
    [1629729, "Reaves", "Josh", "Josh Reaves", false],
    [1442, "Rebraca", "Zeljko", "Zeljko Rebraca", false],
    [695, "Recasner", "Eldridge", "Eldridge Recasner", false],
    [2072, "Redd", "Michael", "Michael Redd", false],
    [1629629, "Reddish", "Cam", "Cam Reddish", true],
    [77925, "Reddout", "Frank", "Frank Reddout", false],
    [200755, "Redick", "JJ", "JJ Redick", false],
    [77926, "Redmond", "Marlon", "Marlon Redmond", false],
    [1628432, "Reed", "Davon", "Davon Reed", true],
    [77927, "Reed", "Hub", "Hub Reed", false],
    [2770, "Reed", "Justin", "Justin Reed", false],
    [1630194, "Reed", "Paul", "Paul Reed", true],
    [77928, "Reed", "Ron", "Ron Reed", false],
    [203186, "Reed", "Willie", "Willie Reed", false],
    [77929, "Reed", "Willis", "Willis Reed", false],
    [735, "Reeves", "Bryant", "Bryant Reeves", false],
    [234, "Reeves", "Khalid", "Khalid Reeves", false],
    [77932, "Regan", "Richie", "Richie Regan", false],
    [77933, "Rehfeldt", "Don", "Don Rehfeldt", false],
    [77937, "Reid", "Billy", "Billy Reid", false],
    [690, "Reid", "Don", "Don Reid", false],
    [462, "Reid", "J.R.", "J.R. Reid", false],
    [77935, "Reid", "Jim", "Jim Reid", false],
    [1629675, "Reid", "Naz", "Naz Reid", true],
    [77936, "Reid", "Robert", "Robert Reid", false],
    [202385, "Reid", "Ryan", "Ryan Reid", false],
    [2876, "Reiner", "Jared", "Jared Reiner", false],
    [77938, "Reiser", "Joseph", "Joseph Reiser", false],
    [77939, "Rellford", "Richard", "Richard Rellford", false],
    [720, "Rencher", "Terrence", "Terrence Rencher", false],
    [77941, "Rennicke", "John", "John Rennicke", false],
    [77942, "Rensberger", "Rob", "Rob Rensberger", false],
    [981, "Rentzias", "Efthimios", "Efthimios Rentzias", false],
    [704, "Respert", "Shawn", "Shawn Respert", false],
    [77944, "Restani", "Kevin", "Kevin Restani", false],
    [1629244, "Reynolds", "Cam", "Cam Reynolds", false],
    [77945, "Reynolds", "George", "George Reynolds", false],
    [77946, "Reynolds", "Jerry", "Jerry Reynolds", false],
    [1631197, "Rhoden", "Jared", "Jared Rhoden", true],
    [77947, "Rhodes", "Gene", "Gene Rhodes", false],
    [1518, "Rhodes", "Rodrick", "Rodrick Rhodes", false],
    [779, "Rice", "Glen", "Glen Rice", false],
    [203318, "Rice", "Glen", "Glen Rice", false],
    [201181, "Richard", "Chris", "Chris Richard", false],
    [1630208, "Richards", "Nick", "Nick Richards", true],
    [77950, "Richardson", "Clint", "Clint Richardson", false],
    [2202, "Richardson", "Jason", "Jason Richardson", false],
    [200978, "Richardson", "Jeremy", "Jeremy Richardson", false],
    [1626196, "Richardson", "Josh", "Josh Richardson", true],
    [1627781, "Richardson", "Malachi", "Malachi Richardson", false],
    [77952, "Richardson", "Micheal Ray", "Micheal Ray Richardson", false],
    [2369, "Richardson", "Norman", "Norman Richardson", false],
    [71, "Richardson", "Pooh", "Pooh Richardson", false],
    [2047, "Richardson", "Quentin", "Quentin Richardson", false],
    [782, "Richmond", "Mitch", "Mitch Richmond", false],
    [77954, "Richter", "John", "John Richter", false],
    [77955, "Ricketts", "Dick", "Dick Ricketts", false],
    [375, "Rider", "Isaiah", "Isaiah Rider", false],
    [77957, "Ridgle", "Jackie", "Jackie Ridgle", false],
    [2557, "Ridnour", "Luke", "Luke Ridnour", false],
    [77958, "Riebe", "Mel", "Mel Riebe", false],
    [77959, "Riffey", "Jim", "Jim Riffey", false],
    [2541, "Rigaudeau", "Antoine", "Antoine Rigaudeau", false],
    [77960, "Riker", "Tom", "Tom Riker", false],
    [77963, "Riley", "Bob", "Bob Riley", false],
    [35, "Riley", "Eric", "Eric Riley", false],
    [77962, "Riley", "Pat", "Pat Riley", false],
    [77964, "Riley", "Ron", "Ron Riley", false],
    [1630203, "Riller", "Grant", "Grant Riller", false],
    [77965, "Rinaldi", "Rich", "Rich Rinaldi", false],
    [77966, "Riordan", "Mike", "Mike Riordan", false],
    [77967, "Risen", "Arnie", "Arnie Risen", false],
    [77968, "Ritter", "Goebel", "Goebel Ritter", false],
    [77969, "Rivas", "Ramon", "Ramon Rivas", false],
    [203085, "Rivers", "Austin", "Austin Rivers", true],
    [77970, "Rivers", "David", "David Rivers", false],
    [470, "Rivers", "Doc", "Doc Rivers", false],
    [77972, "Robbins", "Lee", "Lee Robbins", false],
    [203460, "Roberson", "Andre", "Andre Roberson", false],
    [101194, "Roberson", "Anthony", "Anthony Roberson", false],
    [77973, "Roberson", "Rick", "Rick Roberson", false],
    [2121, "Roberson", "Terrance", "Terrance Roberson", false],
    [77975, "Roberts", "Anthony", "Anthony Roberts", false],
    [77979, "Roberts", "Bill", "Bill Roberts", false],
    [203148, "Roberts", "Brian", "Brian Roberts", false],
    [122, "Roberts", "Fred", "Fred Roberts", false],
    [77977, "Roberts", "Joe", "Joe Roberts", false],
    [101160, "Roberts", "Lawrence", "Lawrence Roberts", false],
    [77978, "Roberts", "Marv", "Marv Roberts", false],
    [914, "Roberts", "Stanley", "Stanley Roberts", false],
    [926, "Robertson", "Alvin", "Alvin Robertson", false],
    [600015, "Robertson", "Oscar", "Oscar Robertson", false],
    [1926, "Robertson", "Ryan", "Ryan Robertson", false],
    [77983, "Robertson", "Tony", "Tony Robertson", false],
    [77984, "Robey", "Rick", "Rick Robey", false],
    [2774, "Robinson", "Bernard", "Bernard Robinson", false],
    [997, "Robinson", "Chris", "Chris Robinson", false],
    [77986, "Robinson", "Cliff T.", "Cliff T. Robinson", false],
    [361, "Robinson", "Clifford", "Clifford Robinson", false],
    [764, "Robinson", "David", "David Robinson", false],
    [1628421, "Robinson", "Devin", "Devin Robinson", false],
    [1629130, "Robinson", "Duncan", "Duncan Robinson", true],
    [1944, "Robinson", "Eddie", "Eddie Robinson", false],
    [77988, "Robinson", "Flynn", "Flynn Robinson", false],
    [299, "Robinson", "Glenn", "Glenn Robinson", false],
    [77989, "Robinson", "Jackie", "Jackie Robinson", false],
    [1554, "Robinson", "Jamal", "Jamal Robinson", false],
    [381, "Robinson", "James", "James Robinson", false],
    [1629010, "Robinson", "Jerome", "Jerome Robinson", false],
    [1629620, "Robinson", "Justin", "Justin Robinson", false],
    [1683, "Robinson", "Larry", "Larry Robinson", false],
    [1629011, "Robinson", "Mitchell", "Mitchell Robinson", true],
    [101126, "Robinson", "Nate", "Nate Robinson", false],
    [77994, "Robinson", "Oliver", "Oliver Robinson", false],
    [1631115, "Robinson", "Orlando", "Orlando Robinson", true],
    [716, "Robinson", "Rumeal", "Rumeal Robinson", false],
    [203080, "Robinson", "Thomas", "Thomas Robinson", false],
    [77993, "Robinson", "Truck", "Truck Robinson", false],
    [77996, "Robinson", "Wayne", "Wayne Robinson", false],
    [203922, "Robinson III", "Glenn", "Glenn Robinson III", false],
    [1630526, "Robinson-Earl", "Jeremiah", "Jeremiah Robinson-Earl", true],
    [77997, "Robinzine", "Bill", "Bill Robinzine", false],
    [77998, "Robisch", "Dave", "Dave Robisch", false],
    [1629676, "Roby", "Isaiah", "Isaiah Roby", true],
    [77999, "Rocha", "Red", "Red Rocha", false],
    [78000, "Roche", "John", "John Roche", false],
    [78001, "Rock", "Gene", "Gene Rock", false],
    [78002, "Rocker", "Jack", "Jack Rocker", false],
    [1631223, "Roddy", "David", "David Roddy", true],
    [78003, "Rodgers", "Guy", "Guy Rodgers", false],
    [23, "Rodman", "Dennis", "Dennis Rodman", false],
    [200771, "Rodriguez", "Sergio", "Sergio Rodriguez", false],
    [691, "Roe", "Lou", "Lou Roe", false],
    [435, "Rogers", "Carlos", "Carlos Rogers", false],
    [78007, "Rogers", "Johnny", "Johnny Rogers", false],
    [78008, "Rogers", "Marshall", "Marshall Rogers", false],
    [915, "Rogers", "Rodney", "Rodney Rogers", false],
    [964, "Rogers", "Roy", "Roy Rogers", false],
    [78010, "Roges", "Al", "Al Roges", false],
    [78011, "Rohloff", "Ken", "Ken Rohloff", false],
    [202375, "Rolle", "Magnum", "Magnum Rolle", false],
    [78012, "Rollins", "Kenny", "Kenny Rollins", false],
    [78013, "Rollins", "Phil", "Phil Rollins", false],
    [1631157, "Rollins", "Ryan", "Ryan Rollins", true],
    [78014, "Rollins", "Tree", "Tree Rollins", false],
    [78015, "Romar", "Lorenzo", "Lorenzo Romar", false],
    [200765, "Rondo", "Rajon", "Rajon Rondo", false],
    [399, "Rooks", "Sean", "Sean Rooks", false],
    [201565, "Rose", "Derrick", "Derrick Rose", true],
    [147, "Rose", "Jalen", "Jalen Rose", false],
    [990, "Rose", "Malik", "Malik Rose", false],
    [78016, "Rose", "Robert", "Robert Rose", false],
    [78017, "Rosenberg", "Alexander", "Alexander Rosenberg", false],
    [78018, "Rosenbluth", "Lennie", "Lennie Rosenbluth", false],
    [78019, "Rosenstein", "Hank", "Hank Rosenstein", false],
    [78020, "Rosenthal", "Dick", "Dick Rosenthal", false],
    [2624, "Ross", "Quinton", "Quinton Ross", false],
    [203082, "Ross", "Terrence", "Terrence Ross", true],
    [78021, "Roth", "Doug", "Doug Roth", false],
    [78022, "Roth", "Scott", "Scott Roth", false],
    [78023, "Rothenberg", "Irv", "Irv Rothenberg", false],
    [78024, "Rottner", "Mickey", "Mickey Rottner", false],
    [78025, "Roundfield", "Dan", "Dan Roundfield", false],
    [78026, "Roux", "Giff", "Giff Roux", false],
    [78027, "Rowan", "Ron", "Ron Rowan", false],
    [78028, "Rowe", "Curtis", "Curtis Rowe", false],
    [78029, "Rowinski", "Jim", "Jim Rowinski", false],
    [78030, "Rowland", "Derrick", "Derrick Rowland", false],
    [78031, "Rowsom", "Brian", "Brian Rowsom", false],
    [200750, "Roy", "Brandon", "Brandon Roy", false],
    [140, "Royal", "Donald", "Donald Royal", false],
    [78033, "Royer", "Bob", "Bob Royer", false],
    [32, "Rozier", "Clifford", "Clifford Rozier", false],
    [1626179, "Rozier", "Terry", "Terry Rozier", true],
    [201937, "Rubio", "Ricky", "Ricky Rubio", true],
    [2462, "Rucker", "Guy", "Guy Rucker", false],
    [78035, "Rudd", "Delaney", "Delaney Rudd", false],
    [78036, "Rudd", "John", "John Rudd", false],
    [204014, "Rudez", "Damjan", "Damjan Rudez", false],
    [78037, "Rudometkin", "John", "John Rudometkin", false],
    [1913, "Ruffin", "Michael", "Michael Ruffin", false],
    [460, "Ruffin", "Trevor", "Trevor Ruffin", false],
    [78039, "Ruffner", "Paul", "Paul Ruffner", false],
    [78040, "Ruklick", "Joe", "Joe Ruklick", false],
    [78041, "Ruland", "Jeff", "Jeff Ruland", false],
    [78042, "Rule", "Bob", "Bob Rule", false],
    [78043, "Rullo", "Jerry", "Jerry Rullo", false],
    [474, "Rusconi", "Stefano", "Stefano Rusconi", false],
    [201575, "Rush", "Brandon", "Brandon Rush", false],
    [2416, "Rush", "Kareem", "Kareem Rush", false],
    [78049, "Russell", "Bill", "Bill Russell", false],
    [935, "Russell", "Bryon", "Bryon Russell", false],
    [78047, "Russell", "Campy", "Campy Russell", false],
    [78045, "Russell", "Cazzie", "Cazzie Russell", false],
    [1626156, "Russell", "D'Angelo", "D'Angelo Russell", true],
    [78046, "Russell", "Frank", "Frank Russell", false],
    [78048, "Russell", "Walker", "Walker Russell", false],
    [201041, "Russell", "Walker", "Walker Russell", false],
    [1630346, "Ryan", "Matt", "Matt Ryan", true],
    [717, "Sabonis", "Arvydas", "Arvydas Sabonis", false],
    [1627734, "Sabonis", "Domantas", "Domantas Sabonis", true],
    [203135, "Sacre", "Robert", "Robert Sacre", false],
    [78050, "Sadowski", "Ed", "Ed Sadowski", false],
    [78051, "Sailors", "Kenny", "Kenny Sailors", false],
    [927, "Salley", "John", "John Salley", false],
    [2422, "Salmons", "John", "John Salmons", false],
    [1089, "Salvadori", "Kevin", "Kevin Salvadori", false],
    [2066, "Samake", "Soumaila", "Soumaila Samake", false],
    [1629677, "Samanic", "Luka", "Luka Samanic", false],
    [200798, "Samb", "Cheikh", "Cheikh Samb", false],
    [1629102, "Sampson", "Brandon", "Brandon Sampson", false],
    [203960, "Sampson", "JaKarr", "JaKarr Sampson", false],
    [2441, "Sampson", "Jamal", "Jamal Sampson", false],
    [78055, "Sampson", "Ralph", "Ralph Sampson", false],
    [202396, "Samuels", "Samardo", "Samardo Samuels", false],
    [2143, "Sanchez", "Pepe", "Pepe Sanchez", false],
    [78057, "Sanders", "Frankie", "Frankie Sanders", false],
    [1874, "Sanders", "Jeff", "Jeff Sanders", false],
    [202336, "Sanders", "Larry", "Larry Sanders", false],
    [2684, "Sanders", "Melvin", "Melvin Sanders", false],
    [78059, "Sanders", "Mike", "Mike Sanders", false],
    [78060, "Sanders", "Thomas", "Thomas Sanders", false],
    [2091, "Santiago", "Daniel", "Daniel Santiago", false],
    [78061, "Santini", "Bob", "Bob Santini", false],
    [78062, "Sappleton", "Wayne", "Wayne Sappleton", false],
    [203967, "Saric", "Dario", "Dario Saric", true],
    [1630846, "Sarr", "Olivier", "Olivier Sarr", true],
    [987, "Sasser", "Jason", "Jason Sasser", false],
    [2219, "Sasser", "Jeryl", "Jeryl Sasser", false],
    [203107, "Satoransky", "Tomas", "Tomas Satoransky", false],
    [2261, "Satterfield", "Kenny", "Kenny Satterfield", false],
    [78063, "Saul", "Frank", "Frank Saul", false],
    [78064, "Sauldsberry", "Woody", "Woody Sauldsberry", false],
    [78065, "Saunders", "Fred", "Fred Saunders", false],
    [78066, "Savage", "Don", "Don Savage", false],
    [2453, "Savovic", "Predrag", "Predrag Savovic", false],
    [78067, "Sawyer", "Alan", "Alan Sawyer", false],
    [2243, "Scalabrine", "Brian", "Brian Scalabrine", false],
    [2637, "Scales", "Alex", "Alex Scales", false],
    [78068, "Scales", "Dewayne", "Dewayne Scales", false],
    [78069, "Schade", "Frank", "Frank Schade", false],
    [78070, "Schadler", "Ben", "Ben Schadler", false],
    [78071, "Schaefer", "Herm", "Herm Schaefer", false],
    [78072, "Schafer", "Bob", "Bob Schafer", false],
    [1630648, "Schakel", "Jordan", "Jordan Schakel", true],
    [78073, "Scharnus", "Ben", "Ben Scharnus", false],
    [78074, "Schatzman", "Marv", "Marv Schatzman", false],
    [78075, "Schaus", "Fred", "Fred Schaus", false],
    [7, "Schayes", "Dan", "Dan Schayes", false],
    [78076, "Schayes", "Dolph", "Dolph Schayes", false],
    [78078, "Schectman", "Ossie", "Ossie Schectman", false],
    [88, "Scheffler", "Steve", "Steve Scheffler", false],
    [78080, "Scheffler", "Tom", "Tom Scheffler", false],
    [78081, "Schellhase", "Dave", "Dave Schellhase", false],
    [101195, "Schenscher", "Luke", "Luke Schenscher", false],
    [78082, "Scherer", "Herb", "Herb Scherer", false],
    [368, "Schintzius", "Dwayne", "Dwayne Schintzius", false],
    [78084, "Schlueter", "Dale", "Dale Schlueter", false],
    [78085, "Schnellbacher", "Otto", "Otto Schnellbacher", false],
    [78086, "Schnittker", "Dick", "Dick Schnittker", false],
    [78087, "Schoene", "Russ", "Russ Schoene", false],
    [1629678, "Schofield", "Admiral", "Admiral Schofield", true],
    [78088, "Scholz", "Dave", "Dave Scholz", false],
    [78089, "Schoon", "Milt", "Milt Schoon", false],
    [96, "Schrempf", "Detlef", "Detlef Schrempf", false],
    [203471, "Schroder", "Dennis", "Dennis Schroder", true],
    [78091, "Schultz", "Howie", "Howie Schultz", false],
    [78092, "Schulz", "Dick", "Dick Schulz", false],
    [78093, "Schweitz", "John", "John Schweitz", false],
    [2449, "Scola", "Luis", "Luis Scola", false],
    [78094, "Scolari", "Freddie", "Freddie Scolari", false],
    [78095, "Scott", "Alvin", "Alvin Scott", false],
    [1064, "Scott", "Brent", "Brent Scott", false],
    [2, "Scott", "Byron", "Byron Scott", false],
    [78097, "Scott", "Charlie", "Charlie Scott", false],
    [192, "Scott", "Dennis", "Dennis Scott", false],
    [1109, "Scott", "James", "James Scott", false],
    [203118, "Scott", "Mike", "Mike Scott", false],
    [78100, "Scott", "Ray", "Ray Scott", false],
    [1035, "Scott", "Shawnelle", "Shawnelle Scott", false],
    [1630286, "Scott", "Trevon", "Trevon Scott", false],
    [1630206, "Scrubb", "Jay", "Jay Scrubb", false],
    [78102, "Scurry", "Carey", "Carey Scurry", false],
    [1631220, "Seabron", "Dereon", "Dereon Seabron", true],
    [78103, "Seals", "Bruce", "Bruce Seals", false],
    [1578, "Seals", "Shea", "Shea Seals", false],
    [907, "Sealy", "Malik", "Malik Sealy", false],
    [78105, "Searcy", "Ed", "Ed Searcy", false],
    [78106, "Sears", "Ken", "Ken Sears", false],
    [78107, "See", "Wayne", "Wayne See", false],
    [200757, "Sefolosha", "Thabo", "Thabo Sefolosha", false],
    [938, "Seikaly", "Rony", "Rony Seikaly", false],
    [78109, "Selbo", "Glen", "Glen Selbo", false],
    [202729, "Selby", "Josh", "Josh Selby", false],
    [1627782, "Selden", "Wayne", "Wayne Selden", false],
    [78110, "Sellers", "Brad", "Brad Sellers", false],
    [78111, "Sellers", "Phil", "Phil Sellers", false],
    [78112, "Seltz", "Rollie", "Rollie Seltz", false],
    [78113, "Selvy", "Frank", "Frank Selvy", false],
    [78114, "Seminoff", "Jim", "Jim Seminoff", false],
    [200754, "Sene", "Mouhamed", "Mouhamed Sene", false],
    [78115, "Senesky", "George", "George Senesky", false],
    [1630578, "Sengun", "Alperen", "Alperen Sengun", true],
    [202338, "Seraphin", "Kevin", "Kevin Seraphin", false],
    [1738, "Sesay", "Ansu", "Ansu Sesay", false],
    [201196, "Sessions", "Ramon", "Ramon Sessions", false],
    [78116, "Sewell", "Tom", "Tom Sewell", false],
    [1629012, "Sexton", "Collin", "Collin Sexton", true],
    [78117, "Seymour", "Paul", "Paul Seymour", false],
    [78118, "Shaback", "Nick", "Nick Shaback", false],
    [1593, "Shackleford", "Charles", "Charles Shackleford", false],
    [78120, "Shaeffer", "Carl", "Carl Shaeffer", false],
    [78121, "Shaffer", "Lee", "Lee Shaffer", false],
    [201203, "Shakur", "Mustafa", "Mustafa Shakur", false],
    [1629013, "Shamet", "Landry", "Landry Shamet", true],
    [1539, "Shammgod", "God", "God Shammgod", false],
    [78123, "Shannon", "Earl", "Earl Shannon", false],
    [78124, "Shannon", "Howie", "Howie Shannon", false],
    [78125, "Share", "Charlie", "Charlie Share", false],
    [78126, "Sharman", "Bill", "Bill Sharman", false],
    [1630549, "Sharpe", "Day'Ron", "Day'Ron Sharpe", true],
    [1631101, "Sharpe", "Shaedon", "Shaedon Sharpe", true],
    [201594, "Sharpe", "Walter", "Walter Sharpe", false],
    [1117, "Shasky", "John", "John Shasky", false],
    [78128, "Shavlik", "Ron", "Ron Shavlik", false],
    [216, "Shaw", "Brian", "Brian Shaw", false],
    [1745, "Shaw", "Casey", "Casey Shaw", false],
    [1629621, "Shayok", "Marial", "Marial Shayok", false],
    [78130, "Shea", "Bob", "Bob Shea", false],
    [78131, "Sheffield", "Fred", "Fred Sheffield", false],
    [78132, "Shelton", "Craig", "Craig Shelton", false],
    [78133, "Shelton", "Lonnie", "Lonnie Shelton", false],
    [203129, "Shengelia", "Tornike", "Tornike Shengelia", false],
    [1852, "Sheppard", "Jeff", "Jeff Sheppard", false],
    [78135, "Sheppard", "Steve", "Steve Sheppard", false],
    [78136, "Sherod", "Edmund", "Edmund Sherod", false],
    [78653, "Shipp", "Charlie", "Charlie Shipp", false],
    [2321, "Shirley", "Paul", "Paul Shirley", false],
    [78138, "Short", "Gene", "Gene Short", false],
    [78139, "Short", "Purvis", "Purvis Short", false],
    [78140, "Shouse", "Dexter", "Dexter Shouse", false],
    [78141, "Shrider", "Dick", "Dick Shrider", false],
    [78142, "Shue", "Gene", "Gene Shue", false],
    [78143, "Shumate", "John", "John Shumate", false],
    [202697, "Shumpert", "Iman", "Iman Shumpert", false],
    [203144, "Shved", "Alexey", "Alexey Shved", false],
    [1627783, "Siakam", "Pascal", "Pascal Siakam", true],
    [1626296, "Sibert", "Jordan", "Jordan Sibert", false],
    [78144, "Sibert", "Sam", "Sam Sibert", false],
    [78145, "Sibley", "Mark", "Mark Sibley", false],
    [78146, "Sichting", "Jerry", "Jerry Sichting", false],
    [78147, "Siegfried", "Larry", "Larry Siegfried", false],
    [78148, "Siewert", "Ralph", "Ralph Siewert", false],
    [78149, "Sikma", "Jack", "Jack Sikma", false],
    [78150, "Silas", "James", "James Silas", false],
    [78151, "Silas", "Paul", "Paul Silas", false],
    [202918, "Silas", "Xavier", "Xavier Silas", false],
    [202081, "Siler", "Garret", "Garret Siler", false],
    [78152, "Silliman", "Mike", "Mike Silliman", false],
    [1629735, "Silva", "Chris", "Chris Silva", true],
    [101134, "Simien", "Wayne", "Wayne Simien", false],
    [1627732, "Simmons", "Ben", "Ben Simmons", true],
    [2250, "Simmons", "Bobby", "Bobby Simmons", false],
    [200759, "Simmons", "Cedric", "Cedric Simmons", false],
    [78153, "Simmons", "Connie", "Connie Simmons", false],
    [78154, "Simmons", "Johnny", "Johnny Simmons", false],
    [203613, "Simmons", "Jonathon", "Jonathon Simmons", false],
    [1628424, "Simmons", "Kobi", "Kobi Simmons", false],
    [1489, "Simmons", "Lionel", "Lionel Simmons", false],
    [1750, "Simon", "Miles", "Miles Simon", false],
    [1630250, "Simonovic", "Marko", "Marko Simonovic", true],
    [1629014, "Simons", "Anfernee", "Anfernee Simons", true],
    [54, "Simpkins", "Dickey", "Dickey Simpkins", false],
    [202067, "Simpson", "Diamon", "Diamon Simpson", false],
    [78157, "Simpson", "Ralph", "Ralph Simpson", false],
    [1630285, "Simpson", "Zavier", "Zavier Simpson", false],
    [1591, "Sims", "Alvin", "Alvin Sims", false],
    [78160, "Sims", "Bob", "Bob Sims", false],
    [201235, "Sims", "Courtney", "Courtney Sims", false],
    [78159, "Sims", "Doug", "Doug Sims", false],
    [203156, "Sims", "Henry", "Henry Sims", false],
    [1630579, "Sims", "Jericho", "Jericho Sims", true],
    [78161, "Sims", "Scott", "Scott Sims", false],
    [202713, "Singler", "Kyle", "Kyle Singler", false],
    [201606, "Singletary", "Sean", "Sean Singletary", false],
    [202698, "Singleton", "Chris", "Chris Singleton", false],
    [101189, "Singleton", "James", "James Singleton", false],
    [78162, "Singleton", "Mckinley", "Mckinley Singleton", false],
    [78163, "Sinicola", "Zeke", "Zeke Sinicola", false],
    [1629686, "Sirvydis", "Deividas", "Deividas Sirvydis", false],
    [78164, "Sitton", "Charlie", "Charlie Sitton", false],
    [203491, "Siva", "Peyton", "Peyton Siva", false],
    [101, "Skiles", "Scott", "Scott Skiles", false],
    [78167, "Skinner", "Al", "Al Skinner", false],
    [1730, "Skinner", "Brian", "Brian Skinner", false],
    [78168, "Skinner", "Talvin", "Talvin Skinner", false],
    [78169, "Skoog", "Whitey", "Whitey Skoog", false],
    [78170, "Slade", "Jeff", "Jeff Slade", false],
    [1073, "Slater", "Reggie", "Reggie Slater", false],
    [78171, "Slaughter", "Jim", "Jim Slaughter", false],
    [78172, "Slaughter", "Jose", "Jose Slaughter", false],
    [2447, "Slay", "Tamar", "Tamar Slay", false],
    [202388, "Sloan", "Donald", "Donald Sloan", false],
    [78173, "Sloan", "Jerry", "Jerry Sloan", false],
    [101166, "Slokar", "Uros", "Uros Slokar", false],
    [78174, "Sluby", "Tom", "Tom Sluby", false],
    [1629346, "Smailagic", "Alen", "Alen Smailagic", false],
    [1630606, "Smart", "Javonte", "Javonte Smart", false],
    [78175, "Smart", "Keith", "Keith Smart", false],
    [203935, "Smart", "Marcus", "Marcus Smart", true],
    [78176, "Smawley", "Belus", "Belus Smawley", false],
    [78177, "Smiley", "Jack", "Jack Smiley", false],
    [78178, "Smith", "Adrian", "Adrian Smith", false],
    [78207, "Smith", "Bill", "Bill Smith", false],
    [78209, "Smith", "Bill", "Bill Smith", false],
    [78202, "Smith", "Bingo", "Bingo Smith", false],
    [78203, "Smith", "Bobby", "Bobby Smith", false],
    [293, "Smith", "Charles", "Charles Smith", false],
    [1520, "Smith", "Charles", "Charles Smith", false],
    [78179, "Smith", "Charles", "Charles Smith", false],
    [1814, "Smith", "Chris", "Chris Smith", false],
    [203147, "Smith", "Chris", "Chris Smith", false],
    [78181, "Smith", "Clinton", "Clinton Smith", false],
    [200783, "Smith", "Craig", "Craig Smith", false],
    [78182, "Smith", "Deb", "Deb Smith", false],
    [78183, "Smith", "Derek", "Derek Smith", false],
    [78184, "Smith", "Don", "Don Smith", false],
    [78185, "Smith", "Donald", "Donald Smith", false],
    [2764, "Smith", "Donta", "Donta Smith", false],
    [475, "Smith", "Doug", "Doug Smith", false],
    [1630696, "Smith", "Dru", "Dru Smith", true],
    [78187, "Smith", "Ed", "Ed Smith", false],
    [78188, "Smith", "Elmore", "Elmore Smith", false],
    [78189, "Smith", "Garfield", "Garfield Smith", false],
    [78190, "Smith", "Greg", "Greg Smith", false],
    [202962, "Smith", "Greg", "Greg Smith", false],
    [202397, "Smith", "Ish", "Ish Smith", true],
    [2747, "Smith", "JR", "JR Smith", false],
    [2074, "Smith", "Jabari", "Jabari Smith", false],
    [1630188, "Smith", "Jalen", "Jalen Smith", true],
    [201160, "Smith", "Jason", "Jason Smith", false],
    [202536, "Smith", "Jerry", "Jerry Smith", false],
    [78191, "Smith", "Jim", "Jim Smith", false],
    [693, "Smith", "Joe", "Joe Smith", false],
    [2746, "Smith", "Josh", "Josh Smith", false],
    [78192, "Smith", "Keith", "Keith Smith", false],
    [181, "Smith", "Kenny", "Kenny Smith", false],
    [78194, "Smith", "Labradford", "Labradford Smith", false],
    [78195, "Smith", "Larry", "Larry Smith", false],
    [1910, "Smith", "Leon", "Leon Smith", false],
    [63, "Smith", "Michael", "Michael Smith", false],
    [78197, "Smith", "Michael", "Michael Smith", false],
    [2065, "Smith", "Mike", "Mike Smith", false],
    [202701, "Smith", "Nolan", "Nolan Smith", false],
    [78198, "Smith", "Otis", "Otis Smith", false],
    [78199, "Smith", "Phil", "Phil Smith", false],
    [78200, "Smith", "Randy", "Randy Smith", false],
    [78201, "Smith", "Reggie", "Reggie Smith", false],
    [78204, "Smith", "Robert", "Robert Smith", false],
    [203893, "Smith", "Russ", "Russ Smith", false],
    [78205, "Smith", "Sammy", "Sammy Smith", false],
    [120, "Smith", "Steven", "Steven Smith", false],
    [200848, "Smith", "Steven", "Steven Smith", false],
    [1478, "Smith", "Stevin", "Stevin Smith", false],
    [2604, "Smith", "Theron", "Theron Smith", false],
    [380, "Smith", "Tony", "Tony Smith", false],
    [78208, "Smith", "Willie", "Willie Smith", false],
    [1629015, "Smith", "Zhaire", "Zhaire Smith", false],
    [1628372, "Smith Jr.", "Dennis", "Dennis Smith Jr.", true],
    [1631095, "Smith Jr.", "Jabari", "Jabari Smith Jr.", true],
    [22, "Smits", "Rik", "Rik Smits", false],
    [1091, "Smrek", "Mike", "Mike Smrek", false],
    [78212, "Smyth", "Joe", "Joe Smyth", false],
    [1630270, "Sneed", "Xavier", "Xavier Sneed", false],
    [203503, "Snell", "Tony", "Tony Snell", false],
    [727, "Snow", "Eric", "Eric Snow", false],
    [78213, "Snyder", "Dick", "Dick Snyder", false],
    [2745, "Snyder", "Kirk", "Kirk Snyder", false],
    [78214, "Sobek", "George", "George Sobek", false],
    [78215, "Sobers", "Ricky", "Ricky Sobers", false],
    [78216, "Sobie", "Ron", "Ron Sobie", false],
    [1631110, "Sochan", "Jeremy", "Jeremy Sochan", true],
    [78217, "Sojourner", "Mike", "Mike Sojourner", false],
    [2226, "Solomon", "Will", "Will Solomon", false],
    [78219, "Somerset", "Willie", "Willie Somerset", false],
    [2443, "Songaila", "Darius", "Darius Songaila", false],
    [78220, "Sorenson", "Dave", "Dave Sorenson", false],
    [203480, "Southerland", "James", "James Southerland", false],
    [78221, "Sovran", "Gino", "Gino Sovran", false],
    [2776, "Sow", "Pape", "Pape Sow", false],
    [1629034, "Spalding", "Ray", "Ray Spalding", false],
    [78222, "Spanarkel", "Jim", "Jim Spanarkel", false],
    [2779, "Spanoulis", "Vassilis", "Vassilis Spanoulis", false],
    [78223, "Sparrow", "Guy", "Guy Sparrow", false],
    [600009, "Sparrow", "Rory", "Rory Sparrow", false],
    [78225, "Spears", "Odie", "Odie Spears", false],
    [78226, "Spector", "Art", "Art Spector", false],
    [201578, "Speights", "Marreese", "Marreese Speights", false],
    [1629016, "Spellman", "Omari", "Omari Spellman", false],
    [78227, "Spencer", "Andre", "Andre Spencer", false],
    [771, "Spencer", "Elmore", "Elmore Spencer", false],
    [280, "Spencer", "Felton", "Felton Spencer", false],
    [78230, "Spicer", "Lou", "Lou Spicer", false],
    [78231, "Spitzer", "Craig", "Craig Spitzer", false],
    [201168, "Splitter", "Tiago", "Tiago Splitter", false],
    [78232, "Spoelstra", "Art", "Art Spoelstra", false],
    [84, "Sprewell", "Latrell", "Latrell Sprewell", false],
    [78233, "Spriggs", "Larry", "Larry Spriggs", false],
    [1630531, "Springer", "Jaden", "Jaden Springer", true],
    [78234, "Springer", "Jim", "Jim Springer", false],
    [78235, "Spruill", "Jim", "Jim Spruill", false],
    [1756, "Stack", "Ryan", "Ryan Stack", false],
    [711, "Stackhouse", "Jerry", "Jerry Stackhouse", false],
    [78237, "Stacom", "Kevin", "Kevin Stacom", false],
    [78238, "Stallworth", "Dave", "Dave Stallworth", false],
    [78239, "Stallworth", "Isaac", "Isaac Stallworth", false],
    [78240, "Stanczak", "Ed", "Ed Stanczak", false],
    [1630199, "Stanley", "Cassius", "Cassius Stanley", false],
    [78241, "Stansbury", "Terence", "Terence Stansbury", false],
    [317, "Starks", "John", "John Starks", false],
    [78243, "Starr", "Keith", "Keith Starr", false],
    [203917, "Stauskas", "Nik", "Nik Stauskas", false],
    [78244, "Staverman", "Larry", "Larry Staverman", false],
    [78245, "Steele", "Larry", "Larry Steele", false],
    [1099, "Steigenga", "Matt", "Matt Steigenga", false],
    [1735, "Stepania", "Vladimir", "Vladimir Stepania", false],
    [203474, "Stephens", "DJ", "DJ Stephens", false],
    [78247, "Stephens", "Everette", "Everette Stephens", false],
    [78249, "Stephens", "Jack", "Jack Stephens", false],
    [1086, "Stephens", "Joe", "Joe Stephens", false],
    [202362, "Stephenson", "Lance", "Lance Stephenson", false],
    [1627293, "Stepheson", "Alex", "Alex Stepheson", false],
    [78250, "Steppe", "Brook", "Brook Steppe", false],
    [78251, "Stevens", "Barry", "Barry Stevens", false],
    [1630205, "Stevens", "Lamar", "Lamar Stevens", true],
    [78252, "Stevens", "Wayne", "Wayne Stevens", false],
    [2052, "Stevenson", "DeShawn", "DeShawn Stevenson", false],
    [1630597, "Stewart", "DJ", "DJ Stewart", false],
    [78253, "Stewart", "Dennis", "Dennis Stewart", false],
    [1630191, "Stewart", "Isaiah", "Isaiah Stewart", true],
    [1529, "Stewart", "Kebu", "Kebu Stewart", false],
    [1125, "Stewart", "Larry", "Larry Stewart", false],
    [1565, "Stewart", "Michael", "Michael Stewart", false],
    [78256, "Stewart", "Norm", "Norm Stewart", false],
    [201880, "Stiemsma", "Greg", "Greg Stiemsma", false],
    [78257, "Stipanovich", "Steve", "Steve Stipanovich", false],
    [179, "Stith", "Bryant", "Bryant Stith", false],
    [78259, "Stith", "Sam", "Sam Stith", false],
    [78260, "Stith", "Tom", "Tom Stith", false],
    [78261, "Stivrins", "Alex", "Alex Stivrins", false],
    [204065, "Stockton", "David", "David Stockton", false],
    [304, "Stockton", "John", "John Stockton", false],
    [978, "Stojakovic", "Peja", "Peja Stojakovic", false],
    [1055, "Stokes", "Ed", "Ed Stokes", false],
    [78264, "Stokes", "Greg", "Greg Stokes", false],
    [203950, "Stokes", "Jarnell", "Jarnell Stokes", false],
    [600016, "Stokes", "Maurice", "Maurice Stokes", false],
    [78266, "Stolkey", "Art", "Art Stolkey", false],
    [1627754, "Stone", "Diamond", "Diamond Stone", false],
    [202933, "Stone", "Julyan", "Julyan Stone", false],
    [2852, "Storey", "Awvee", "Awvee Storey", false],
    [757, "Stoudamire", "Damon", "Damon Stoudamire", false],
    [101136, "Stoudamire", "Salim", "Salim Stoudamire", false],
    [2405, "Stoudemire", "Amar'e", "Amar'e Stoudemire", false],
    [78267, "Stovall", "Paul", "Paul Stovall", false],
    [201199, "Strawberry", "DJ", "DJ Strawberry", false],
    [78268, "Strawder", "Joe", "Joe Strawder", false],
    [78269, "Stricker", "Bill", "Bill Stricker", false],
    [1065, "Strickland", "Erick", "Erick Strickland", false],
    [1110, "Strickland", "Mark", "Mark Strickland", false],
    [393, "Strickland", "Rod", "Rod Strickland", false],
    [78270, "Strickland", "Roger", "Roger Strickland", false],
    [78271, "Stroeder", "John", "John Stroeder", false],
    [400, "Strong", "Derek", "Derek Strong", false],
    [78273, "Strothers", "Lamont", "Lamont Strothers", false],
    [78274, "Stroud", "John", "John Stroud", false],
    [1629622, "Strus", "Max", "Max Strus", true],
    [201155, "Stuckey", "Rodney", "Rodney Stuckey", false],
    [78275, "Stump", "Gene", "Gene Stump", false],
    [78276, "Stutz", "Stan", "Stan Stutz", false],
    [1630591, "Suggs", "Jalen", "Jalen Suggs", true],
    [78277, "Suiter", "Gary", "Gary Suiter", false],
    [203096, "Sullinger", "Jared", "Jared Sullinger", false],
    [201969, "Summers", "DaJuan", "DaJuan Summers", false],
    [1628410, "Sumner", "Edmond", "Edmond Sumner", true],
    [78654, "Sumpter", "Barry", "Barry Sumpter", false],
    [201180, "Sun Yue", "", "Sun Yue", false],
    [78278, "Sunderlage", "Don", "Don Sunderlage", false],
    [1743, "Sundov", "Bruno", "Bruno Sundov", false],
    [78279, "Sundvold", "Jon", "Jon Sundvold", false],
    [682, "Sura", "Bob", "Bob Sura", false],
    [78280, "Surhoff", "Dick", "Dick Surhoff", false],
    [78281, "Suttle", "Dane", "Dane Suttle", false],
    [357, "Sutton", "Greg", "Greg Sutton", false],
    [78283, "Swain", "Bennie", "Bennie Swain", false],
    [1628403, "Swanigan", "Caleb", "Caleb Swanigan", false],
    [78284, "Swanson", "Norm", "Norm Swanson", false],
    [78285, "Swartz", "Dan", "Dan Swartz", false],
    [2552, "Sweetney", "Michael", "Michael Sweetney", false],
    [1631306, "Swider", "Cole", "Cole Swider", true],
    [2741, "Swift", "Robert", "Robert Swift", false],
    [2031, "Swift", "Stromile", "Stromile Swift", false],
    [78286, "Swinson", "Aaron", "Aaron Swinson", false],
    [1628591, "Sword", "Craig", "Craig Sword", false],
    [202377, "Sy", "Pape", "Pape Sy", false],
    [78287, "Sydnor", "Wallace", "Wallace Sydnor", false],
    [1626208, "Sykes", "Keifer", "Keifer Sykes", false],
    [1132, "Sykes", "Larry", "Larry Sykes", false],
    [1136, "Szabo", "Brett", "Brett Szabo", false],
    [1887, "Szczerbiak", "Wally", "Wally Szczerbiak", false],
    [440, "Tabak", "Zan", "Zan Tabak", false],
    [2657, "Tabuse", "Yuta", "Yuta Tabuse", false],
    [101147, "Taft", "Chris", "Chris Taft", false],
    [78291, "Tannenbaum", "Sid", "Sid Tannenbaum", false],
    [1434, "Tarlac", "Dragan", "Dragan Tarlac", false],
    [78293, "Tarpley", "Roy", "Roy Tarpley", false],
    [1630256, "Tate", "Jae'Sean", "Jae'Sean Tate", true],
    [78294, "Tatum", "Earl", "Earl Tatum", false],
    [1628369, "Tatum", "Jayson", "Jayson Tatum", true],
    [204002, "Tavares", "Edy", "Edy Tavares", false],
    [78295, "Taylor", "Anthony", "Anthony Taylor", false],
    [78296, "Taylor", "Brian", "Brian Taylor", false],
    [101182, "Taylor", "Donell", "Donell Taylor", false],
    [78298, "Taylor", "Fred", "Fred Taylor", false],
    [1627819, "Taylor", "Isaiah", "Isaiah Taylor", false],
    [78297, "Taylor", "Jay", "Jay Taylor", false],
    [78299, "Taylor", "Jeff", "Jeff Taylor", false],
    [203106, "Taylor", "Jeffery", "Jeffery Taylor", false],
    [201966, "Taylor", "Jermaine", "Jermaine Taylor", false],
    [1511, "Taylor", "Johnny", "Johnny Taylor", false],
    [78301, "Taylor", "Leonard", "Leonard Taylor", false],
    [1508, "Taylor", "Maurice", "Maurice Taylor", false],
    [201446, "Taylor", "Mike", "Mike Taylor", false],
    [78302, "Taylor", "Roland", "Roland Taylor", false],
    [1630678, "Taylor", "Terry", "Terry Taylor", true],
    [203116, "Taylor", "Tyshawn", "Tyshawn Taylor", false],
    [78303, "Taylor", "Vince", "Vince Taylor", false],
    [78304, "Teagle", "Terry", "Terry Teagle", false],
    [201952, "Teague", "Jeff", "Jeff Teague", false],
    [203104, "Teague", "Marquis", "Marquis Teague", false],
    [203141, "Teletovic", "Mirza", "Mirza Teletovic", false],
    [2742, "Telfair", "Sebastian", "Sebastian Telfair", false],
    [202066, "Temple", "Garrett", "Garrett Temple", true],
    [1628462, "Teodosic", "Milos", "Milos Teodosic", false],
    [78305, "Terrell", "Ira", "Ira Terrell", false],
    [1629123, "Terrell", "Jared", "Jared Terrell", false],
    [78307, "Terry", "Carlos", "Carlos Terry", false],
    [78306, "Terry", "Chuck", "Chuck Terry", false],
    [78308, "Terry", "Claude", "Claude Terry", false],
    [1631207, "Terry", "Dalen", "Dalen Terry", true],
    [1629150, "Terry", "Emanuel", "Emanuel Terry", false],
    [1891, "Terry", "Jason", "Jason Terry", false],
    [1630179, "Terry", "Tyrell", "Tyrell Terry", false],
    [1630257, "Teske", "Jon", "Jon Teske", false],
    [201934, "Thabeet", "Hasheem", "Hasheem Thabeet", false],
    [78309, "Thacker", "Tom", "Tom Thacker", false],
    [1628464, "Theis", "Daniel", "Daniel Theis", true],
    [78310, "Theus", "Reggie", "Reggie Theus", false],
    [78311, "Thibeaux", "Peter", "Peter Thibeaux", false],
    [78312, "Thieben", "Bill", "Bill Thieben", false],
    [78313, "Thigpen", "Justus", "Justus Thigpen", false],
    [78314, "Thirdkill", "David", "David Thirdkill", false],
    [203519, "Thomas", "Adonis", "Adonis Thomas", false],
    [2873, "Thomas", "Billy", "Billy Thomas", false],
    [1630271, "Thomas", "Brodric", "Brodric Thomas", false],
    [1630560, "Thomas", "Cam", "Cam Thomas", true],
    [1063, "Thomas", "Carl", "Carl Thomas", false],
    [78316, "Thomas", "Charles", "Charles Thomas", false],
    [2041, "Thomas", "Etan", "Etan Thomas", false],
    [78317, "Thomas", "Irving", "Irving Thomas", false],
    [202738, "Thomas", "Isaiah", "Isaiah Thomas", false],
    [78318, "Thomas", "Isiah", "Isiah Thomas", false],
    [1975, "Thomas", "Jamel", "Jamel Thomas", false],
    [2839, "Thomas", "James", "James Thomas", false],
    [78320, "Thomas", "Jim", "Jim Thomas", false],
    [78321, "Thomas", "Joe", "Joe Thomas", false],
    [1519, "Thomas", "John", "John Thomas", false],
    [1903, "Thomas", "Kenny", "Kenny Thomas", false],
    [1629017, "Thomas", "Khyri", "Khyri Thomas", false],
    [703, "Thomas", "Kurt", "Kurt Thomas", false],
    [202498, "Thomas", "Lance", "Lance Thomas", false],
    [202952, "Thomas", "Malcolm", "Malcolm Thomas", false],
    [1629744, "Thomas", "Matt", "Matt Thomas", false],
    [78322, "Thomas", "Terry", "Terry Thomas", false],
    [1501, "Thomas", "Tim", "Tim Thomas", false],
    [200748, "Thomas", "Tyrus", "Tyrus Thomas", false],
    [202717, "Thompkins", "Trey", "Trey Thompkins", false],
    [78323, "Thompson", "Bernard", "Bernard Thompson", false],
    [1631, "Thompson", "Billy", "Billy Thompson", false],
    [240, "Thompson", "Brooks", "Brooks Thompson", false],
    [78325, "Thompson", "Corny", "Corny Thompson", false],
    [78326, "Thompson", "David", "David Thompson", false],
    [101159, "Thompson", "Dijon", "Dijon Thompson", false],
    [78327, "Thompson", "George", "George Thompson", false],
    [203138, "Thompson", "Hollis", "Hollis Thompson", false],
    [201574, "Thompson", "Jason", "Jason Thompson", false],
    [78328, "Thompson", "John", "John Thompson", false],
    [78329, "Thompson", "Kevin", "Kevin Thompson", false],
    [202691, "Thompson", "Klay", "Klay Thompson", true],
    [78, "Thompson", "LaSalle", "LaSalle Thompson", false],
    [78331, "Thompson", "Mychal", "Mychal Thompson", false],
    [202814, "Thompson", "Mychel", "Mychel Thompson", false],
    [78332, "Thompson", "Paul", "Paul Thompson", false],
    [78333, "Thompson", "Stephen", "Stephen Thompson", false],
    [202684, "Thompson", "Tristan", "Tristan Thompson", false],
    [1630550, "Thor", "JT", "JT Thor", true],
    [78335, "Thorn", "Rod", "Rod Thorn", false],
    [201154, "Thornton", "Al", "Al Thornton", false],
    [738, "Thornton", "Bob", "Bob Thornton", false],
    [201977, "Thornton", "Marcus", "Marcus Thornton", false],
    [1628414, "Thornwell", "Sindarius", "Sindarius Thornwell", false],
    [901, "Thorpe", "Otis", "Otis Thorpe", false],
    [9, "Threatt", "Sedale", "Sedale Threatt", false],
    [600001, "Thurmond", "Nate", "Nate Thurmond", false],
    [78340, "Thurston", "Mel", "Mel Thurston", false],
    [1629680, "Thybulle", "Matisse", "Matisse Thybulle", true],
    [78341, "Tidrick", "Howard", "Howard Tidrick", false],
    [78342, "Tieman", "Dan", "Dan Tieman", false],
    [1629681, "Tillie", "Killian", "Killian Tillie", false],
    [78343, "Tillis", "Darren", "Darren Tillis", false],
    [1630214, "Tillman", "Xavier", "Xavier Tillman", true],
    [78344, "Tingle", "Jack", "Jack Tingle", false],
    [2224, "Tinsley", "Jamaal", "Jamaal Tinsley", false],
    [47, "Tisdale", "Wayman", "Wayman Tisdale", false],
    [1627861, "Tobey", "Mike", "Mike Tobey", false],
    [1630225, "Todd", "Isaiah", "Isaiah Todd", true],
    [78346, "Todorovich", "Marko", "Marko Todorovich", false],
    [78348, "Tolbert", "Ray", "Ray Tolbert", false],
    [78347, "Tolbert", "Tom", "Tom Tolbert", false],
    [201229, "Tolliver", "Anthony", "Anthony Tolliver", false],
    [78349, "Tolson", "Dean", "Dean Tolson", false],
    [78350, "Tomjanovich", "Rudy", "Rudy Tomjanovich", false],
    [78351, "Toney", "Andrew", "Andrew Toney", false],
    [78352, "Toney", "Sedric", "Sedric Toney", false],
    [78353, "Tonkovich", "Andy", "Andy Tonkovich", false],
    [342, "Toolson", "Andy", "Andy Toolson", false],
    [78355, "Toomay", "Jack", "Jack Toomay", false],
    [78356, "Toone", "Bernard", "Bernard Toone", false],
    [1630167, "Toppin", "Obi", "Obi Toppin", true],
    [78357, "Torgoff", "Irv", "Irv Torgoff", false],
    [78358, "Tormohlen", "Gene", "Gene Tormohlen", false],
    [2351, "Torres", "Oscar", "Oscar Torres", false],
    [1629308, "Toscano-Anderson", "Juan", "Juan Toscano-Anderson", true],
    [78359, "Tosheff", "Bill", "Bill Tosheff", false],
    [78360, "Tough", "Bob", "Bob Tough", false],
    [1626253, "Toupane", "Axel", "Axel Toupane", false],
    [78361, "Towe", "Monte", "Monte Towe", false],
    [700, "Tower", "Keith", "Keith Tower", false],
    [78363, "Towery", "William", "William Towery", false],
    [78364, "Townes", "Linton", "Linton Townes", false],
    [1626157, "Towns", "Karl-Anthony", "Karl-Anthony Towns", true],
    [78365, "Townsend", "Raymond", "Raymond Townsend", false],
    [78366, "Trapp", "George", "George Trapp", false],
    [78367, "Trapp", "John", "John Trapp", false],
    [1714, "Traylor", "Robert", "Robert Traylor", false],
    [718, "Trent", "Gary", "Gary Trent", false],
    [1629018, "Trent Jr.", "Gary", "Gary Trent Jr.", true],
    [2244, "Trepagnier", "Jeff", "Jeff Trepagnier", false],
    [78651, "Tresvant", "John", "John Tresvant", false],
    [1629019, "Trier", "Allonzo", "Allonzo Trier", false],
    [78368, "Triptow", "Dick", "Dick Triptow", false],
    [78369, "Tripucka", "Kelly", "Kelly Tripucka", false],
    [2456, "Trybanski", "Cezary", "Cezary Trybanski", false],
    [2054, "Tsakalidis", "Jake", "Jake Tsakalidis", false],
    [78370, "Tschogl", "John", "John Tschogl", false],
    [78371, "Tsioropoulos", "Lou", "Lou Tsioropoulos", false],
    [2401, "Tskitishvili", "Nikoloz", "Nikoloz Tskitishvili", false],
    [78372, "Tucker", "Al", "Al Tucker", false],
    [201169, "Tucker", "Alando", "Alando Tucker", false],
    [141, "Tucker", "Anthony", "Anthony Tucker", false],
    [78374, "Tucker", "Jim", "Jim Tucker", false],
    [200782, "Tucker", "P.J.", "P.J. Tucker", true],
    [1629730, "Tucker", "Rayjon", "Rayjon Tucker", false],
    [78375, "Tucker", "Trent", "Trent Tucker", false],
    [101142, "Turiaf", "Ronny", "Ronny Turiaf", false],
    [1726, "Turkcan", "Mirsad", "Mirsad Turkcan", false],
    [2045, "Turkoglu", "Hedo", "Hedo Turkoglu", false],
    [78377, "Turner", "Andre", "Andre Turner", false],
    [78385, "Turner", "Bill", "Bill Turner", false],
    [78378, "Turner", "Elston", "Elston Turner", false],
    [202323, "Turner", "Evan", "Evan Turner", false],
    [78379, "Turner", "Henry", "Henry Turner", false],
    [78380, "Turner", "Jack", "Jack Turner", false],
    [78382, "Turner", "Jack", "Jack Turner", false],
    [78381, "Turner", "Jeff", "Jeff Turner", false],
    [1819, "Turner", "John", "John Turner", false],
    [1626167, "Turner", "Myles", "Myles Turner", true],
    [1943, "Turner", "Wayne", "Wayne Turner", false],
    [78386, "Turpin", "Mel", "Mel Turpin", false],
    [78387, "Twardzik", "Dave", "Dave Twardzik", false],
    [78388, "Twyman", "Jack", "Jack Twyman", false],
    [189, "Tyler", "B.J.", "B.J. Tyler", false],
    [202719, "Tyler", "Jeremy", "Jeremy Tyler", false],
    [78390, "Tyler", "Terry", "Terry Tyler", false],
    [78391, "Tyra", "Charlie", "Charlie Tyra", false],
    [202775, "Ubiles", "Edwin", "Edwin Ubiles", false],
    [202327, "Udoh", "Ekpe", "Ekpe Udoh", false],
    [2137, "Udoka", "Ime", "Ime Udoka", false],
    [2757, "Udrih", "Beno", "Beno Udrih", false],
    [101146, "Ukic", "Roko", "Roko Ukic", false],
    [1627755, "Ulis", "Tyler", "Tyler Ulis", false],
    [1630649, "Umude", "Stanley", "Stanley Umude", true],
    [78392, "Unseld", "Wes", "Wes Unseld", false],
    [78393, "Uplinger", "Hal", "Hal Uplinger", false],
    [78394, "Upshaw", "Kelvin", "Kelvin Upshaw", false],
    [1627784, "Uthoff", "Jarrod", "Jarrod Uthoff", false],
    [202386, "Uzoh", "Ben", "Ben Uzoh", false],
    [201987, "Vaden", "Robert", "Robert Vaden", false],
    [202685, "Valanciunas", "Jonas", "Jonas Valanciunas", true],
    [78395, "Valentine", "Darnell", "Darnell Valentine", false],
    [1627756, "Valentine", "Denzel", "Denzel Valentine", false],
    [78396, "Valentine", "Ron", "Ron Valentine", false],
    [78397, "Vallely", "John", "John Vallely", false],
    [78398, "Van Arsdale", "Dick", "Dick Van Arsdale", false],
    [78399, "Van Arsdale", "Tom", "Tom Van Arsdale", false],
    [78401, "Van Breda Kolff", "Butch", "Butch Van Breda Kolff", false],
    [78400, "Van Breda Kolff", "Jan", "Jan Van Breda Kolff", false],
    [89, "Van Exel", "Nick", "Nick Van Exel", false],
    [1496, "Van Horn", "Keith", "Keith Van Horn", false],
    [78406, "Van Lier", "Norm", "Norm Van Lier", false],
    [1627832, "VanVleet", "Fred", "Fred VanVleet", true],
    [78402, "Vance", "Gene", "Gene Vance", false],
    [78403, "Vander Velden", "Log", "Log Vander Velden", false],
    [1629020, "Vanderbilt", "Jarred", "Jarred Vanderbilt", true],
    [78405, "Vandeweghe", "Ernie", "Ernie Vandeweghe", false],
    [78404, "Vandeweghe", "Kiki", "Kiki Vandeweghe", false],
    [78407, "Vanos", "Nick", "Nick Vanos", false],
    [2128, "Vanterpool", "David", "David Vanterpool", false],
    [2237, "Varda", "Ratko", "Ratko Varda", false],
    [2760, "Varejao", "Anderson", "Anderson Varejao", false],
    [202363, "Varnado", "Jarvis", "Jarvis Varnado", false],
    [202349, "Vasquez", "Greivis", "Greivis Vasquez", false],
    [1630170, "Vassell", "Devin", "Devin Vassell", true],
    [78409, "Vaughn", "Charles", "Charles Vaughn", false],
    [710, "Vaughn", "David", "David Vaughn", false],
    [1521, "Vaughn", "Jacque", "Jacque Vaughn", false],
    [1626173, "Vaughn", "Rashad", "Rashad Vaughn", false],
    [78411, "Vaughn", "Virgil", "Virgil Vaughn", false],
    [919, "Vaught", "Loy", "Loy Vaught", false],
    [78413, "Verga", "Bob", "Bob Verga", false],
    [78414, "Verhoeven", "Peter", "Peter Verhoeven", false],
    [202686, "Vesely", "Jan", "Jan Vesely", false],
    [78415, "Vetra", "Gundars", "Gundars Vetra", false],
    [78416, "Vianna", "Joao", "Joao Vianna", false],
    [1630492, "Vildoza", "Luca", "Luca Vildoza", false],
    [101111, "Villanueva", "Charlie", "Charlie Villanueva", false],
    [1629216, "Vincent", "Gabe", "Gabe Vincent", true],
    [78418, "Vincent", "Jay", "Jay Vincent", false],
    [78417, "Vincent", "Sam", "Sam Vincent", false],
    [200790, "Vinicius", "Marcus", "Marcus Vinicius", false],
    [1844, "Vinson", "Fred", "Fred Vinson", false],
    [78419, "Voce", "Gary", "Gary Voce", false],
    [78420, "Volker", "Floyd", "Floyd Volker", false],
    [78421, "Volkov", "Alexander", "Alexander Volkov", false],
    [78422, "Von Nieda", "Whitey", "Whitey Von Nieda", false],
    [203943, "Vonleh", "Noah", "Noah Vonleh", true],
    [2063, "Voskuhl", "Jake", "Jake Voskuhl", false],
    [78423, "Vranes", "Danny", "Danny Vranes", false],
    [2582, "Vranes", "Slavko", "Slavko Vranes", false],
    [1037, "Vrankovic", "Stojko", "Stojko Vrankovic", false],
    [78425, "Vroman", "Brett", "Brett Vroman", false],
    [2761, "Vroman", "Jackson", "Jackson Vroman", false],
    [202696, "Vucevic", "Nikola", "Nikola Vucevic", true],
    [2756, "Vujacic", "Sasha", "Sasha Vujacic", false],
    [1629731, "Wade", "Dean", "Dean Wade", true],
    [2548, "Wade", "Dwyane", "Dwyane Wade", false],
    [78426, "Wade", "Mark", "Mark Wade", false],
    [101144, "Wafer", "Von", "Von Wafer", false],
    [78427, "Wager", "Clint", "Clint Wager", false],
    [2402, "Wagner", "Dajuan", "Dajuan Wagner", false],
    [78428, "Wagner", "Danny", "Danny Wagner", false],
    [1630532, "Wagner", "Franz", "Franz Wagner", true],
    [78429, "Wagner", "Milt", "Milt Wagner", false],
    [1629021, "Wagner", "Moritz", "Moritz Wagner", true],
    [1630688, "Wainright", "Ish", "Ish Wainright", true],
    [203079, "Waiters", "Dion", "Dion Waiters", false],
    [78430, "Waiters", "Granville", "Granville Waiters", false],
    [78431, "Wakefield", "Andre", "Andre Wakefield", false],
    [78432, "Walk", "Neal", "Neal Walk", false],
    [78433, "Walker", "Andy", "Andy Walker", false],
    [952, "Walker", "Antoine", "Antoine Walker", false],
    [78434, "Walker", "Brady", "Brady Walker", false],
    [78435, "Walker", "Chet", "Chet Walker", false],
    [78437, "Walker", "Darrell", "Darrell Walker", false],
    [78436, "Walker", "Foots", "Foots Walker", false],
    [201611, "Walker", "Henry", "Henry Walker", false],
    [78438, "Walker", "Horace", "Horace Walker", false],
    [1631133, "Walker", "Jabari", "Jabari Walker", true],
    [78439, "Walker", "Jimmy", "Jimmy Walker", false],
    [202689, "Walker", "Kemba", "Kemba Walker", true],
    [78440, "Walker", "Kenny", "Kenny Walker", false],
    [1630640, "Walker", "MJ", "MJ Walker", false],
    [78441, "Walker", "Phil", "Phil Walker", false],
    [955, "Walker", "Samaki", "Samaki Walker", false],
    [78442, "Walker", "Wally", "Wally Walker", false],
    [1629022, "Walker IV", "Lonnie", "Lonnie Walker IV", true],
    [202322, "Wall", "John", "John Wall", true],
    [1112, "Wallace", "Ben", "Ben Wallace", false],
    [2222, "Wallace", "Gerald", "Gerald Wallace", false],
    [961, "Wallace", "John", "John Wallace", false],
    [78443, "Wallace", "Michael", "Michael Wallace", false],
    [739, "Wallace", "Rasheed", "Rasheed Wallace", false],
    [1627820, "Wallace", "Tyrone", "Tyrone Wallace", false],
    [78444, "Waller", "Dwight", "Dwight Waller", false],
    [78445, "Waller", "Jamie", "Jamie Waller", false],
    [78446, "Walsh", "Jim", "Jim Walsh", false],
    [101190, "Walsh", "Matt", "Matt Walsh", false],
    [777, "Walters", "Rex", "Rex Walters", false],
    [78447, "Walther", "Paul", "Paul Walther", false],
    [78448, "Walthour", "Rabbit", "Rabbit Walthour", false],
    [78450, "Walton", "Bill", "Bill Walton", false],
    [78449, "Walton", "Lloyd", "Lloyd Walton", false],
    [2575, "Walton", "Luke", "Luke Walton", false],
    [1628476, "Walton Jr.", "Derrick", "Derrick Walton Jr.", false],
    [202954, "Wanamaker", "Brad", "Brad Wanamaker", false],
    [78453, "Wanzer", "Bobby", "Bobby Wanzer", false],
    [78454, "Warbington", "Perry", "Perry Warbington", false],
    [369, "Ward", "Charlie", "Charlie Ward", false],
    [78455, "Ward", "Gerry", "Gerry Ward", false],
    [78456, "Ward", "Henry", "Henry Ward", false],
    [203810, "Ware", "Casper", "Casper Ware", false],
    [78457, "Ware", "Jim", "Jim Ware", false],
    [78458, "Warley", "Ben", "Ben Warley", false],
    [78459, "Warlick", "Bob", "Bob Warlick", false],
    [78460, "Warner", "Cornell", "Cornell Warner", false],
    [1627866, "Warney", "Jameel", "Jameel Warney", false],
    [78461, "Warren", "Johnny", "Johnny Warren", false],
    [203933, "Warren", "T.J.", "T.J. Warren", true],
    [202378, "Warren", "Willie", "Willie Warren", false],
    [78462, "Warrick", "Bryan", "Bryan Warrick", false],
    [101124, "Warrick", "Hakim", "Hakim Warrick", false],
    [78463, "Washburn", "Chris", "Chris Washburn", false],
    [1627395, "Washburn", "Julian", "Julian Washburn", false],
    [78469, "Washington", "Bobby", "Bobby Washington", false],
    [200827, "Washington", "Darius", "Darius Washington", false],
    [78464, "Washington", "Duane", "Duane Washington", false],
    [78465, "Washington", "Dwayne", "Dwayne Washington", false],
    [1540, "Washington", "Eric", "Eric Washington", false],
    [78466, "Washington", "Jim", "Jim Washington", false],
    [78467, "Washington", "Kermit", "Kermit Washington", false],
    [1629023, "Washington", "P.J.", "P.J. Washington", true],
    [78468, "Washington", "Richard", "Richard Washington", false],
    [78470, "Washington", "Stan", "Stan Washington", false],
    [78471, "Washington", "Wilson", "Wilson Washington", false],
    [1630613, "Washington Jr.", "Duane", "Duane Washington Jr.", true],
    [1631102, "Washington Jr.", "TyTy", "TyTy Washington Jr.", true],
    [1629139, "Watanabe", "Yuta", "Yuta Watanabe", true],
    [1629682, "Waters", "Tremont", "Tremont Waters", false],
    [1630322, "Waters III", "Lindy", "Lindy Waters III", true],
    [1630570, "Watford", "Trendon", "Trendon Watford", true],
    [201208, "Watkins", "Darryl", "Darryl Watkins", false],
    [201228, "Watson", "C.J.", "C.J. Watson", false],
    [2248, "Watson", "Earl", "Earl Watson", false],
    [320, "Watson", "Jamie", "Jamie Watson", false],
    [1628778, "Watson", "Paul", "Paul Watson", false],
    [1631212, "Watson", "Peyton", "Peyton Watson", true],
    [78474, "Watts", "Ron", "Ron Watts", false],
    [78473, "Watts", "Slick", "Slick Watts", false],
    [203146, "Wayns", "Maalik", "Maalik Wayns", false],
    [204033, "Wear", "David", "David Wear", false],
    [204037, "Wear", "Travis", "Travis Wear", false],
    [221, "Weatherspoon", "Clar.", "Clar. Weatherspoon", false],
    [78475, "Weatherspoon", "Nick", "Nick Weatherspoon", false],
    [1629683, "Weatherspoon", "Quinndary", "Quinndary Weatherspoon", false],
    [201602, "Weaver", "Kyle", "Kyle Weaver", false],
    [78477, "Webb", "Jeff", "Jeff Webb", false],
    [78478, "Webb", "Marcus", "Marcus Webb", false],
    [892, "Webb", "Spud", "Spud Webb", false],
    [1627821, "Webb III", "James", "James Webb III", false],
    [185, "Webber", "Chris", "Chris Webber", false],
    [1627362, "Weber", "Briante", "Briante Weber", false],
    [78479, "Weber", "Forest", "Forest Weber", false],
    [1067, "Webster", "Jeff", "Jeff Webster", false],
    [101110, "Webster", "Martell", "Martell Webster", false],
    [78481, "Webster", "Marvin", "Marvin Webster", false],
    [78482, "Wedman", "Scott", "Scott Wedman", false],
    [201603, "Weems", "Sonny", "Sonny Weems", false],
    [78483, "Wehr", "Dick", "Dick Wehr", false],
    [78484, "Weidner", "Brant", "Brant Weidner", false],
    [78485, "Weiss", "Bob", "Bob Weiss", false],
    [78486, "Weitzman", "Rick", "Rick Weitzman", false],
    [1719, "Wells", "Bonzi", "Bonzi Wells", false],
    [1528, "Wells", "Bubba", "Bubba Wells", false],
    [78488, "Wells", "Owen", "Owen Wells", false],
    [78489, "Wells", "Ralph", "Ralph Wells", false],
    [1846, "Welp", "Chris", "Chris Welp", false],
    [2412, "Welsch", "Jiri", "Jiri Welsch", false],
    [1629118, "Welsh", "Thomas", "Thomas Welsh", false],
    [82, "Wennington", "Bill", "Bill Wennington", false],
    [78492, "Wenstrom", "Matt", "Matt Wenstrom", false],
    [438, "Werdann", "Robert", "Robert Werdann", false],
    [78494, "Wertis", "Ray", "Ray Wertis", false],
    [1631104, "Wesley", "Blake", "Blake Wesley", true],
    [133, "Wesley", "David", "David Wesley", false],
    [78495, "Wesley", "Walt", "Walt Wesley", false],
    [2561, "West", "David", "David West", false],
    [2753, "West", "Delonte", "Delonte West", false],
    [28, "West", "Doug", "Doug West", false],
    [78497, "West", "Jerry", "Jerry West", false],
    [201238, "West", "Mario", "Mario West", false],
    [770, "West", "Mark", "Mark West", false],
    [78499, "West", "Roland", "Roland West", false],
    [201566, "Westbrook", "Russell", "Russell Westbrook", true],
    [78500, "Westphal", "Paul", "Paul Westphal", false],
    [78501, "Wetzel", "John", "John Wetzel", false],
    [101156, "Whaley", "Robert", "Robert Whaley", false],
    [941, "Whatley", "Ennis", "Ennis Whatley", false],
    [1545, "Wheat", "DeJuan", "DeJuan Wheat", false],
    [78504, "Wheeler", "Clinton", "Clinton Wheeler", false],
    [1755, "Wheeler", "Tyson", "Tyson Wheeler", false],
    [78506, "Whitaker", "Lucian", "Lucian Whitaker", false],
    [1629632, "White", "Coby", "Coby White", true],
    [201591, "White", "DJ", "DJ White", false],
    [1628401, "White", "Derrick", "Derrick White", true],
    [78507, "White", "Eric", "Eric White", false],
    [78508, "White", "Herb", "Herb White", false],
    [78509, "White", "Hubie", "Hubie White", false],
    [1631298, "White", "Jack", "Jack White", true],
    [1751, "White", "Jahidi", "Jahidi White", false],
    [200778, "White", "James", "James White", false],
    [78510, "White", "Jojo", "Jojo White", false],
    [1627855, "White", "Okaro", "Okaro White", false],
    [1839, "White", "Randy", "Randy White", false],
    [2206, "White", "Rodney", "Rodney White", false],
    [78512, "White", "Rory", "Rory White", false],
    [203091, "White", "Royce", "Royce White", false],
    [78513, "White", "Rudy", "Rudy White", false],
    [202358, "White", "Terrico", "Terrico White", false],
    [78514, "White", "Tony", "Tony White", false],
    [78515, "White", "Willie", "Willie White", false],
    [1628510, "White III", "Andrew", "Andrew White III", false],
    [1627785, "Whitehead", "Isaiah", "Isaiah Whitehead", false],
    [78516, "Whitehead", "Jerome", "Jerome Whitehead", false],
    [1126, "Whiteside", "Donald", "Donald Whiteside", false],
    [202355, "Whiteside", "Hassan", "Hassan Whiteside", false],
    [730, "Whitfield", "Dwayne", "Dwayne Whitfield", false],
    [78519, "Whitney", "Charles", "Charles Whitney", false],
    [43, "Whitney", "Chris", "Chris Whitney", false],
    [204222, "Whittington", "Greg", "Greg Whittington", false],
    [203963, "Whittington", "Shayne", "Shayne Whittington", false],
    [78520, "Wicks", "Sidney", "Sidney Wicks", false],
    [78521, "Wier", "Murray", "Murray Wier", false],
    [78522, "Wiesenhahn", "Bob", "Bob Wiesenhahn", false],
    [1630580, "Wieskamp", "Joe", "Joe Wieskamp", true],
    [1630598, "Wiggins", "Aaron", "Aaron Wiggins", true],
    [203952, "Wiggins", "Andrew", "Andrew Wiggins", true],
    [78523, "Wiggins", "Mitchell", "Mitchell Wiggins", false],
    [1629623, "Wigginton", "Lindell", "Lindell Wigginton", true],
    [78524, "Wilburn", "Ken", "Ken Wilburn", false],
    [203912, "Wilcox", "C.J.", "C.J. Wilcox", false],
    [2404, "Wilcox", "Chris", "Chris Wilcox", false],
    [78525, "Wilcutt", "D.C.", "D.C. Wilcutt", false],
    [78526, "Wiley", "Gene", "Gene Wiley", false],
    [1628451, "Wiley", "Jacob", "Jacob Wiley", false],
    [78527, "Wiley", "Michael", "Michael Wiley", false],
    [78528, "Wiley", "Morlon", "Morlon Wiley", false],
    [78529, "Wilfong", "Win", "Win Wilfong", false],
    [78530, "Wilkens", "Lenny", "Lenny Wilkens", false],
    [78531, "Wilkerson", "Bob", "Bob Wilkerson", false],
    [78532, "Wilkes", "Jamaal", "Jamaal Wilkes", false],
    [78533, "Wilkes", "James", "James Wilkes", false],
    [2863, "Wilkins", "Damien", "Damien Wilkins", false],
    [1122, "Wilkins", "Dominique", "Dominique Wilkins", false],
    [78534, "Wilkins", "Eddielee", "Eddielee Wilkins", false],
    [786, "Wilkins", "Gerald", "Gerald Wilkins", false],
    [78537, "Wilkins", "Jeff", "Jeff Wilkins", false],
    [78538, "Wilkinson", "Dale", "Dale Wilkinson", false],
    [2366, "Wilks", "Mike", "Mike Wilks", false],
    [1425, "Williams", "Aaron", "Aaron Williams", false],
    [1626210, "Williams", "Alan", "Alan Williams", false],
    [1631214, "Williams", "Alondes", "Alondes Williams", true],
    [1541, "Williams", "Alvin", "Alvin Williams", false],
    [78539, "Williams", "Arthur", "Arthur Williams", false],
    [78540, "Williams", "Bernie", "Bernie Williams", false],
    [78564, "Williams", "Bob", "Bob Williams", false],
    [1585, "Williams", "Brandon", "Brandon Williams", false],
    [1630314, "Williams", "Brandon", "Brandon Williams", false],
    [433, "Williams", "Buck", "Buck Williams", false],
    [203710, "Williams", "C.J.", "C.J. Williams", false],
    [78547, "Williams", "Chuck", "Chuck Williams", false],
    [78541, "Williams", "Chuckie", "Chuckie Williams", false],
    [78543, "Williams", "Cliff", "Cliff Williams", false],
    [101258, "Williams", "Corey", "Corey Williams", false],
    [101114, "Williams", "Deron", "Deron Williams", false],
    [202682, "Williams", "Derrick", "Derrick Williams", false],
    [78545, "Williams", "Don", "Don Williams", false],
    [1631495, "Williams", "Donovan", "Donovan Williams", true],
    [78546, "Williams", "Earl", "Earl Williams", false],
    [202343, "Williams", "Elliot", "Elliot Williams", false],
    [677, "Williams", "Eric", "Eric Williams", false],
    [2421, "Williams", "Frank", "Frank Williams", false],
    [78548, "Williams", "Freeman", "Freeman Williams", false],
    [1629684, "Williams", "Grant", "Grant Williams", true],
    [78549, "Williams", "Gus", "Gus Williams", false],
    [78550, "Williams", "Guy", "Guy Williams", false],
    [1006, "Williams", "Herb", "Herb Williams", false],
    [73, "Williams", "Hot Rod", "Hot Rod Williams", false],
    [1631114, "Williams", "Jalen", "Jalen Williams", true],
    [1715, "Williams", "Jason", "Jason Williams", false],
    [101214, "Williams", "Jawad", "Jawad Williams", false],
    [2398, "Williams", "Jay", "Jay Williams", false],
    [1631119, "Williams", "Jaylin", "Jaylin Williams", true],
    [420, "Williams", "Jayson", "Jayson Williams", false],
    [966, "Williams", "Jerome", "Jerome Williams", false],
    [78554, "Williams", "John", "John Williams", false],
    [1629140, "Williams", "Johnathan", "Johnathan Williams", false],
    [202716, "Williams", "Jordan", "Jordan Williams", false],
    [200818, "Williams", "Justin", "Justin Williams", false],
    [78555, "Williams", "Kenny", "Kenny Williams", false],
    [1629026, "Williams", "Kenrich", "Kenrich Williams", true],
    [78556, "Williams", "Kevin", "Kevin Williams", false],
    [31, "Williams", "Lorenzo", "Lorenzo Williams", false],
    [101150, "Williams", "Lou", "Lou Williams", false],
    [200766, "Williams", "Marcus", "Marcus Williams", false],
    [201173, "Williams", "Marcus", "Marcus Williams", false],
    [1631109, "Williams", "Mark", "Mark Williams", true],
    [101107, "Williams", "Marvin", "Marvin Williams", false],
    [52, "Williams", "Micheal", "Micheal Williams", false],
    [78558, "Williams", "Mike", "Mike Williams", false],
    [78560, "Williams", "Milt", "Milt Williams", false],
    [2590, "Williams", "Mo", "Mo Williams", false],
    [42, "Williams", "Monty", "Monty Williams", false],
    [78561, "Williams", "Nate", "Nate Williams", false],
    [1630172, "Williams", "Patrick", "Patrick Williams", true],
    [78566, "Williams", "Pete", "Pete Williams", false],
    [78571, "Williams", "Ray", "Ray Williams", false],
    [199, "Williams", "Reggie", "Reggie Williams", false],
    [202130, "Williams", "Reggie", "Reggie Williams", false],
    [78563, "Williams", "Rickey", "Rickey Williams", false],
    [78565, "Williams", "Rob", "Rob Williams", false],
    [78567, "Williams", "Ron", "Ron Williams", false],
    [78568, "Williams", "Sam", "Sam Williams", false],
    [78569, "Williams", "Samuel", "Samuel Williams", false],
    [281, "Williams", "Scott", "Scott Williams", false],
    [201157, "Williams", "Sean", "Sean Williams", false],
    [1742, "Williams", "Shammond", "Shammond Williams", false],
    [200761, "Williams", "Shawne", "Shawne Williams", false],
    [200749, "Williams", "Shelden", "Shelden Williams", false],
    [78570, "Williams", "Sly", "Sly Williams", false],
    [201944, "Williams", "Terrence", "Terrence Williams", false],
    [1576, "Williams", "Travis", "Travis Williams", false],
    [1627786, "Williams", "Troy", "Troy Williams", false],
    [1005, "Williams", "Walt", "Walt Williams", false],
    [78573, "Williams", "Ward", "Ward Williams", false],
    [78574, "Williams", "Willie", "Willie Williams", false],
    [1630533, "Williams", "Ziaire", "Ziaire Williams", true],
    [1629057, "Williams III", "Robert", "Robert Williams III", true],
    [1628475, "Williams Jr.", "Matt", "Matt Williams Jr.", false],
    [1631246, "Williams Jr.", "Vince", "Vince Williams Jr.", true],
    [1628430, "Williams-Goss", "Nigel", "Nigel Williams-Goss", false],
    [722, "Williamson", "Corliss", "Corliss Williamson", false],
    [78575, "Williamson", "John", "John Williamson", false],
    [1629627, "Williamson", "Zion", "Zion Williamson", true],
    [788, "Willis", "Kevin", "Kevin Willis", false],
    [78577, "Willoughby", "Bill", "Bill Willoughby", false],
    [2000, "Willoughby", "Dedric", "Dedric Willoughby", false],
    [78586, "Wilson", "Bob", "Bob Wilson", false],
    [78585, "Wilson", "Bobby", "Bobby Wilson", false],
    [1628391, "Wilson", "D.J.", "D.J. Wilson", false],
    [78579, "Wilson", "George", "George Wilson", false],
    [78580, "Wilson", "Isaiah", "Isaiah Wilson", false],
    [203966, "Wilson", "Jamil", "Jamil Wilson", false],
    [78581, "Wilson", "Mike", "Mike Wilson", false],
    [78582, "Wilson", "Nikita", "Nikita Wilson", false],
    [78578, "Wilson", "Othell", "Othell Wilson", false],
    [78583, "Wilson", "Rick", "Rick Wilson", false],
    [78584, "Wilson", "Ricky", "Ricky Wilson", false],
    [78587, "Wilson", "Thomas", "Thomas Wilson", false],
    [1027, "Wilson", "Trevor", "Trevor Wilson", false],
    [1627787, "Wiltjer", "Kyle", "Kyle Wiltjer", false],
    [78589, "Winchester", "Kennard", "Kennard Winchester", false],
    [78590, "Windis", "Tony", "Tony Windis", false],
    [1629685, "Windler", "Dylan", "Dylan Windler", true],
    [78591, "Windsor", "John", "John Windsor", false],
    [78592, "Winfield", "Lee", "Lee Winfield", false],
    [766, "Wingate", "David", "David Wingate", false],
    [112, "Wingfield", "Dontonio", "Dontonio Wingfield", false],
    [78596, "Wingo", "Harthorne", "Harthorne Wingo", false],
    [78597, "Winkler", "Marv", "Marv Winkler", false],
    [1626159, "Winslow", "Justise", "Justise Winslow", true],
    [1984, "Winslow", "Rickie", "Rickie Winslow", false],
    [1630216, "Winston", "Cassius", "Cassius Winston", false],
    [1868, "Winter", "Trevor", "Trevor Winter", false],
    [78600, "Winters", "Brian", "Brian Winters", false],
    [78601, "Winters", "Voise", "Voise Winters", false],
    [78602, "Wise", "Willie", "Willie Wise", false],
    [1630164, "Wiseman", "James", "James Wiseman", true],
    [203481, "Withey", "Jeff", "Jeff Withey", false],
    [78603, "Witte", "Luke", "Luke Witte", false],
    [1456, "Wittman", "Randy", "Randy Wittman", false],
    [78605, "Witts", "Garry", "Garry Witts", false],
    [78606, "Wohl", "Dave", "Dave Wohl", false],
    [341, "Wolf", "Joe", "Joe Wolf", false],
    [2106, "Wolkowyski", "Ruben", "Ruben Wolkowyski", false],
    [203489, "Wolters", "Nate", "Nate Wolters", false],
    [78610, "Wood", "Al", "Al Wood", false],
    [78612, "Wood", "Bob", "Bob Wood", false],
    [1626174, "Wood", "Christian", "Christian Wood", true],
    [116, "Wood", "David", "David Wood", false],
    [78609, "Wood", "Howard", "Howard Wood", false],
    [78611, "Wood", "Leon", "Leon Wood", false],
    [1630218, "Woodard II", "Robert", "Robert Woodard II", false],
    [2254, "Woods", "Loren", "Loren Woods", false],
    [2417, "Woods", "Qyntel", "Qyntel Woods", false],
    [1010, "Woods", "Randy", "Randy Woods", false],
    [78614, "Woodson", "Mike", "Mike Woodson", false],
    [78615, "Woolridge", "Orlando", "Orlando Woolridge", false],
    [1629624, "Wooten", "Kenny", "Kenny Wooten", false],
    [906, "Workman", "Haywoode", "Haywoode Workman", false],
    [78616, "Workman", "Mark", "Mark Workman", false],
    [78617, "Workman", "Tom", "Tom Workman", false],
    [1897, "World Peace", "Metta", "Metta World Peace", false],
    [78618, "Worthen", "Sam", "Sam Worthen", false],
    [1460, "Worthy", "James", "James Worthy", false],
    [101120, "Wright", "Antoine", "Antoine Wright", false],
    [101152, "Wright", "Bracey", "Bracey Wright", false],
    [78620, "Wright", "Brad", "Brad Wright", false],
    [201148, "Wright", "Brandan", "Brandan Wright", false],
    [202874, "Wright", "Chris", "Chris Wright", false],
    [203203, "Wright", "Chris", "Chris Wright", false],
    [1626153, "Wright", "Delon", "Delon Wright", true],
    [2748, "Wright", "Dorell", "Dorell Wright", false],
    [78621, "Wright", "Howard", "Howard Wright", false],
    [78622, "Wright", "Joby", "Joby Wright", false],
    [201153, "Wright", "Julian", "Julian Wright", false],
    [78623, "Wright", "Larry", "Larry Wright", false],
    [953, "Wright", "Lorenzen", "Lorenzen Wright", false],
    [1007, "Wright", "Luther", "Luther Wright", false],
    [1630589, "Wright", "Moses", "Moses Wright", false],
    [412, "Wright", "Sharone", "Sharone Wright", false],
    [1630593, "Wright IV", "McKinley", "McKinley Wright IV", true],
    [1629625, "Wright-Foreman", "Justin", "Justin Wright-Foreman", false],
    [203100, "Wroten", "Tony", "Tony Wroten", false],
    [78627, "Wynder", "A.J.", "A.J. Wynder", false],
    [1627824, "Yabusele", "Guerschon", "Guerschon Yabusele", false],
    [2397, "Yao Ming", "", "Yao Ming", false],
    [2428, "Yarbrough", "Vincent", "Vincent Yarbrough", false],
    [78628, "Yardley", "George", "George Yardley", false],
    [78629, "Yates", "Barry", "Barry Yates", false],
    [78630, "Yates", "Wayne", "Wayne Yates", false],
    [78631, "Yelverton", "Charlie", "Charlie Yelverton", false],
    [201146, "Yi Jianlian", "", "Yi Jianlian", false],
    [78632, "Yonakor", "Rich", "Rich Yonakor", false],
    [1628221, "York", "Gabe", "Gabe York", false],
    [78633, "Young", "Danny", "Danny Young", false],
    [203923, "Young", "James", "James Young", false],
    [1626202, "Young", "Joe", "Joe Young", false],
    [1748, "Young", "Korleone", "Korleone Young", false],
    [78634, "Young", "Michael", "Michael Young", false],
    [201156, "Young", "Nick", "Nick Young", false],
    [78635, "Young", "Perry", "Perry Young", false],
    [201970, "Young", "Sam", "Sam Young", false],
    [201152, "Young", "Thaddeus", "Thaddeus Young", true],
    [1937, "Young", "Tim", "Tim Young", false],
    [1629027, "Young", "Trae", "Trae Young", true],
    [1630209, "Yurtseven", "Omer", "Omer Yurtseven", true],
    [78638, "Zaslofsky", "Max", "Max Zaslofsky", false],
    [78639, "Zawoluk", "Robert", "Robert Zawoluk", false],
    [203469, "Zeller", "Cody", "Cody Zeller", true],
    [78640, "Zeller", "Dave", "Dave Zeller", false],
    [78641, "Zeller", "Gary", "Gary Zeller", false],
    [78642, "Zeller", "Hank", "Hank Zeller", false],
    [202545, "Zeller", "Luke", "Luke Zeller", false],
    [203092, "Zeller", "Tyler", "Tyler Zeller", false],
    [78643, "Zeno", "Tony", "Tony Zeno", false],
    [78644, "Zevenbergen", "Phil", "Phil Zevenbergen", false],
    [1917, "Zhi-zhi", "Wang", "Wang Zhi-zhi", false],
    [1627753, "Zhou Qi", "", "Zhou Qi", false],
    [678, "Zidek", "George", "George Zidek", false],
    [2583, "Zimmerman", "Derrick", "Derrick Zimmerman", false],
    [1627757, "Zimmerman", "Stephen", "Stephen Zimmerman", false],
    [1627835, "Zipser", "Paul", "Paul Zipser", false],
    [1627790, "Zizic", "Ante", "Ante Zizic", false],
    [78647, "Zoet", "Jim", "Jim Zoet", false],
    [78648, "Zopf", "Bill", "Bill Zopf", false],
    [1627826, "Zubac", "Ivica", "Ivica Zubac", true],
    [78650, "Zunic", "Matt", "Matt Zunic", false]
]


const team_index_id = 0
const team_index_abbreviation = 1
const team_index_nickname = 2
const team_index_year_founded = 3
const team_index_city = 4
const team_index_full_name = 5
const team_index_state = 6
const team_index_championship_year = 7

export const teams = [
    [1610612737, 'ATL', 'Hawks', 1949, 'Atlanta', 'Atlanta Hawks', 'Georgia', [1958]],
    [1610612738, 'BOS', 'Celtics', 1946, 'Boston', 'Boston Celtics', 'Massachusetts', [1957, 1959, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1968, 1969, 1974, 1976, 1981, 1984, 1986, 2008]],
    [1610612739, 'CLE', 'Cavaliers', 1970, 'Cleveland', 'Cleveland Cavaliers', 'Ohio', [2016]],
    [1610612740, 'NOP', 'Pelicans', 2002, 'New Orleans', 'New Orleans Pelicans', 'Louisiana', []],
    [1610612741, 'CHI', 'Bulls', 1966, 'Chicago', 'Chicago Bulls', 'Illinois', [1991, 1992, 1993, 1996, 1997, 1998]],
    [1610612742, 'DAL', 'Mavericks', 1980, 'Dallas', 'Dallas Mavericks', 'Texas', [2011]],
    [1610612743, 'DEN', 'Nuggets', 1976, 'Denver', 'Denver Nuggets', 'Colorado', []],
    [1610612744, 'GSW', 'Warriors', 1946, 'Golden State', 'Golden State Warriors', 'California', [1947, 1956, 1975, 2015, 2017, 2018, 2022]],
    [1610612745, 'HOU', 'Rockets', 1967, 'Houston', 'Houston Rockets', 'Texas', [1994, 1995]],
    [1610612746, 'LAC', 'Clippers', 1970, 'Los Angeles', 'Los Angeles Clippers', 'California', []],
    [1610612747, 'LAL', 'Lakers', 1948, 'Los Angeles', 'Los Angeles Lakers', 'California', [1949, 1950, 1952, 1953, 1954, 1972, 1980, 1982, 1985, 1987, 1988, 2000, 2001, 2002, 2009, 2010, 2020]],
    [1610612748, 'MIA', 'Heat', 1988, 'Miami', 'Miami Heat', 'Florida', [2006, 2012, 2013]],
    [1610612749, 'MIL', 'Bucks', 1968, 'Milwaukee', 'Milwaukee Bucks', 'Wisconsin', [1971, 2021]],
    [1610612750, 'MIN', 'Timberwolves', 1989, 'Minnesota', 'Minnesota Timberwolves', 'Minnesota', []],
    [1610612751, 'BKN', 'Nets', 1976, 'Brooklyn', 'Brooklyn Nets', 'New York', []],
    [1610612752, 'NYK', 'Knicks', 1946, 'New York', 'New York Knicks', 'New York', [1970, 1973]],
    [1610612753, 'ORL', 'Magic', 1989, 'Orlando', 'Orlando Magic', 'Florida', []],
    [1610612754, 'IND', 'Pacers', 1976, 'Indiana', 'Indiana Pacers', 'Indiana', []],
    [1610612755, 'PHI', '76ers', 1949, 'Philadelphia', 'Philadelphia 76ers', 'Pennsylvania', [1955, 1967, 1983]],
    [1610612756, 'PHX', 'Suns', 1968, 'Phoenix', 'Phoenix Suns', 'Arizona', []],
    [1610612757, 'POR', 'Trail Blazers', 1970, 'Portland', 'Portland Trail Blazers', 'Oregon', [1977]],
    [1610612758, 'SAC', 'Kings', 1948, 'Sacramento', 'Sacramento Kings', 'California', [1951]],
    [1610612759, 'SAS', 'Spurs', 1976, 'San Antonio', 'San Antonio Spurs', 'Texas', [1999, 2003, 2005, 2007, 2014]],
    [1610612760, 'OKC', 'Thunder', 1967, 'Oklahoma City', 'Oklahoma City Thunder', 'Oklahoma', [1979]],
    [1610612761, 'TOR', 'Raptors', 1995, 'Toronto', 'Toronto Raptors', 'Ontario', [2019]],
    [1610612762, 'UTA', 'Jazz', 1974, 'Utah', 'Utah Jazz', 'Utah', []],
    [1610612763, 'MEM', 'Grizzlies', 1995, 'Memphis', 'Memphis Grizzlies', 'Tennessee', []],
    [1610612764, 'WAS', 'Wizards', 1961, 'Washington', 'Washington Wizards', 'District of Columbia', [1978]],
    [1610612765, 'DET', 'Pistons', 1948, 'Detroit', 'Detroit Pistons', 'Michigan', [1989, 1990, 2004]],
    [1610612766, 'CHA', 'Hornets', 1988, 'Charlotte', 'Charlotte Hornets', 'North Carolina', []]
]

export const teamToColor = {
    ATL: "#e03a3e",
    BKN: "#000",
    BOS: "#008348",
    CHA: "#00788c",
    CHI: "#ce1141",
    CLE: "#6f263d",
    DAL: "#0053bc",
    DEN: "#0e2240",
    DET: "#1d428a",
    GSW: "#006bb6",
    HOU: "#ce1141",
    IND: "#002d62",
    LAC: "#c8102e",
    LAL: "#552583",
    MEM: "#5d76a9",
    MIA: "#98002e",
    MIL: "#00471b",
    MIN: "#0c2340",
    NOP: "#002b5c",
    NYK: "#006bb6",
    OKC: "#007ac1",
    ORL: "#0077c0",
    PHI: "#006bb6",
    PHX: "#1d1160",
    POR: "#e03a3e",
    SAC: "#5a2d81",
    SAS: "#000",
    TOR: "#000",
    UTA: "#002b5c",
    WAS: "#002b5c",
    TOT: "#1a1a1a",
    ZZZ: "#1a1a1a",
}

export const curSeason = "2023-24";

export const seasonDates= {
    "1946-1947": {
    start: "1946-11-01",
    end: "1947-04-09"
    },
    "1947-1948": {
    start: "1947-11-01",
    end: "1948-03-20"
    },
    "1948-1949": {
    start: "1948-10-30",
    end: "1949-03-26"
    },
    "1949-1950": {
    start: "1949-10-29",
    end: "1950-03-21"
    },
    "1950-1951": {
    start: "1950-10-11",
    end: "1951-03-17"
    },
    "1951-1952": {
    start: "1951-10-30",
    end: "1952-03-23"
    },
    "1952-1953": {
    start: "1952-10-31",
    end: "1953-03-14"
    },
    "1953-1954": {
    start: "1953-10-29",
    end: "1954-03-27"
    },
    "1954-1955": {
    start: "1954-10-29",
    end: "1955-03-19"
    },
    "1955-1956": {
    start: "1955-10-27",
    end: "1956-03-17"
    },
    "1956-1957": {
    start: "1956-10-26",
    end: "1957-03-16"
    },
    "1957-1958": {
    start: "1957-10-24",
    end: "1958-03-22"
    },
    "1958-1959": {
    start: "1958-10-24",
    end: "1959-03-21"
    },
    "1959-1960": {
    start: "1959-10-16",
    end: "1960-03-20"
    },
    "1960-1961": {
    start: "1960-10-19",
    end: "1961-03-18"
    },
    "1961-1962": {
    start: "1961-10-19",
    end: "1962-03-17"
    },
    "1962-1963": {
    start: "1962-10-16",
    end: "1963-03-14"
    },
    "1963-1964": {
    start: "1963-10-15",
    end: "1964-03-14"
    },
    "1964-1965": {
    start: "1964-10-16",
    end: "1965-03-16"
    },
    "1965-1966": {
    start: "1965-10-16",
    end: "1966-03-16"
    },
    "1966-1967": {
    start: "1966-10-15",
    end: "1967-03-15"
    },
    "1967-1968": {
    start: "1967-10-14",
    end: "1968-03-19"
    },
    "1968-1969": {
    start: "1968-10-16",
    end: "1969-03-19"
    },
    "1969-1970": {
    start: "1969-10-17",
    end: "1970-03-22"
    },
    "1970-1971": {
    start: "1970-10-17",
    end: "1971-03-23"
    },
    "1971-1972": {
    start: "1971-10-12",
    end: "1972-04-09"
    },
    "1972-1973": {
    start: "1972-10-11",
    end: "1973-04-05"
    },
    "1973-1974": {
    start: "1973-10-10",
    end: "1974-04-09"
    },
    "1974-1975": {
    start: "1974-10-18",
    end: "1975-04-06"
    },
    "1975-1976": {
    start: "1975-10-24",
    end: "1976-04-18"
    },
    "1976-1977": {
    start: "1976-10-22",
    end: "1977-04-10"
    },
    "1977-1978": {
    start: "1977-10-21",
    end: "1978-04-09"
    },
    "1978-1979": {
    start: "1978-10-13",
    end: "1979-04-08"
    },
    "1979-1980": {
    start: "1979-10-12",
    end: "1980-04-13"
    },
    "1980-1981": {
    start: "1980-10-10",
    end: "1981-04-12"
    },
    "1981-1982": {
    start: "1981-10-30",
    end: "1982-04-18"
    },
    "1982-1983": {
    start: "1982-10-29",
    end: "1983-04-17"
    },
    "1983-1984": {
    start: "1983-10-28",
    end: "1984-04-15"
    },
    "1984-1985": {
    start: "1984-10-26",
    end: "1985-04-14"
    },
    "1985-1986": {
    start: "1985-10-25",
    end: "1986-04-13"
    },
    "1986-1987": {
    start: "1986-10-31",
    end: "1987-04-19"
    },
    "1987-1988": {
    start: "1987-10-23",
    end: "1988-04-17"
    },
    "1988-1989": {
    start: "1988-11-04",
    end: "1989-04-23"
    },
    "1989-1990": {
    start: "1989-11-03",
    end: "1990-04-22"
    },
    "1990-1991": {
    start: "1990-11-02",
    end: "1991-04-21"
    },
    "1991-1992": {
    start: "1991-11-01",
    end: "1992-04-19"
    },
    "1992-1993": {
    start: "1992-11-06",
    end: "1993-04-18"
    },
    "1993-1994": {
    start: "1993-11-05",
    end: "1994-04-24"
    },
    "1994-1995": {
    start: "1994-11-04",
    end: "1995-04-23"
    },
    "1995-1996": {
    start: "1995-11-03",
    end: "1996-04-21"
    },
    "1996-1997": {
    start: "1996-11-01",
    end: "1997-04-20"
    },
    "1997-1998": {
    start: "1997-10-31",
    end: "1998-04-19"
    },
    "1998-1999": {
    start: "1999-02-05",
    end: "1999-05-05"
    },
    "1999-00": {
    start: "2000-10-31",
    end: "2000-04-19"
    },
    "2000-01": {
    start: "2001-11-01",
    end: "2001-04-18"
    },
    "2001-02": {
    start: "2002-10-30",
    end: "2002-04-17"
    },
    "2002-03": {
    start: "2003-10-29",
    end: "2003-04-16"
    },
    "2003-04": {
    start: "2003-10-28",
    end: "2004-04-14"
    },
    "2004-05": {
    start: "2004-11-02",
    end: "2005-04-20"
    },
    "2005-06": {
    start: "2005-11-01",
    end: "2006-04-19"
    },
    "2006-07": {
    start: "2006-10-31",
    end: "2007-04-18"
    },
    "2007-08": {
    start: "2007-10-30",
    end: "2008-04-16"
    },
    "2008-09": {
    start: "2008-10-28",
    end: "2009-04-16"
    },
    "2009-10": {
    start: "2009-10-27",
    end: "2010-04-14"
    },
    "2010-11": {
    start: "2010-10-26",
    end: "2011-04-13"
    },
    "2011-12": {
    start: "2011-12-25",
    end: "2012-04-26"
    },
    "2012-13": {
    start: "2012-10-30",
    end: "2013-04-17"
    },
    "2013-14": {
    start: "2013-10-29",
    end: "2014-04-16"
    },
    "2014-15": {
    start: "2014-10-28",
    end: "2015-04-15"
    },
    "2015-16": {
    start: "2015-10-27",
    end: "2016-04-13"
    },
    "2016-17": {
    start: "2016-10-25",
    end: "2017-04-12"
    },
    "2017-18": {
    start: "2017-10-17",
    end: "2018-04-11"
    },
    "2018-19": {
    start: "2018-10-16",
    end: "2019-04-10"
    },
    "2019-20": {
    start: "2019-10-22",
    end: "2020-08-14"
    },
    "2020-21": {
    start: "2020-12-22",
    end: "2021-05-16"
    },
    "2021-22": {
    start: "2021-10-19",
    end: "2022-04-10"
    },
    "2022-23": {
    start: "2022-10-18",
    end: "2023-04-19"
    },
    "2023-24": {
    start: "2023-10-24",
    end: "2024-04-14"
    }
};
